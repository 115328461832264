import {Show} from 'platform/foundation';

import featureFlags from '@omnetic-dms/feature-flags';

import {DeprecatedNotificationOverview} from './DeprecatedNotificationOverview';
import {NotificationOverview} from './NotificationOverview';

export function NotificationOverviewWrapper() {
  return (
    <>
      <Show whenFeatureEnabled={featureFlags.MY_PROFILE}>
        <NotificationOverview />
      </Show>

      {/* When MY_PROFILE FF gets remove, delete this wrapper */}
      <Show whenFeatureDisabled={featureFlags.MY_PROFILE}>
        <DeprecatedNotificationOverview />
      </Show>
    </>
  );
}
