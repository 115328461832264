import {isValid} from 'date-fns';
import {
  AttributesRow,
  Button,
  Dropdown,
  DropdownItem,
  getFilePreviewUrl,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {HStack, Hide, Link, Show} from 'platform/foundation';
import {Pattern, match} from 'ts-pattern';

import {useNavigate} from 'react-router-dom';

import {always, isNil, isNotEmpty} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  BankAccountResponseBody,
  CheckoutCorrectiveTaxDocumentType,
  CustomerBankAccountResponseBody,
  EntityResourceIds,
  OrderPaymentResponseBody,
  OrderResponseBody,
  useCancelOrderMutation,
  useDeletePaymentFileMutation,
  useGetBankListQuery,
  useGetCurrenciesQuery,
  useGetInvoicePaymentListQuery,
  useGetInvoiceProformaPaymentListQuery,
  useGetParticipationQuery,
  useGetTenantQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {accountingRoutes, testIds} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError, useBank, usePermissions} from '@omnetic-dms/shared';

import {
  Nullish,
  buildArray,
  composePath,
  getApiDateString,
  isNilOrZero,
  openFile,
  parseDate,
  useRequiredParams,
} from 'shared';

import {useIsStandardInvoiceDisabled} from './useIsStandardInvoiceDisabled';

type GetPaymentDetailAttributesArgs = {
  payment: OrderPaymentResponseBody;
  order: OrderResponseBody;
  checkoutId: string;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
};

const MARGIN_INVOICE_TAG = 'm_';

export const useGetPaymentDetailAttributes = ({
  checkoutId,
  order,
  payment,
  refreshBusinessCaseCheckoutInfo,
}: GetPaymentDetailAttributesArgs) => {
  const {id: businessCaseId} = useRequiredParams();

  const [shouldHideStandardInvoice] = useIsStandardInvoiceDisabled(order, businessCaseId);

  const [cancelOrder, {isLoading: isCancelingOrder}] = useCancelOrderMutation();

  const [deletePaymentFile, {isLoading: isDeletingPaymentFile, originalArgs}] =
    useDeletePaymentFileMutation();
  const {data: tenant} = useGetTenantQuery();
  const {data: currencies} = useGetCurrenciesQuery();
  const {data: selectBankLists} = useGetBankListQuery({countryCode: tenant?.country});
  const {composeBankAccount} = useBank();

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: businessCaseId,
      resourceId: EntityResourceIds.businessCase,
    },
    {skip: isNil(businessCaseId)}
  );

  const [canCancelSellingInvoice, canIssueSellingCorrectiveTaxDocument, canReadDocuments] =
    usePermissions({
      permissionKeys: [
        'cancelBusinessCaseSellingInvoice',
        'issueBusinessCaseSellingCorrection',
        'readDocuments',
      ],
      scopes: {
        cancelBusinessCaseSellingInvoice: {participation: businessCaseParticipation},
        issueBusinessCaseSellingCorrection: {participation: businessCaseParticipation},
      },
    });

  const isCancelInvoiceButtonDisabled =
    order.orderDiscriminator === 'PURCHASE' ||
    order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE' ||
    order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES'
      ? false
      : !canCancelSellingInvoice;

  const customerBankAccounts = order.contractInformation.customerContractInformation.bankAccounts;

  const isIssueCorrectiveTaxDocumentHidden =
    order.orderDiscriminator === 'PURCHASE' ||
    order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE' ||
    order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES'
      ? false
      : !canIssueSellingCorrectiveTaxDocument;

  const {data: proformaPayments} = useGetInvoiceProformaPaymentListQuery(
    {invoiceId: payment.proformaInvoiceId ?? ''},
    {skip: isNil(payment.proformaInvoiceId)}
  );

  const {data: invoicePayments} = useGetInvoicePaymentListQuery(
    {invoiceId: payment.invoiceId ?? ''},
    {skip: isNil(payment.invoiceId)}
  );

  const activeProformaPayments = proformaPayments?.filter(
    (item) => isNil(item.cancelingTransactionId) && isNil(item.cancelledTransactionId)
  );
  const activeInvoicePayments = invoicePayments?.filter(
    (item) => isNil(item.cancelingTransactionId) && isNil(item.cancelledTransactionId)
  );

  const arePaymentsEmpty =
    isNilOrEmpty(activeProformaPayments) && isNilOrEmpty(activeInvoicePayments);

  const navigate = useNavigate();

  if (isNil(tenant) || isNil(currencies) || isNil(selectBankLists)) {
    return [[]];
  }

  const tenantBankAccountCurrency = currencies.find(
    (currency) => currency.code === payment.tenantBankAccount?.currencyCode
  );

  const bankAccount = payment.customerBankAccountId
    ? (customerBankAccounts.find(
        (bankAccount) => bankAccount.id === payment.customerBankAccountId
      ) ?? null)
    : null;

  const paymentMethod = getPaymentMethodOptions().find(
    (option) => option.value === payment.paymentMethod
  );

  const isPaymentStateConcept = payment.paymentState === 'CONCEPT';

  const issuedOnDateParsed = parseDate(payment.issuedOn);
  const dateOfTaxableSupply = payment.dateOfTaxableSupply
    ? getApiDateString(parseDate(payment.dateOfTaxableSupply))
    : null;
  const issuedOn =
    payment.issuedOn && issuedOnDateParsed && isValid(issuedOnDateParsed)
      ? getApiDateString(issuedOnDateParsed)
      : null;
  const dueDateParsed = parseDate(payment.dueDate);
  const dueDate =
    payment.dueDate && dueDateParsed && isValid(dueDateParsed)
      ? getApiDateString(dueDateParsed)
      : null;

  const toggleCreateCorrectiveTaxDocument = (type: CheckoutCorrectiveTaxDocumentType) => () => {
    const {id, invoiceType} = match([
      isNotEmpty(payment.taxableDocuments),
      isNotEmpty(payment.proformaInvoices),
    ])
      .with(
        [true, Pattern.boolean],
        always({
          id: payment.taxDocumentId,
          invoiceType: 'TAX_DOCUMENT_FOR_PAYMENT',
        })
      )
      .with(
        [Pattern.boolean, true],
        always({
          id: payment.proformaInvoiceId,
          invoiceType: 'PROFORMA',
        })
      )
      .otherwise(always({id: payment.invoiceId, invoiceType: 'ISSUED'}));

    navigate(
      composePath(accountingRoutes.newCorrectiveTaxDocument, {
        params: {id},
        queryParams: {
          invoiceType,
          checkoutId,
          type,
          businessCaseId,
          orderId: order.id,
          paymentId: payment.id,
        },
      })
    );
  };

  const handleDeletePaymentFile = (fileId: string) =>
    deletePaymentFile({fileId, checkoutId, orderId: order.id, paymentId: payment.id})
      .unwrap()
      .catch(handleApiError);

  const handleCancelPayment = () =>
    openConfirmDialog({
      text: i18n.t('page.businessCase.checkout.cancelInvoice'),
      onConfirm: () =>
        cancelOrder({checkoutId, orderId: order.id, paymentId: payment.id})
          .unwrap()
          .then(() => showNotification.success())
          .then(() => refreshBusinessCaseCheckoutInfo(null))
          .catch(handleApiError),
    });

  const rows = buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.checkout.labels.paymentMethod'),
      value: isPaymentStateConcept ? null : paymentMethod?.label,
      testId: 'paymentMethod',
    })
    .add({
      label: i18n.t('entity.checkout.labels.issuedOn'),
      value: issuedOn,
      testId: 'issuedOn',
    })
    .add({
      label: i18n.t('entity.checkout.labels.dueDate'),
      value: isPaymentStateConcept ? null : dueDate,
      testId: 'dueDate',
    })
    .add({
      label: i18n.t('entity.accounting.labels.dateOfTaxableSupply'),
      value: dateOfTaxableSupply,
      testId: 'dateOfTaxableSupply',
    })
    .when(isNotNil(payment.supplierInvoiceNumber), {
      label: i18n.t('entity.checkout.labels.supplierInvoiceNumber'),
      value: payment.supplierInvoiceNumber || null,
      testId: 'supplierInvoiceNumber',
    })
    .when(payment.paymentState !== 'CONCEPT' && payment.paymentMethod !== 'CASH', {
      label: i18n.t('general.labels.bankAccount'),
      value: bankAccount
        ? composeBankAccount(bankAccount.bankAccountData)
        : payment.tenantBankAccount
          ? [
              payment.tenantBankAccount?.accountName,
              payment.tenantBankAccount?.accountNumber,
              payment.tenantBankAccount?.bankName,
              tenantBankAccountCurrency?.name,
            ]
              .filter(Boolean)
              .join(', ')
          : null,
      testId: 'bankAccount',
    })
    .when(
      order.orderDiscriminator !== 'PURCHASE' &&
        order.orderDiscriminator !== 'PURCHASE_BROKERAGE_SALE',
      {
        label: i18n.t('entity.checkout.labels.note'),
        value: payment.comment || EMPTY_PLACEHOLDER,
        testId: 'note',
      }
    );

  const isPaymentAmountZero = isNilOrZero(Number(payment.amount?.amount ?? '0'));
  const canCancelInvoice =
    (payment.paymentState === 'PENDING' || isPaymentAmountZero) && arePaymentsEmpty;

  rows.push(
    ...payment.invoices.map((invoice) => {
      const isMarginInvoice = invoice.name.startsWith(MARGIN_INVOICE_TAG);
      const hasMarginInvoice = payment.invoices.some(
        (item) => item.name === `${MARGIN_INVOICE_TAG}${invoice.name}`
      );

      const isStandardInvoiceDisabled = shouldHideStandardInvoice && hasMarginInvoice;

      const shouldHideActions = match([
        hasMarginInvoice,
        shouldHideStandardInvoice,
        isMarginInvoice,
      ])
        .with([true, true, false], always(true))
        .with([true, false, false], always(false))
        .with([false, true, true], always(false))
        .with([false, false, true], always(true))
        .with([false, Pattern.boolean, false], always(false))
        .otherwise(always(false));

      return {
        label:
          invoice.documentType === 'PAYMENT_PROFORMA_INVOICE'
            ? i18n.t('entity.checkout.labels.proformaInvoice')
            : i18n.t('entity.checkout.labels.invoice'),
        content: (
          <HStack justify="space-between">
            <Link
              size="small"
              onClick={() => openFile(invoice.fileUri)}
              title={invoice.name}
              isDisabled={isStandardInvoiceDisabled || !canReadDocuments}
              data-testid={testIds.businessCase.checkout('paymentAttributes-invoice')}
            />

            <Hide when={shouldHideActions}>
              <HStack spacing={2} align="center">
                <Show when={canCancelInvoice}>
                  <Button
                    size="small"
                    isLoading={isCancelingOrder}
                    variant="dangerLink"
                    title={i18n.t('entity.invoice.actions.cancelInvoice')}
                    isDisabled={isCancelInvoiceButtonDisabled}
                    onClick={handleCancelPayment}
                    data-testid={testIds.businessCase.checkout('paymentAttributes-cancelInvoice')}
                  />
                </Show>

                <Show when={isIssueCorrectiveTaxDocumentHidden}>
                  <Link
                    isDisabled
                    size="small"
                    title={i18n.t('entity.checkout.actions.correctiveTaxDocument')}
                    data-testid={testIds.businessCase.checkout(
                      'paymentAttributes-correctiveDropdown'
                    )}
                  />
                </Show>
                <Hide when={isIssueCorrectiveTaxDocumentHidden}>
                  <Dropdown
                    closeOnBlur
                    key={invoice.fileUri}
                    data-testid={testIds.businessCase.checkout(
                      'paymentAttributes-correctiveDropdown'
                    )}
                    dropdownControl={
                      <Link
                        size="small"
                        title={i18n.t('entity.checkout.actions.correctiveTaxDocument')}
                        data-testid={testIds.businessCase.checkout(
                          'paymentAttributes-correctiveDropdown'
                        )}
                      />
                    }
                  >
                    <DropdownItem
                      onClick={toggleCreateCorrectiveTaxDocument('full')}
                      label={i18n.t('entity.accounting.labels.correctiveTaxDocumentFull')}
                      data-testid={testIds.businessCase.checkout(
                        'paymentAttributes-correctiveDropdown-full'
                      )}
                    />
                    <DropdownItem
                      onClick={toggleCreateCorrectiveTaxDocument('partial')}
                      label={i18n.t('entity.accounting.labels.correctiveTaxDocumentPartial')}
                      data-testid={testIds.businessCase.checkout(
                        'paymentAttributes-correctiveDropdown-partial'
                      )}
                    />
                  </Dropdown>
                </Hide>
              </HStack>
            </Hide>
          </HStack>
        ),
        testId: 'invoice',
      };
    })
  );

  rows.push(
    ...payment.internalInvoiceDocuments.map((internalInvoiceDocument, index) => ({
      label: i18n.t('entity.checkout.labels.internalInvoiceDocument'),
      content: (
        <Link
          size="small"
          isDisabled={!canReadDocuments}
          onClick={() => openFile(getFilePreviewUrl(internalInvoiceDocument.fileUri))}
          title={internalInvoiceDocument.name}
          data-testid={testIds.businessCase.checkout(
            `paymentAttributes-internalInvoice-[${index}]`
          )}
        />
      ),
      testId: 'internalInvoiceDocument',
    }))
  );

  rows.push(
    ...payment.proformaInvoices.map((proformaInvoice, index) => ({
      label: i18n.t('entity.checkout.labels.proformaInvoice'),
      content: (
        <HStack justify="space-between">
          <Link
            size="small"
            onClick={() => openFile(proformaInvoice.fileUri)}
            isDisabled={!canReadDocuments}
            title={proformaInvoice.name}
            data-testid={testIds.businessCase.checkout(
              `paymentAttributes-proformaInvoice-[${index}]`
            )}
          />
          <Show when={payment.paymentDiscriminator === 'DEPOSIT'}>
            <HStack spacing={2} align="center">
              <Show when={payment.paymentState === 'PENDING' && arePaymentsEmpty}>
                <Button
                  size="small"
                  isLoading={isCancelingOrder}
                  variant="dangerLink"
                  title={i18n.t('entity.invoice.actions.cancelInvoice')}
                  data-testid={testIds.businessCase.checkout(
                    `paymentAttributes-proformaInvoice-[${index}]-cancel`
                  )}
                  onClick={handleCancelPayment}
                  isDisabled={isCancelInvoiceButtonDisabled || !canReadDocuments}
                />
              </Show>
            </HStack>
          </Show>
        </HStack>
      ),
      testId: 'proformaInvoice',
    }))
  );

  if (payment.paymentDiscriminator !== 'DEPOSIT') {
    rows.push(
      ...payment.taxableDocuments.map((taxDocument, index) => ({
        label: i18n.t('entity.checkout.labels.taxableDocument'),
        content: (
          <Link
            size="small"
            isDisabled={!canReadDocuments}
            onClick={() => openFile(taxDocument.fileUri)}
            title={taxDocument.name}
            data-testid={testIds.businessCase.checkout(
              `paymentAttributes-taxableDocument-[${index}]`
            )}
          />
        ),
        testId: 'taxableDocument',
      }))
    );
  }

  rows.push(
    ...payment.files.map((file, index) => ({
      label: i18n.t('entity.checkout.labels.acceptedInvoice'),
      content: (
        <HStack justify="space-between">
          <Link
            size="small"
            onClick={() => openFile(file.fileUri)}
            title={file.name}
            isDisabled={!canReadDocuments}
            data-testid={testIds.businessCase.checkout(`paymentAttributes-files-[${index}]`)}
          />
          <Button
            size="small"
            isDisabled={!canReadDocuments}
            isLoading={originalArgs?.fileId === file.fileId && isDeletingPaymentFile}
            variant="dangerLink"
            title={i18n.t('general.actions.cancel')}
            onClick={() => handleDeletePaymentFile(file.fileId)}
            data-testid={testIds.businessCase.checkout(`paymentAttributes-files-[${index}]-cancel`)}
          />
        </HStack>
      ),
      testId: 'file',
    }))
  );

  return [rows];
};

export const convertBankAccount = (
  dealerAccount: BankAccountResponseBody
): CustomerBankAccountResponseBody => ({
  id: dealerAccount.accountNumber || '',
  bankAccountData: {
    bankCode: dealerAccount.bankName,
    countryCode: null,
    currency: dealerAccount.currency,
    name: dealerAccount.accountName,
    number: dealerAccount.accountNumber,
    iban: dealerAccount.iban,
    swiftBic: dealerAccount.swift,
    ownerName: null,
  },
});

export const getPaymentMethodOptions = () => [
  {
    label: i18n.t('entity.checkout.labels.paymentMethods.bankTransfer'),
    value: 'BANK_TRANSFER',
  },
  {
    label: i18n.t('entity.checkout.labels.paymentMethods.cash'),
    value: 'CASH',
  },
  {
    label: i18n.t('entity.checkout.labels.paymentMethods.card'),
    value: 'PAYMENT_CARD',
  },
  {
    label: i18n.t('entity.invoice.paymentMethod.offset'),
    value: 'OFFSET',
  },
];
