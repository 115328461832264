import {
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  DialogFooter,
  NotificationItem,
  NotificationItemData,
  showNotification,
} from 'platform/components';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';

import {useGetCurrentUserInfoQuery, useGetToastNotificationExampleQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {useGetNotificationItemData} from '@omnetic-dms/notifications';

import {useBoolean} from 'shared';

interface NotificationPreviewProps {
  notificationId: string;
}

export function NotificationExamplePreview(props: NotificationPreviewProps) {
  const {data: notification, isError} = useGetToastNotificationExampleQuery({
    id: props.notificationId,
  });

  const [isLoading, startLoading, stopLoading] = useBoolean(true);

  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const getNotificationItemData = useGetNotificationItemData();

  const [parsedNotification, setParsedNotification] = useState<NotificationItemData | null>();

  useEffect(() => {
    if (isNil(currentUser) || isNil(notification)) {
      return;
    }

    startLoading();

    Promise.all([getNotificationItemData(notification, currentUser.id)]).then(([item]) => {
      setParsedNotification(item);
      stopLoading();
    });

    // we don't want loading state to be dependent on the notification data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotificationItemData, notification, currentUser]);

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={isNil(parsedNotification)}
      emptyMessage={i18n.t('page.demo.notificationNotHandled')}
      minHeight={20}
      spacing={3}
    >
      {parsedNotification && (
        <NotificationItem
          onNavigate={(url: string) => showNotification.info(`Navigating to: ${url}`)}
          {...parsedNotification}
        />
      )}

      <DialogFooter>
        <ButtonGroup
          align="right"
          buttons={[{title: i18n.t('general.actions.close'), onClick: closeCurrentDialog}]}
        />
      </DialogFooter>
    </DataStatus>
  );
}
