import {
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  PhoneNumber,
  Alpha3CountryCode,
  Button,
  Country,
} from 'platform/components';
import {GridItem, Heading, HStack, Right, Show, Grid, VStack} from 'platform/foundation';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNil, join, split, find, has} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetServiceHandOverApiResponse,
  GetServiceCheckInApiResponse,
  PostServiceCheckInApiArg,
  PostServiceHandOverApiArg,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {CustomerSelectChoice, usePhoneNumbers} from '@omnetic-dms/shared';

import {parseDate, getApiDateTimeString, Nullish, suffixTestId, TestIdProps} from 'shared';

import {FormActions} from './components/FormActions';
import {CheckInHandOverFormType} from './types/CheckInHandOverFormType';

interface CheckInHandOverFormProps extends TestIdProps {
  isEditing: boolean;
  onEditDiscard: () => void;
  isCheckin?: boolean;
  defaultValues?: GetServiceHandOverApiResponse | GetServiceCheckInApiResponse;
  onSubmit: (
    data: PostServiceCheckInApiArg['body'] | PostServiceHandOverApiArg['body']
  ) => Promise<void>;
}

const sliderTicks = [
  {value: 0, label: 'E'},
  {value: 0.25, label: '1/4'},
  {value: 0.5, label: '1/2'},
  {value: 0.75, label: '3/4'},
  {value: 1, label: 'F'},
];

const getFullName = (firstName: string | Nullish, lastName: string | Nullish) => {
  if (isNil(firstName) && isNil(lastName)) {
    return;
  }

  if (isNil(lastName)) {
    return firstName ?? undefined;
  }

  if (isNil(firstName)) {
    return lastName;
  }

  return `${firstName} ${lastName}`;
};

const getPhoneNumber = (
  prefix: string | Nullish,
  number: string | Nullish,
  countries?: Country[]
) => {
  const emptyPhoneNumber = {
    prefix: countries?.[0]?.dialCode,
    number: number ?? undefined,
    countryCode: countries?.[0]?.countryCode,
  };

  if (isNil(prefix) || isNil(number)) {
    return emptyPhoneNumber;
  }

  const phoneItem = find((item) => item.dialCode === prefix, countries ?? []);

  if (!phoneItem) {
    return emptyPhoneNumber;
  }

  const countryCode = phoneItem.countryCode as Alpha3CountryCode;

  const phoneNumber: PhoneNumber = {
    prefix,
    number,
    countryCode,
  };

  return phoneNumber;
};

export function CheckInHandOverForm(props: CheckInHandOverFormProps) {
  // nitfy hack until https://carvago.atlassian.net/browse/T20-66063 is fixed
  const [phoneInputKey, setPhoneInputKey] = useState(0);
  const refreshPhoneInputKey = () => setPhoneInputKey((prev) => prev + 1);

  const {countriesOptions} = usePhoneNumbers();

  const defaultValues = {
    ...props.defaultValues,
    isVehicleNotInService: has('isVehicleNotInService', props.defaultValues)
      ? props.defaultValues.isVehicleNotInService
      : false,
    fuelTank: props.defaultValues?.fuelTank ?? 0,
    driverFullName: getFullName(
      props.defaultValues?.driverFirstname,
      props.defaultValues?.driverLastname
    ),
    driverPhone: getPhoneNumber(
      props.defaultValues?.driverPhonePrefix,
      props.defaultValues?.driverPhoneNo,
      countriesOptions
    ),
    technicalInspectionDate: props.defaultValues?.technicalInspection
      ? parseDate(props.defaultValues?.technicalInspection)
      : undefined,
    emissionsDate: props.defaultValues?.emissions
      ? parseDate(props.defaultValues?.emissions)
      : undefined,
  };

  const handleSubmit: FormSubmitHandler<CheckInHandOverFormType> = async (data, _, reset) => {
    const {technicalInspectionDate, emissionsDate, driverPhone, driverFullName, ...res} = data;

    const [driverFirstname, ...lastNames] = split(' ', driverFullName ?? '');
    const driverLastname = join(' ', lastNames);

    const completedDate = props.defaultValues?.completedDate ?? getApiDateTimeString(new Date());

    const technicalInspection = technicalInspectionDate
      ? getApiDateTimeString(technicalInspectionDate)
      : null;
    const emissions = emissionsDate ? getApiDateTimeString(emissionsDate) : null;

    const driverPhoneNo = driverPhone.number;
    const driverPhonePrefix = driverPhone.prefix;

    await props.onSubmit({
      ...res,
      technicalInspection,
      emissions,
      completedDate,
      driverFirstname: isNilOrEmpty(driverFirstname) ? null : driverFirstname,
      driverLastname: isNilOrEmpty(driverLastname) ? null : driverLastname,
      driverPhonePrefix,
      driverPhoneNo,
      withFuelTankMileageUpdate: data.withFuelTankMileageUpdate || false,
    });
    reset(undefined, {keepValues: true});
  };

  const handleTechnicalInspectionDateChange = (
    value: Date | null,
    formApi: UseFormReturn<CheckInHandOverFormType>
  ) => {
    if (!isNil(value)) {
      formApi.setValue('emissionsDate', value);
    }
  };

  const handleEmissionsDateChange = (
    value: Date | null,
    formApi: UseFormReturn<CheckInHandOverFormType>
  ) => {
    if (!isNil(value) && !formApi.getValues().technicalInspectionDate) {
      formApi.setValue('technicalInspectionDate', value);
    }
  };

  return (
    <Form<CheckInHandOverFormType> defaultValues={defaultValues} onSubmit={handleSubmit}>
      {(control, formApi) => (
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('entity.workshop.labels.vehicleDriver')}</Heading>
          <CustomerSelectChoice
            onCustomerSelect={(data) => {
              const formattedPhoneNumber = getPhoneNumber(
                data.prefix,
                data.phoneNumber,
                countriesOptions
              );

              formApi.setValue('driverFullName', data.name);
              formApi.setValue('driverEmail', data.email);
              formApi.setValue('driverPhone', {
                countryCode: formattedPhoneNumber.countryCode || 'CZE',
                number: formattedPhoneNumber.number || '',
                prefix: formattedPhoneNumber.prefix || '',
              });
            }}
            onAfterCustomerSelect={refreshPhoneInputKey}
          />
          <Grid columns={[2, 2, 2, 4]} spacing={4}>
            <GridItem>
              <FormField
                label={i18n.t('general.labels.nameAndSurname')}
                name="driverFullName"
                type="text"
                control={control}
                data-testid={suffixTestId('driver', props)}
              />
            </GridItem>
            <GridItem>
              <FormField
                key={phoneInputKey}
                label={i18n.t('entity.phoneNumber.labels.number')}
                name="driverPhone"
                type="phone"
                control={control}
                countries={countriesOptions}
                data-testid={suffixTestId('driver', props)}
              />
            </GridItem>
            <GridItem>
              <FormField
                label={i18n.t('general.labels.emailAddress')}
                name="driverEmail"
                type="email"
                control={control}
                data-testid={suffixTestId('email', props)}
              />
            </GridItem>

            <GridItem>
              <FormField
                label={i18n.t('general.labels.identityCard')}
                name="driverIdentityCard"
                type="text"
                control={control}
                data-testid={suffixTestId('identityCard', props)}
              />
            </GridItem>
          </Grid>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.workshop.labels.vehicleInformation')}</Heading>
          <Grid columns={[2, 2, 2, 4]} spacing={4}>
            <GridItem>
              <FormField
                label={i18n.t('entity.vehicle.labels.actualMileage')}
                name="mileage"
                type="number"
                control={control}
                minStepperValue={0}
                data-testid={suffixTestId('mileage', props)}
              />
            </GridItem>
            <GridItem>
              <FormField
                label={i18n.t('entity.workshop.labels.technicalInspectionValidUntil')}
                name="technicalInspectionDate"
                type="date"
                control={control}
                isRelativeDatesHidden
                onChange={(value) => handleTechnicalInspectionDateChange(value, formApi)}
                data-testid={suffixTestId('scheduledDate', props)}
              />
            </GridItem>
            <GridItem>
              <FormField
                label={i18n.t('entity.workshop.labels.emissionsValidUntil')}
                name="emissionsDate"
                type="date"
                control={control}
                isRelativeDatesHidden
                onChange={(value) => handleEmissionsDateChange(value, formApi)}
                data-testid={suffixTestId('scheduledDate', props)}
              />
            </GridItem>
            <GridItem>
              <FormField
                label={i18n.t('entity.workshop.labels.fuelTank')}
                name="fuelTank"
                type="slider"
                control={control}
                max={1}
                min={0}
                step={0.25}
                ticks={sliderTicks}
                data-testid={suffixTestId('fuelTank', props)}
              />
            </GridItem>
          </Grid>
          <Show when={props.isCheckin}>
            <Grid columns={[2, 2, 2, 4]} spacing={4}>
              <GridItem>
                <FormField
                  name="consentToTestDrive"
                  type="checkbox"
                  control={control}
                  label={i18n.t('entity.order.labels.consentToTestDrive')}
                  data-testid={suffixTestId('consentToTestDrive', props)}
                />
              </GridItem>
              <GridItem>
                <FormField
                  name="isVehicleNotInService"
                  type="checkbox"
                  control={control}
                  label={i18n.t('entity.order.labels.vehicleIsNotInService')}
                  data-testid={suffixTestId('vehicleIsNotInService', props)}
                />
              </GridItem>
            </Grid>
          </Show>
          <Separator spacing={0} />
          <FormField
            label={i18n.t('general.labels.note')}
            name="note"
            type="textarea"
            control={control}
            data-testid={suffixTestId('note', props)}
          />
          <Right>
            <HStack spacing={2}>
              <Show when={props.isEditing}>
                <Button
                  data-testid={suffixTestId('discardEditing', props)}
                  onClick={props.onEditDiscard}
                  variant="secondary"
                  title={i18n.t('general.actions.discard')}
                />
              </Show>
              <FormActions
                control={control}
                formApi={formApi}
                handleSubmit={handleSubmit}
                isEditing={props.isEditing}
                defaultFuelTank={props.defaultValues?.fuelTank}
                defaultMileage={props.defaultValues?.mileage}
                data-testid={suffixTestId('actions', props)}
              />
            </HStack>
          </Right>
        </VStack>
      )}
    </Form>
  );
}
