import {Card, closeCurrentDialog, DataStatus, openDialog, Parameter} from 'platform/components';
import {match} from 'ts-pattern';

import {FullVehicleResponseBody, useGetVehicleQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';

import {buildArray, composePath, Nullish, suffixTestId, TestIdProps, useToggle} from 'shared';

import {AftersaleVehicleOverview} from '../../AftersalesVehicleWidgetCard/components/AftersaleVehicleOverview';
import {ServiceVehicleForm} from '../../ServiceVehicleForm/ServiceVehicleForm';
import {ServiceVehicleFormMode} from '../../ServiceVehicleForm/types/ServiceVehicleFormMode';
import {ServiceVehicleFormVariant} from '../../ServiceVehicleForm/types/ServiceVehicleFormVariant';
import {OwnershipType} from '../types/ownershipType';

interface SuggestedVehicleProps extends TestIdProps {
  vehicleId: string;
  isSelected: boolean;
  type: OwnershipType | Nullish;
  onSelect: (vehicle: FullVehicleResponseBody | null) => void;
  isExpanded?: boolean;
}

export function SuggestedVehicle(props: SuggestedVehicleProps) {
  const {data: vehicle, isLoading, isError} = useGetVehicleQuery({vehicleId: props.vehicleId});

  const [isExpanded, expand] = useToggle(props.isExpanded);

  const onVehicleEdit = () => {
    if (!props.vehicleId) {
      return;
    }

    openDialog(
      <ServiceVehicleForm
        mode={ServiceVehicleFormMode.EDIT}
        variant={ServiceVehicleFormVariant.SIMPLE}
        vehicleId={props.vehicleId}
        onSuccess={closeCurrentDialog}
        onDiscard={closeCurrentDialog}
        data-testid={suffixTestId('edit', props)}
      />,
      {title: i18n.t('entity.vehicle.labels.editVehicle'), withAdditionalFooter: true}
    );
  };

  const flagTitle = match(props.type)
    .with('CORVEHICLECUSTOMERTYPE_OPERATOR', () => i18n.t('entity.ownership.label.vehicleOperator'))
    .with('CORVEHICLECUSTOMERTYPE_OWNER', () => i18n.t('entity.ownership.label.vehicleOwner'))
    .with('CORVEHICLECUSTOMERTYPE_USER', () => i18n.t('entity.ownership.label.vehicleUser'))
    .with('CORVEHICLECUSTOMERTYPE_MANAGER', () => i18n.t('entity.ownership.label.vehicleManager'))
    .otherwise(() => '');

  return (
    <Card
      variant="inlineWhite"
      title={vehicle?.title}
      flags={[{label: flagTitle, colorScheme: 'blue', size: 'small', isSubtle: true}]}
      parameters={buildArray<Parameter>()
        .when(vehicle?.vehicle.registrationPlate, vehicle?.vehicle.registrationPlate)
        .when(vehicle?.vin, vehicle?.vin)}
      control={{
        type: 'radio',
        value: props.isSelected,
        onChange: () => props.onSelect(vehicle || null),
      }}
      isExpandable
      onExpandButtonClick={expand}
      isExpanded={isExpanded}
      actions={[
        {
          type: 'iconButton',
          size: 'small',
          icon: 'action/open_in_new',
          onClick: () => {
            if (!props.vehicleId) {
              return;
            }

            window.open(composePath(vehiclesRoutes.detail, {params: {id: vehicle?.id}}), '_blank');
          },
        },
        {
          type: 'iconButton',
          size: 'small',
          icon: 'image/edit',
          onClick: onVehicleEdit,
        },
      ]}
    >
      <DataStatus isLoading={isLoading} isError={isError} minHeight={12}>
        {vehicle && (
          <AftersaleVehicleOverview
            vehicle={vehicle}
            data-testid={suffixTestId('overview', props)}
          />
        )}
      </DataStatus>
    </Card>
  );
}
