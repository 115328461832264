import {Action, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isEmpty, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  ComplaintCreatedResponseBody,
  EntityResourceIds,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useGetVehicleQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {useVehicleComplaint} from '../../hooks/useVehicleComplaint';
import {Main} from '../Main/Main';
import {VehicleComplaintCard} from './components/VehicleComplaintCard';

interface VehicleComplaintsProps {
  vehicleId: string;
  complaints: ComplaintCreatedResponseBody[];
}

export function VehicleComplaints(props: VehicleComplaintsProps) {
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery({vehicleId: props.vehicleId});

  const {
    data: saleVehicle,
    isLoading: isSaleVehicleLoading,
    isError: isSaleVehicleError,
  } = useGetSaleVehicleQuery({vehicleId: props.vehicleId});

  const {openComplaintWizard, editComplaint, handleInspectionButton, isInspectionButtonLoading} =
    useVehicleComplaint({
      vehicleId: props.vehicleId,
      saleBusinessCaseId: saleVehicle?.saleBusinessCaseId,
    });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {recordId: saleVehicle?.saleBusinessCaseId ?? '', resourceId: EntityResourceIds.businessCase},
    {skip: isNilOrEmpty(saleVehicle?.saleBusinessCaseId)}
  );

  const [hasComplaintCreatePermission] = usePermissions({
    permissionKeys: ['complaintCreate'],
    scopes: {
      complaintCreate: {participation: businessCaseParticipation},
    },
  });

  const isLoading = isVehicleLoading || isSaleVehicleLoading;
  const isError = isVehicleError || isSaleVehicleError;
  const isClaimAvailable = isNotNil(saleVehicle?.closedSellingBusinessCaseDate);
  const canCreateComplaint = isClaimAvailable && hasComplaintCreatePermission;

  const actions: Action[] = [
    {
      title: i18n.t('entity.vehicle.actions.newComplaint'),
      onClick: openComplaintWizard,
      type: 'button',
      isDisabled: !canCreateComplaint,
    },
  ];

  return (
    <Main actions={actions}>
      <DataStatus
        emptyMessage={i18n.t('entity.vehicleComplaint.labels.noComplaints')}
        action={
          isClaimAvailable
            ? {
                title: i18n.t('entity.vehicle.actions.newComplaint'),
                leftIcon: 'content/add_circle',
                onClick: openComplaintWizard,
                isDisabled: !canCreateComplaint,
              }
            : undefined
        }
        isEmpty={isEmpty(props.complaints)}
        isLoading={isLoading}
        isError={isError}
      >
        <VStack spacing={4}>
          {props.complaints.map((complaint) => (
            <VehicleComplaintCard
              key={complaint.id}
              complaintId={complaint.id}
              onEdit={editComplaint}
              originalMileage={vehicle?.vehicle.mileage}
              onInspectionClick={handleInspectionButton}
              isInspectionButtonLoading={isInspectionButtonLoading}
              businessCaseParticipation={businessCaseParticipation}
            />
          ))}
        </VStack>
      </DataStatus>
    </Main>
  );
}
