import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {EntityResourceIds} from '@omnetic-dms/api';

import {TaskRelations} from '../types/TaskRelations';

export function getIconKeyByTaskRelationType(relationType: TaskRelations) {
  return match(relationType)
    .returnType<ThemeIconKey>()
    .with(EntityResourceIds.customer, () => 'sidebar/person')
    .with(EntityResourceIds.vehicle, () => 'sidebar/directions_car')
    .with(EntityResourceIds.businessCase, () => 'sidebar/business_center')
    .with(EntityResourceIds.interest, () => 'sidebar/next_week')
    .otherwise(() => 'action/question_answer');
}
