import {
  Button,
  ButtonGroup,
  Chips,
  ChipsOption,
  DataStatus,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack, Space} from 'platform/foundation';

import {useState} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  MyClaimCalculationSettings,
  useGetMetadaServiceCaseQuery,
  useGetVehicleV2Query,
  usePostServiceOrderMyClaimCalculationsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, buildArray, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {ImportSettings} from './ImportSettings';

const DEFAULT_SETTINGS = [
  {
    value: true,
    key: 'material' as const,
    label: i18n.t('entity.calculationType.labels.material'),
    additionalInfo: i18n.t('entity.calculationType.labels.materialInfo'),
  },
  {
    value: true,
    key: 'labour' as const,
    label: i18n.t('entity.calculationType.labels.labour'),
    additionalInfo: i18n.t('entity.calculationType.labels.labourInfo'),
  },
  {
    value: true,
    key: 'lacquerWork' as const,
    label: i18n.t('entity.calculationType.labels.lacquerWork'),
    additionalInfo: i18n.t('entity.calculationType.labels.lacquerWorkInfo'),
  },
  {
    value: true,
    key: 'lacquerMaterial' as const,
    label: i18n.t('entity.calculationType.labels.lacquerMaterial'),
    additionalInfo: i18n.t('entity.calculationType.labels.lacquerMaterialInfo'),
  },
  {
    value: true,
    key: 'lacquerConstant' as const,
    label: i18n.t('entity.calculationType.labels.lacquerConstant'),
    additionalInfo: i18n.t('entity.calculationType.labels.lacquerConstantInfo'),
  },
  {
    value: true,
    key: 'consumablesSurcharge' as const,
    label: i18n.t('entity.calculationType.labels.consumablesSurcharge'),
    additionalInfo: i18n.t('entity.calculationType.labels.consumablesSurchargeInfo'),
  },
  {
    value: true,
    key: 'dentWork' as const,
    label: i18n.t('entity.calculationType.labels.dentWork'),
    additionalInfo: i18n.t('entity.calculationType.labels.dentWorkInfo'),
  },
];

interface SilverdatCalculationsProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  onClose: () => void;
}

export function SilverdatCalculations(props: SilverdatCalculationsProps) {
  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId: props.serviceCaseId});
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleV2Query(
    {vehicleId: serviceCase?.vehicleId ?? ''},
    {skip: isNil(serviceCase?.vehicleId)}
  );

  const [postServiceOrderMyClaimCalculations, {isLoading}] =
    usePostServiceOrderMyClaimCalculationsMutation();

  const [settings, setSetings] = useState(DEFAULT_SETTINGS);
  const [selectedCalculation, setCalculation] = useState<string | Nullish>();
  const [selectedCalculationType, setCalculationType] = useState('SERVICE_ORDER');

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([
      filter,
      {
        serviceOrderId: props.serviceOrderId,
        vehicleVin: selectedCalculationType === 'VEHICLE' ? vehicle?.vin : undefined,
        calculationType: selectedCalculationType,
      },
    ]);

  const handleChipsChange = (value: string[] | Nullish) => {
    if (isNil(value)) {
      return;
    }

    setCalculationType(value[0]);
  };

  const handleConfirm = () => {
    if (isNil(selectedCalculation)) {
      return;
    }

    const settingsObject = settings.reduce((acc, setting) => {
      acc[setting.key] = setting.value;
      return acc;
    }, {} as MyClaimCalculationSettings);

    postServiceOrderMyClaimCalculations({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {myClaimId: selectedCalculation, settings: settingsObject},
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(props.onClose)
      .catch(handleApiError);
  };

  const chipOptions = buildArray<ChipsOption>([
    {label: i18n.t('entity.calculationType.labels.serviceOrder'), value: 'SERVICE_ORDER'},
  ])
    .when(isNotNilOrEmpty(vehicle?.vin), {
      label: i18n.t('entity.calculationType.labels.vehicle'),
      value: 'VEHICLE',
    })
    .add({
      label: i18n.t('entity.calculationType.labels.allCalculations'),
      value: 'ALL_CALCULATIONS',
    });

  return (
    <DataStatus
      isLoading={isServiceCaseLoading || isVehicleLoading}
      isError={isServiceCaseError || isVehicleError}
    >
      <HStack spacing={4}>
        <Box width={90}>
          <ImportSettings
            settings={settings}
            setSetings={setSetings}
            data-testid={suffixTestId('importSettings', props)}
          />
        </Box>
        <Box flex={1}>
          <Chips
            value={[selectedCalculationType]}
            onChange={handleChipsChange}
            options={chipOptions}
            data-testid={suffixTestId('calculationType', props)}
          />
          <Separator />
          <Box height={170}>
            <DataGrid
              // need to change queryModifier, so cannot use `useRefreshDataGrid`
              // eslint-disable-next-line no-restricted-syntax
              key={selectedCalculationType}
              gridCode="service-order-myclaim-calculation"
              onRowSelectionChange={(rows) => {
                const castedRows = rows as {contractId?: {value?: string}}[];

                setCalculation(head(castedRows)?.contractId?.value);
              }}
              queryModifier={queryModifier}
              data-testid={suffixTestId('datagrid', props)}
            />
          </Box>
          <Space vertical={4} />
        </Box>
      </HStack>
      <ButtonGroup align="right">
        <Button
          title={i18n.t('general.actions.discard')}
          variant="secondary"
          onClick={props.onClose}
          data-testid={suffixTestId('discard', props)}
        />
        <Button
          title={i18n.t('general.actions.confirm')}
          variant="primary"
          onClick={handleConfirm}
          isLoading={isLoading}
          isDisabled={isNilOrEmpty(selectedCalculation)}
          data-testid={suffixTestId('confirm', props)}
        />
      </ButtonGroup>
    </DataStatus>
  );
}
