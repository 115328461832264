import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  closeCurrentDialog,
  openConfirmDialog,
  openDeleteDialog,
  openDialog,
} from 'platform/components';

import {useSelector} from 'react-redux';
import {createSearchParams, useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil, isTrue} from 'ramda-adjunct';

import {
  EntityResourceIds,
  selectSaleVehicle,
  useArchiveVehicleMutation,
  useCancelVehicleReservationMutation,
  useGetParticipationQuery,
  useGetVehicleV2Query,
  useUnarchiveVehicleMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds, workshopRoutes} from '@omnetic-dms/routes';
import {
  VehicleReservationWizard,
  handleApiError,
  usePermissions,
  useSaleVehicleActions,
} from '@omnetic-dms/shared';

import {buildArray, composePath} from 'shared';

import {VehicleBuyForm} from '../(sections)/VehicleDetails/components/VehicleBuyForm';
import {useGetSecondaryVehicleHeaderActions} from './useGetSecondaryVehicleHeaderActions';

export const useGetVehicleHeaderActions = (vehicleId: string) => {
  const navigate = useNavigate();
  const secondaryActions = useGetSecondaryVehicleHeaderActions(vehicleId);

  const {data: saleVehicle} = useSelector(selectSaleVehicle(vehicleId));
  const [cancelSaleVehicleReservation] = useCancelVehicleReservationMutation();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: saleVehicle?.saleBusinessCaseId ?? '',
    },
    {skip: isNil(saleVehicle?.saleBusinessCaseId)}
  );

  const {isSaleActionEnabled} = useSaleVehicleActions(vehicleId);

  const [
    canReadPurchaseVehicle,
    canReserve,
    canReadActiveReservation,
    canReadReservationHistory,
    canCancelReservation,
    canUpdateReservation,
    hasCreateBusinessCasePurchasePermission,
    hasCreateBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    canReadSellVehicle,
    hasAddVehicleToSellPermission,
    canUpdateVehicle,
  ] = usePermissions({
    permissionKeys: [
      'viewBusinessCasePurchase',
      'vehicleReserve',
      'vehicleReadActiveReservation',
      'vehicleReadReservationHistory',
      'vehicleCancelReservation',
      'vehicleUpdateReservation',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'viewBusinessCaseSelling',
      'addVehicleToSell',
      'updateVehicle',
    ],
    scopes: {
      vehicleCancelReservation: {participation: vehicleParticipation},
      vehicleUpdateReservation: {participation: vehicleParticipation},
      vehicleReadActiveReservation: {participation: vehicleParticipation},
      vehicleReadReservationHistory: {participation: vehicleParticipation},
      vehicleReserve: {participation: vehicleParticipation},
      updateVehicle: {participation: vehicleParticipation},
      viewBusinessCasePurchase: {participation: businessCaseParticipation},
      viewBusinessCaseSelling: {participation: businessCaseParticipation},
    },
  });

  const isSalesModuleEnabled = isFeatureEnabled(featureFlags.ACL_SALES);

  const canCreatePurchaseBusinessCase =
    hasCreateBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;
  const canCreateSellBusinessCase =
    hasCreateBusinessCaseSellingPermission && hasAddVehicleToSellPermission;

  const isSaleVehicle = isNotNil(saleVehicle);

  const {data: vehicle} = useGetVehicleV2Query({vehicleId});
  const [unarchiveVehicle, {isLoading: isLoadingArchiveVehicle}] = useUnarchiveVehicleMutation();
  const [archiveVehicle, {isLoading: isLoadingUnarchiveVehicle}] = useArchiveVehicleMutation();

  const isArchived = isTrue(vehicle?.isArchived);
  const handleArchiveVehicle = isArchived ? unarchiveVehicle : archiveVehicle;
  const isArchiveLoading = isLoadingArchiveVehicle || isLoadingUnarchiveVehicle;

  const handleDeleteReservation = () =>
    openDeleteDialog({
      onConfirm: () => cancelSaleVehicleReservation({vehicleId}).unwrap().catch(handleApiError),
      text: i18n.t('entity.vehicle.labels.cancelCurrentReservation'),
    });

  const openReservationWizard = () =>
    openDialog(
      <VehicleReservationWizard
        vehicleId={vehicleId}
        onComplete={closeCurrentDialog}
        onCancel={closeCurrentDialog}
        data-testid={testIds.vehicles.detail('reservation')}
      />,
      {
        'data-testid': testIds.vehicles.detail('reservation'),
        title: i18n.t('entity.vehicle.labels.reservation'),
        size: 'large',
      }
    );

  const onClickBuy = () => {
    openDialog(<VehicleBuyForm vehicleId={vehicleId} />, {
      id: 'vehicleBuy-dialog',
      title: i18n.t('entity.vehicle.actions.addVehicleToBuy'),
      'data-testid': 'vehicleBuy-dialog',
    });
  };

  const onClickSell = () => {
    const navigateToCreateBusinessCaseConcept = () =>
      navigate({
        pathname: businessCaseRoutes.concept,
        search: createSearchParams({
          vehicleId,
        }).toString(),
      });

    if (saleVehicle?.activeReservation?.paidAdvanceDeposit) {
      openConfirmDialog({
        text: i18n.t('entity.vehicle.labels.reservationWithAdvanceDepositExistsConfirmation'),
        onConfirm: navigateToCreateBusinessCaseConcept,
      });
    } else {
      navigateToCreateBusinessCaseConcept();
    }
  };

  const openNewServiceCase = () =>
    navigate(composePath(workshopRoutes.createServiceCase, {queryParams: {vehicleId}}));

  return buildArray<Action>()
    .when(secondaryActions.length > 0, {
      type: 'dropdown-iconButton',
      priority: 'secondary',
      menuItems: secondaryActions,
      'data-testid': testIds.vehicles.detail('vehicleHeader-more'),
    })
    .when(
      saleVehicle?.activeReservation &&
        !saleVehicle?.activeReservation?.paidAdvanceDeposit &&
        isSaleVehicle &&
        canCancelReservation &&
        canReadActiveReservation &&
        canReadReservationHistory,
      {
        type: 'button',
        variant: 'secondary',
        title: i18n.t('entity.vehicle.actions.cancelReservation'),
        onClick: handleDeleteReservation,
        'data-testid': testIds.vehicles.detail('vehicleHeader-reservation'),
      }
    )
    .when(
      (isSaleActionEnabled('SALE_VEHICLE_RESERVE') || saleVehicle?.activeReservation) &&
        isSaleVehicle &&
        canReserve &&
        canReadActiveReservation &&
        canReadReservationHistory &&
        canUpdateReservation,
      {
        type: 'button',
        variant: 'secondary',
        title: i18n.t('entity.vehicle.labels.reservation'),
        onClick: openReservationWizard,
        'data-testid': testIds.vehicles.detail('vehicleHeader-reservation'),
      }
    )
    .when(
      isSaleActionEnabled('SALE_VEHICLE_BUY') &&
        isSaleVehicle &&
        canReadPurchaseVehicle &&
        canCreatePurchaseBusinessCase,
      {
        type: 'button',
        variant: isSaleActionEnabled('SALE_VEHICLE_SELL') ? 'secondary' : 'primary',
        title: i18n.t('general.actions.buy'),
        onClick: onClickBuy,
        'data-testid': testIds.vehicles.detail('vehicleHeader-buy'),
      }
    )
    .when(
      isSalesModuleEnabled &&
        isSaleActionEnabled('SALE_VEHICLE_SELL') &&
        isSaleVehicle &&
        canReadSellVehicle &&
        canCreateSellBusinessCase,
      {
        type: 'button',
        variant: 'primary',
        title: i18n.t('general.actions.sell'),
        onClick: onClickSell,
        'data-testid': testIds.vehicles.detail('vehicleHeader-sell'),
      }
    )
    .when(
      !isSaleVehicle &&
        isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2) &&
        canUpdateVehicle,
      {
        type: 'button',
        variant: 'secondary',
        title: isArchived
          ? i18n.t('entity.vehicle.labels.unarchive')
          : i18n.t('entity.vehicle.labels.archive'),
        onClick: () => handleArchiveVehicle({vehicleId}),
        isLoading: isArchiveLoading,
        'data-testid': testIds.vehicles.detail(
          isArchived ? 'vehicleHeader-unarchive' : 'vehicleHeader-archive'
        ),
      }
    )
    .whenNot(isSaleVehicle, {
      type: 'button',
      variant: isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)
        ? 'primary'
        : 'secondary',
      title: i18n.t('page.workshop.labels.newServiceCase'),
      onClick: openNewServiceCase,
      'data-testid': testIds.vehicles.detail('vehicleHeader-create-service-case'),
    });
};
