import {
  ButtonVariant,
  closeCurrentDialog,
  ColorSchemeType,
  DataStatus,
  openDialog,
  Parameter,
  PhoneNumber,
  showNotification,
  UploadImageValue,
} from 'platform/components';
import {formatPhoneNumber, useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, ifElse, isNil, pathOr, pipe} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  PutEmployeeActionApiArg,
  useGetEmployeeActionsQuery,
  useGetEmployeePersonalDataQuery,
  useGetEmployeeAvatarUrlQuery,
  usePutEmployeeActionMutation,
  useSetEmployeePhotoMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {employeeRoutes, testIds} from '@omnetic-dms/routes';
import {
  DetailTemplateHeader,
  EntityHeader,
  handleApiError,
  NavigationItem,
  Page,
} from '@omnetic-dms/shared';

import {buildArray, composePath, Nullish, parseDate} from 'shared';

import {ExternalSystems} from './(sections)/ExternalSystems/ExternalSystems';
import {OrganizationalPlacement} from './(sections)/OrganizationPlacement/OrganizationalPlacement';
import {PersonalInfo} from './(sections)/PersonalInfo/PersonalInfo';
import {UserDataAndRoles} from './(sections)/UserDataAndRole/UserDataAndRoles';
import {UploadEmployeeImage} from './components/UploadEmployeeImage';
import {useEmployeeUrl} from './hooks/useEmployeeUrl';
import {getEmployeeName} from './utils/getEmployeeName';

export function EmployeeDetail() {
  const {employeeId} = useEmployeeUrl();
  const formatDate = useDateTimeFormatter();

  const {data: employeeAvatar} = useGetEmployeeAvatarUrlQuery({employeeId});
  const {data, isLoading, isError} = useGetEmployeePersonalDataQuery({employeeId});
  const {data: employeeActions} = useGetEmployeeActionsQuery({employeeId});

  const [putEmployeeAction, {isLoading: isPutActionLoading}] = usePutEmployeeActionMutation();
  const [setEmployeePhoto] = useSetEmployeePhotoMutation();

  const handleEmployeeActionChange = (actionKey: 'activate' | 'deactivate' | Nullish) => {
    if (isNil(actionKey)) {
      return;
    }

    const patchEmployeeArg: PutEmployeeActionApiArg = {
      employeeId,
      body: {actionKey},
    };

    putEmployeeAction(patchEmployeeArg).unwrap().catch(handleApiError);
  };

  const handleSetEmployeeImage = (image: UploadImageValue) => {
    setEmployeePhoto({employeeId, setEmployeePhotoRequestBody: {fileId: image.id}})
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleSelectEmployeeImage = () => {
    openDialog(
      <UploadEmployeeImage
        data-testid={testIds.employee.detail('upload-photo')}
        handleUpload={handleSetEmployeeImage}
        employeeId={employeeId}
      />,
      {title: i18n.t('general.actions.upload')}
    );
  };

  const actionButton = match(employeeActions?.employeeActions?.optionClick)
    .with(
      'activate',
      always({
        title: i18n.t('page.employeeDetail.actions.activateEmployee'),
        variant: 'primary' as ButtonVariant,
      })
    )
    .with(
      'deactivate',
      always({
        title: i18n.t('page.employeeDetail.actions.deactivateEmployee'),
        variant: 'secondary' as ButtonVariant,
      })
    )
    .otherwise(always(undefined));

  const stateFlags = match(data?.state)
    .with(
      'COREMPLOYEESTATE_DEACTIVATED',
      always([
        {
          label: i18n.t('entity.employee.labels.flagDeactivated'),
          colorScheme: 'red' as ColorSchemeType,
        },
      ])
    )
    .with(
      'COREMPLOYEESTATE_ACTIVE',
      always([
        {
          label: i18n.t('entity.employee.labels.flagActive'),
          colorScheme: 'green' as ColorSchemeType,
        },
      ])
    )
    .otherwise(always([]));

  const canChangeEmployeeAction =
    isNotNilOrEmpty(employeeActions?.employeeActions?.optionClick) && isNotNil(actionButton);

  const header: DetailTemplateHeader = {
    title: getEmployeeName(data),
    flags: stateFlags,
    iconActions: [{icon: 'image/edit', onClick: handleSelectEmployeeImage}],
    imageActions: [{icon: 'image/edit', onClick: handleSelectEmployeeImage}],
    image: employeeAvatar,
    parameters: buildArray<Parameter>()
      .when(
        data?.employmentData?.startDate,
        () =>
          `${i18n.t('entity.employee.labels.startDate')}: ${formatDate(
            'dateShort',
            parseDate(data?.employmentData?.startDate ?? '')
          )}`
      )
      .when(
        data?.employmentData?.endDate,
        () =>
          `${i18n.t('entity.employee.labels.endDate')}: ${formatDate(
            'dateShort',
            parseDate(data?.employmentData?.endDate ?? '')
          )}`
      ),
    actions: canChangeEmployeeAction
      ? [
          {
            type: 'button',
            variant: actionButton?.variant,
            title: actionButton?.title ?? '',
            isLoading: isPutActionLoading,
            onClick: () =>
              handleEmployeeActionChange(employeeActions?.employeeActions?.optionClick),
          },
        ]
      : undefined,
    icon: 'custom/employee',
    primaryParameter: pipe(
      pathOr(null, ['contactData', 'phone_number_work']),
      ifElse(
        (phoneNumber: PhoneNumber | Nullish) => isNilOrEmpty(phoneNumber?.number),
        always(i18n.t('entity.customer.labels.noPhoneNumber')),
        (phoneNumber) =>
          formatPhoneNumber(`${phoneNumber?.prefix}${phoneNumber?.number}`) ?? undefined
      )
    )(data),
    secondaryParameter: pathOr(i18n.t('entity.customer.labels.noEmail'), [
      'contactData',
      'email_work',
    ])(data),
  };

  const navigationItems: NavigationItem[] = [
    {
      id: 'personalInfo',
      content: <PersonalInfo data-testid={testIds.employee.detail('personalInfo')} />,
      label: i18n.t('page.employeeDetail.labels.personalInfo'),
      'data-testid': testIds.employee.detail('navigation-personalInfo'),
      href: composePath(employeeRoutes.personalInfo, {params: {id: employeeId}}),
    },
    {
      id: 'organizationalPlacement',
      content: (
        <OrganizationalPlacement data-testid={testIds.employee.detail('organizationalPlacement')} />
      ),
      label: i18n.t('page.employeeDetail.labels.organizationalPlacement'),
      'data-testid': testIds.employee.detail('navigation-organizationalPlacement'),
      href: composePath(employeeRoutes.organizationalPlacement, {params: {id: employeeId}}),
    },
    {
      id: 'UserDataAndRole',
      content: <UserDataAndRoles data-testid={testIds.employee.detail('UserDataAndRole')} />,
      label: i18n.t('page.employeeDetail.labels.UserDataAndRole'),
      'data-testid': testIds.employee.detail('navigation-UserDataAndRole'),
      href: composePath(employeeRoutes.userDataAndRole, {params: {id: employeeId}}),
    },
    {
      id: 'externalSystem',
      content: <ExternalSystems data-testid={testIds.employee.detail('externalSystem')} />,
      label: i18n.t('page.employeeDetail.labels.externalSystem'),
      'data-testid': testIds.employee.detail('navigation-externalSystem'),
      href: composePath(employeeRoutes.externalSystems, {params: {id: employeeId}}),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.employeeDetail.labels.title')} />
      <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
        <Page
          key={employeeId}
          navigation={navigationItems}
          header={<EntityHeader {...header} data-testid={testIds.employee.detail('header')} />}
          data-testid={testIds.employee.detail('page')}
        />
      </DataStatus>
    </>
  );
}
