import {Action, Card, DataStatus, openDeleteDialog, showNotification} from 'platform/components';
import {useFormatCurrency} from 'platform/locale';

import {isNil} from 'ramda';

import {
  UpdateInterestApiArg,
  UpdateMinipurchaseApiArg,
  useUpdateInterestMutation,
  useUpdateMinipurchaseMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {handleApiError, usePricePermissions, VehicleWidgetCard} from '@omnetic-dms/shared';

import {buildArray, composePath, RequiredTestIdProps, suffixTestId} from 'shared';

import {useInterestData} from '../hooks/useInterestData';

interface OfferedVehiclesProps extends RequiredTestIdProps {
  interestId: string;
}

export function OfferedVehicles(props: OfferedVehiclesProps) {
  const formatPrice = useFormatCurrency();

  const {canViewAnyOfVehiclePriceFields: canReadPrices} = usePricePermissions({
    vehicleRecordId: null,
    businessCaseRecordId: null,
  });

  const {interest, offeredVehiclesList, miniPurchase, isLoading, isError} = useInterestData(
    props.interestId
  );

  const [updateMinipurchase, {isLoading: isUpdateMinipurchaseLoading}] =
    useUpdateMinipurchaseMutation();
  const [updateInterest] = useUpdateInterestMutation();

  const onRemoveVehicle = (vehicleId: string) => {
    if (!miniPurchase) {
      showNotification.error('missing miniPurchase object');
      return;
    }

    const vehicleIdsCopy = miniPurchase.offeredVehiclesIds?.filter(
      (id: string) => id !== vehicleId
    );

    const hasAnyOfferedVehicles = vehicleIdsCopy && vehicleIdsCopy.length > 0;

    const miniPurchaseBody = hasAnyOfferedVehicles
      ? miniPurchase
      : {id: miniPurchase.id, interestId: miniPurchase.interestId};

    const arg: UpdateMinipurchaseApiArg = {
      interestId: props.interestId,
      body: {
        ...miniPurchaseBody,
        minipurchaseId: miniPurchase.id,
        offeredVehiclesIds: vehicleIdsCopy,
      },
    };

    updateMinipurchase(arg)
      .unwrap()
      .then(() => {
        if (!hasAnyOfferedVehicles) {
          const args: UpdateInterestApiArg = {
            id: props.interestId,
            updateInterestRequestBody: {
              type: 'BUYING',
            },
          };
          updateInterest(args).unwrap().catch(handleApiError);
        }
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus minHeight={25} isLoading={isLoading} isError={isError}>
      {offeredVehiclesList && (
        <Card title={i18n.t('entity.interest.labels.sellingTab')}>
          <VehicleWidgetCard
            data-testid={suffixTestId('offeredList', props)}
            vehicles={offeredVehiclesList.map((vehicle, index) => ({
              id: vehicle.id,
              vin: vehicle.vin,
              licencePlate: vehicle.vehicle.registrationPlate,
              firstRegistration: vehicle.vehicle.firstRegistrationOn,
              make: vehicle.make,
              vehicleType: vehicle.type?.toString(),
              modelFamily: vehicle.modelFamily,
              trim: vehicle.trim,
              variant: vehicle.variant,
              mileage: vehicle.vehicle.mileage,
              transmission: vehicle.transmission,
              fuelType: vehicle.fuelType,
              bodyStyle: vehicle.bodyStyle,
              driveType: vehicle.drive,
              photos: vehicle.photos,
              firstParameter:
                // TODO: this ACL condition is not tied to recordId, should we abstract it?
                canReadPrices && vehicle.sellingPrice.withoutVat
                  ? formatPrice(
                      Number(vehicle.sellingPrice.withoutVat.amount),
                      vehicle.sellingPrice.withoutVat.currency,
                      0
                    )
                  : null,
              secondParameter:
                canReadPrices && vehicle.sellingPrice.withVat
                  ? formatPrice(
                      Number(vehicle.sellingPrice.withVat.amount),
                      vehicle.sellingPrice.withVat.currency,
                      0
                    )
                  : null,
              actions: buildArray<Action>()
                .add({
                  'data-testid': suffixTestId(`offeredList-delete-[${index}]`, props),
                  type: 'button',
                  leftIcon: 'action/delete',
                  variant: 'outlined',
                  title: i18n.t('general.labels.delete'),
                  isDisabled:
                    isNil(miniPurchase) ||
                    isUpdateMinipurchaseLoading ||
                    interest?.state === 'UNSUCCESSFUL' ||
                    interest?.state === 'CLOSED',
                  onClick: () =>
                    openDeleteDialog({
                      'data-testid': suffixTestId(`offeredList-delete-[${index}]`, props),
                      onConfirm: () => onRemoveVehicle(vehicle.id),
                    }),
                })
                .add({
                  'data-testid': suffixTestId(`offeredList-detail-[${index}]`, props),
                  type: 'button',
                  title: i18n.t('entity.vehicle.labels.detail'),
                  variant: 'outlined',
                  leftIcon: 'action/launch',
                  onClick: () => {
                    window.open(
                      composePath(vehiclesRoutes.detail, {params: {id: vehicle.id}}),
                      '_blank'
                    );
                  },
                }),
            }))}
          />
        </Card>
      )}
    </DataStatus>
  );
}
