import {Card, DataStatus, EmptyStatus, Separator} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {EntityResourceIds, useGetParticipationQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CommentsWidget,
  EntityCustomFields,
  Main,
  NoteWidget,
  usePermissions,
  usePricePermissions,
} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

import {useBusinessCase} from '../../hooks/useBusinessCase';
import {isBrokerageVehicle} from '../../utils/isBrokerageVehicle';
import {ClosedBusinessCaseDetails} from './components/ClosedBusinessCaseDetails';
import {FinancialOverview} from './components/FinancialOverview';
import {VehicleOverview} from './components/VehicleOverview';

export function BusinessCaseOverview() {
  const {id} = useRequiredParams();

  const {
    id: businessCaseId,
    businessCase: {interestId, ...businessCase},
    state,
    isLoading: isLoadingBusinessCase,
    isError: hasBusinessCaseError,
    saleVehicles,
    purchaseVehicles,
  } = useBusinessCase(id);

  const {canViewAnyOfSalePriceFields, canViewAnyOfPurchasePriceFields} = usePricePermissions({
    businessCaseRecordId: businessCaseId,
    vehicleRecordId: null,
  });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: businessCaseId ?? '',
      resourceId: EntityResourceIds.businessCase,
    },
    {
      skip: isNilOrEmpty(businessCaseId),
    }
  );

  const [
    canSetBusinessCasePurchaseNote,
    canReadBusinessCaseComments,
    canAddBusinessCaseComments,
    canDeleteBusinessCaseComments,
    canEditCustomFields,
  ] = usePermissions({
    permissionKeys: [
      'setBusinessCasePurchaseNote',
      'businessCaseCommentRead',
      'businessCaseCommentAdd',
      'businessCaseCommentDelete',
      'businessCaseCustomFields',
    ],
    scopes: {
      setBusinessCasePurchaseNote: {participation: businessCaseParticipation},
      businessCaseCommentRead: {participation: businessCaseParticipation},
      businessCaseCommentAdd: {participation: businessCaseParticipation},
      businessCaseCommentDelete: {participation: businessCaseParticipation},
      businessCaseCustomFields: {participation: businessCaseParticipation},
    },
  });
  const isNoteEditable =
    businessCase?.businessCaseInternalType === 'SELLING' ? true : canSetBusinessCasePurchaseNote;

  return (
    <Main>
      <DataStatus isLoading={isLoadingBusinessCase} isError={hasBusinessCaseError}>
        <Grid columns={2} align="flex-start">
          <Show when={state === 'UNSUCCESSFUL'}>
            <GridItem span={2}>
              <ClosedBusinessCaseDetails
                businessCase={businessCase}
                data-testid={testIds.businessCase.overview('closedBusinessCaseDetails')}
              />
            </GridItem>
          </Show>
          <Show when={canViewAnyOfSalePriceFields || canViewAnyOfPurchasePriceFields}>
            <GridItem span={2}>
              <FinancialOverview businessCaseId={businessCaseId!} />
            </GridItem>
          </Show>
          <GridItem span={2}>
            <Card
              title={i18n.t('entity.vehicle.labels.pageTitle')}
              data-testid={testIds.businessCase.overview('vehicles')}
            >
              <Show when={Boolean(saleVehicles?.length) || Boolean(purchaseVehicles?.length)}>
                <VStack>
                  {saleVehicles?.map((vehicle, i) => (
                    <VehicleOverview
                      data-testid={testIds.businessCase.overview(`saleVehicle[${i}]`)}
                      key={vehicle.id}
                      vehicleId={vehicle.vehicleId}
                      isBrokerage={isBrokerageVehicle(vehicle)}
                    />
                  ))}
                  {Boolean(purchaseVehicles?.length) && Boolean(saleVehicles?.length) && (
                    <Separator />
                  )}
                  {purchaseVehicles?.map((vehicle, i) => (
                    <VehicleOverview
                      key={vehicle.id}
                      data-testid={testIds.businessCase.overview(`purchaseVehicle[${i}]`)}
                      vehicleId={vehicle.vehicleId}
                      isBrokerage={isBrokerageVehicle(vehicle)}
                    />
                  ))}
                </VStack>
              </Show>
              <Hide when={Boolean(saleVehicles?.length) || Boolean(purchaseVehicles?.length)}>
                <EmptyStatus
                  data-testid={testIds.businessCase.overview('no-vehicles')}
                  headline={i18n.t('entity.vehicle.notifications.noVehicles')}
                  subheadline={i18n.t('entity.vehicle.notifications.noVehiclesSub')}
                />
              </Hide>
            </Card>
          </GridItem>
          <Show when={canReadBusinessCaseComments}>
            <VStack spacing={4}>
              <EntityCustomFields
                recordId={id}
                resourceId={EntityResourceIds.businessCase}
                isEditable={canEditCustomFields}
                data-testid={testIds.vehicles.detail('vehicle-custom-fields')}
              />
              <CommentsWidget
                data-testid={testIds.businessCase.overview('comments')}
                title={i18n.t('entity.businessCase.labels.businessCaseComments')}
                resourceId={EntityResourceIds.businessCase}
                recordId={businessCaseId ?? ''}
                isAddDisabled={!canAddBusinessCaseComments}
                isDeleteDisabled={!canDeleteBusinessCaseComments}
              />
            </VStack>
          </Show>
          <Hide when={canReadBusinessCaseComments}>
            <Space fillAvailable />
          </Hide>
          <NoteWidget
            data-testid={testIds.businessCase.overview('note')}
            resourceId={EntityResourceIds.businessCase}
            recordId={businessCaseId ?? ''}
            context={EntityResourceIds.businessCase}
            isEditable={isNoteEditable}
          />
        </Grid>
      </DataStatus>
    </Main>
  );
}
