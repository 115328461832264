import {isNotNil} from 'ramda';

import {SeriesPatternRequestBody} from '@omnetic-dms/api';

import {getApiDateString} from 'shared';

import {DocumentSeriesFormValues} from '../types/DocumentSeriesFormValues';
import {getRawStartingNumber} from './getRawStartingNumber';

export const transformPatternsToString = (
  patterns: DocumentSeriesFormValues['patterns']
): SeriesPatternRequestBody[] =>
  patterns?.map((pattern) => ({
    ...pattern,
    startingNumber: Number(getRawStartingNumber(pattern)),
    from: isNotNil(pattern.from) ? getApiDateString(pattern.from) : null,
  }));
