import {Card, Switch} from 'platform/components';
import {HStack, Show, Text, VStack} from 'platform/foundation';

import {Dispatch, SetStateAction} from 'react';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps} from 'shared';

type Setting = {key: string; value: boolean; label: string; additionalInfo?: string};

interface ImportSettingsProps<T extends Setting> extends RequiredTestIdProps {
  settings: T[];
  setSetings: Dispatch<SetStateAction<T[]>>;
}

export function ImportSettings<T extends Setting>(props: ImportSettingsProps<T>) {
  return (
    <Card
      data-testid="inlineGreyWithTitle"
      title={i18n.t('entity.calculationType.labels.importSettings')}
      variant="inlineGrey"
      isFullHeight
    >
      <VStack spacing={4}>
        <Text color="secondary">{i18n.t('entity.calculationType.labels.importSettingsInfo')}</Text>
        {props.settings.map((setting) => (
          <HStack key={setting.key} align="center" spacing={3}>
            <Switch
              value={setting.value}
              onChange={(newValue) =>
                props.setSetings(
                  props.settings.map((s) => (s.key === setting.key ? {...s, value: newValue} : s))
                )
              }
            />
            <VStack>
              <Text>{setting.label}</Text>
              <Show when={setting.additionalInfo}>
                <Text>{setting.additionalInfo}</Text>
              </Show>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </Card>
  );
}
