import {
  Alert,
  Button,
  closeCurrentDialog,
  DataStatus,
  EmptyStatus,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {Box, Hide, Right, Show, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {isNil, not} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useCancelVehicleReservationMutation,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useVehicleActiveReservationQuery,
  useVehicleReservationHistoryQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';

import {composePath, TestIdProps} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {useSaleVehicleActions} from '../../hooks/useSaleVehicleActions';
import {handleApiError} from '../../utils/handleApiError';
import {Main} from '../Main/Main';
import {VehicleReservationWizard} from '../VehicleReservationWizard/VehicleReservationWizard';
import {ActiveReservationCard} from './components/ActiveReservationCard';

interface VehicleReservationsProps extends TestIdProps {
  vehicleId: string;
  customerId?: string;
  businessCaseId?: string;
}

export function VehicleReservations(props: VehicleReservationsProps) {
  const {data: saleVehicle, isLoading} = useGetSaleVehicleQuery(
    {vehicleId: props.vehicleId},
    {skip: isNilOrEmpty(props.vehicleId)}
  );
  const {data: activeReservation, isLoading: isActiveReservationLoading} =
    useVehicleActiveReservationQuery({vehicleId: props.vehicleId});

  const {data: reservationsHistorySaleVehicle, isLoading: isReservationHistoryLoading} =
    useVehicleReservationHistoryQuery(
      {
        vehicleId: props.vehicleId,
        saleVehicleId: saleVehicle?.id ?? '',
      },
      {skip: isNilOrEmpty(saleVehicle?.id) && isNotNilOrEmpty(props.businessCaseId)}
    );

  const {
    data: reservationsHistoryBusinessCase,
    isLoading: isReservationHistoryBusinessCaseLoading,
  } = useVehicleReservationHistoryQuery(
    {
      vehicleId: props.vehicleId,
      businessCaseId: props.businessCaseId ?? '',
    },
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const [cancelSaleVehicleReservation] = useCancelVehicleReservationMutation();
  const {isSaleActionEnabled} = useSaleVehicleActions(props.vehicleId);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const [canReserve, canReadActiveReservation, canReadReservationHistory] = usePermissions({
    permissionKeys: [
      'vehicleReserve',
      'vehicleReadActiveReservation',
      'vehicleReadReservationHistory',
    ],
    scopes: {
      vehicleReserve: {participation: vehicleParticipation},
      vehicleReadActiveReservation: {participation: vehicleParticipation},
      vehicleReadReservationHistory: {participation: vehicleParticipation},
    },
  });

  const canShowDifferentCustomerWarning =
    isNotNil(props.businessCaseId) &&
    isNotNil(activeReservation?.customer?.id) &&
    activeReservation?.customer?.id !== props.customerId;

  const navigate = useNavigate();

  const openWizard = () => {
    openDialog(
      <VehicleReservationWizard
        vehicleId={props.vehicleId}
        onComplete={closeCurrentDialog}
        onCancel={closeCurrentDialog}
        customerId={props.customerId}
        businessCaseId={props.businessCaseId}
        data-testid={testIds.vehicles.reservations('reservation')}
      />,
      {
        'data-testid': testIds.vehicles.reservations('reservation'),
        title: i18n.t('entity.vehicle.labels.reservation'),
        size: 'large',
      }
    );
  };

  const onReservationDelete = () =>
    openDeleteDialog({
      onConfirm: () =>
        cancelSaleVehicleReservation({vehicleId: props.vehicleId}).unwrap().catch(handleApiError),
      text: i18n.t('entity.vehicle.labels.cancelCurrentReservation'),
      'data-testid': testIds.vehicles.reservations('cancelCurrentReservation'),
    });

  return (
    <Main data-testid={testIds.vehicles.reservations('page')}>
      <VStack spacing={4}>
        <DataStatus isLoading={isLoading}>
          <VStack spacing={4}>
            <Show when={canShowDifferentCustomerWarning}>
              <Alert
                data-testid={testIds.vehicles.reservations('reservedForDifferentCustomer')}
                title={i18n.t('entity.vehicle.labels.reservedForDifferentCustomer')}
                type="inline"
                variant="error"
                hyperlinks={[
                  {
                    title: i18n.t('entity.vehicle.labels.showReservation'),
                    href: composePath(businessCaseRoutes.reservations, {
                      params: {id: saleVehicle?.activeReservation?.businessCaseId ?? ''},
                    }),
                    size: 'small',
                  },
                ]}
              />
            </Show>

            <Hide when={canShowDifferentCustomerWarning || saleVehicle?.activeReservation}>
              <Box padding={11}>
                <Show when={isSaleActionEnabled('SALE_VEHICLE_RESERVE')}>
                  <EmptyStatus
                    headline={i18n.t('entity.vehicle.labels.noActiveReservation')}
                    action={
                      canReserve && canReadActiveReservation && canReadReservationHistory
                        ? {
                            title: i18n.t('entity.vehicle.actions.addReservation'),
                            onClick: openWizard,
                            leftIcon: 'content/add_circle',
                          }
                        : {}
                    }
                  />
                </Show>
                <Show when={!isSaleActionEnabled('SALE_VEHICLE_RESERVE')}>
                  <EmptyStatus headline={i18n.t('entity.vehicle.labels.noActiveReservation')} />
                </Show>
              </Box>
            </Hide>

            <Show when={!canShowDifferentCustomerWarning && isNotNil(saleVehicle?.vehicleId)}>
              <Show
                when={isNotNil(saleVehicle?.activeReservation) && not(isActiveReservationLoading)}
              >
                <ActiveReservationCard
                  vehicleId={saleVehicle?.vehicleId ?? ''}
                  reservation={activeReservation!}
                  onEdit={openWizard}
                  onDelete={onReservationDelete}
                  data-testid={testIds.vehicles.reservations('reservation')}
                />
              </Show>
              <Show
                when={
                  !canShowDifferentCustomerWarning &&
                  isNotNilOrEmpty(saleVehicle?.nonActiveReservations) &&
                  not(isReservationHistoryLoading) &&
                  isNil(props.businessCaseId)
                }
              >
                {reservationsHistorySaleVehicle?.map((reservation) => (
                  <ActiveReservationCard
                    key={reservation.createdAt}
                    vehicleId={saleVehicle?.vehicleId ?? ''}
                    reservation={reservation}
                    isCancelled
                  />
                ))}
              </Show>
              <Show
                when={
                  !canShowDifferentCustomerWarning &&
                  isNotNilOrEmpty(saleVehicle?.nonActiveReservations) &&
                  not(isReservationHistoryBusinessCaseLoading) &&
                  isNotNil(props.businessCaseId)
                }
              >
                {reservationsHistoryBusinessCase?.map((reservation) => (
                  <ActiveReservationCard
                    key={reservation.createdAt}
                    vehicleId={saleVehicle?.vehicleId ?? ''}
                    reservation={reservation}
                    isCancelled
                  />
                ))}
              </Show>
            </Show>
          </VStack>
          <Show when={props.businessCaseId && !canShowDifferentCustomerWarning}>
            <Right>
              <Button
                data-testid={testIds.businessCase.buying('continue')}
                onClick={() =>
                  navigate(
                    composePath(businessCaseRoutes.insurance, {
                      params: {id: props.businessCaseId},
                    })
                  )
                }
                variant="outlined"
                rightIcon="navigation/chevron_right"
                title={i18n.t('general.actions.continue')}
              />
            </Right>
          </Show>
        </DataStatus>
      </VStack>
    </Main>
  );
}
