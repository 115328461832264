import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {usePatchUserMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, yupPasswordValidation} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type FormValues = {
  password: string;
  confirmPassword: string;
};

interface EditUserPasswordFormProps extends RequiredTestIdProps {
  userId: string;
}

export function EditUserPasswordForm(props: EditUserPasswordFormProps) {
  const [patchUser] = usePatchUserMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    await patchUser({
      userId: props.userId,
      updateUserRequestBody: {
        password: data.password,
      },
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  return (
    <Form<FormValues> schema={schema} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <Grid columns={2}>
            <FormField
              control={control}
              name="password"
              label={i18n.t('general.labels.password')}
              type="password"
              data-testid={suffixTestId('edit-password', props)}
            />

            <FormField
              control={control}
              name="confirmPassword"
              label={i18n.t('general.labels.confirmPassword')}
              type="password"
              data-testid={suffixTestId('edit-confirmPassword', props)}
            />
          </Grid>

          <ButtonGroup align="right">
            <Button
              variant="secondary"
              title={i18n.t('general.actions.discard')}
              data-testid={suffixTestId('edit-discard', props)}
              onClick={closeCurrentDialog}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.create')}
              data-testid={suffixTestId('edit-create', props)}
              variant="primary"
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  password: yupPasswordValidation,
  confirmPassword: yupPasswordValidation.test(
    'passwords-match',
    i18n.t('general.notifications.passwordsDoesNotMatch'),
    (value, context) => context.parent.password === value
  ),
});
