import {Card} from 'platform/components';
import {match} from 'ts-pattern';

import {RefObject, useCallback} from 'react';

import {isNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {CorrectionType} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {catchUnhandledDataGridActions} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

interface ReceiveNoteCorrectionAddMaterialListProps extends RequiredTestIdProps {
  receiveNoteId: string;
  dataGridRef: RefObject<DataGridRef>;
  onRefreshDataGrid: VoidFunction;
  onAddMaterialToBasket: (itemsIds: string[], correctionType: CorrectionType) => Promise<void>;
  onOpenMaterialDetailInNewTab: (warehouseId: string, articleId: string) => void;
}

export function ReceiveNoteCorrectionAddMaterialList(
  props: ReceiveNoteCorrectionAddMaterialListProps
) {
  const actionCallback: ActionCallback = ({actionKey, rowData, rowId}) => {
    const ids = isArray(rowId) ? rowId : [rowId];

    match(actionKey)
      .with('addReturn', () => {
        props.onAddMaterialToBasket(ids, 'RETURN_TO_SUPPLIER').then(props.onRefreshDataGrid);
      })
      .with('addValueChange', () => {
        props.onAddMaterialToBasket(ids, 'VALUE_CORRECTION').then(props.onRefreshDataGrid);
      })
      .with('detail', () => {
        const articleId = path(['articleId', 'value'], rowData) as string;
        const warehouseId = path(['warehouseId', 'value'], rowData) as string;

        if (isNil(warehouseId || articleId)) {
          throw new Error('Opening material detail in new tab is not possible');
        }

        props.onOpenMaterialDetailInNewTab(warehouseId, articleId);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {receiveNoteId: props.receiveNoteId}]),
    [props.receiveNoteId]
  );

  return (
    <Card
      title={i18n.t('entity.warehouse.labels.itemsList')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'navigation/refresh',
          title: i18n.t('general.actions.refresh'),
          onClick: props.onRefreshDataGrid,
          'data-testid': suffixTestId('actions.refresh', props),
        },
      ]}
      isFullHeight
      data-testid={props['data-testid']}
    >
      <DataGrid
        ref={props.dataGridRef}
        gridCode="warehouse-credit-note-item-add"
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        data-testid={suffixTestId('items', props)}
      />
    </Card>
  );
}
