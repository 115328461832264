import {FC} from 'react';

import {EntityResourceIds, useGetParticipationQuery} from '@omnetic-dms/api';
import {usePermissions} from '@omnetic-dms/shared';
import {PaymentStateEnum} from '@omnetic-dms/teas';

import {TestIdProps} from 'shared';

import {CheckoutOrderBillingProps} from '../types/CheckoutOrderBillingProps';
import {CheckoutOrderBillingDetail} from './CheckoutOrderBillingDetail';
import {CheckoutSelectOrderBilling} from './CheckoutSelectOrderBilling';

export const CheckoutOrderBilling: FC<CheckoutOrderBillingProps & TestIdProps> = ({
  checkoutContractsInformation,
  order,
  ...props
}) => {
  const issued = !order.payments.every(
    (payment) => payment.paymentState === PaymentStateEnum.CONCEPT
  );

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: props.businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [
    hasSelectBusinessCaseSellingCheckoutContractInformationPermission,
    hasSelectBusinessCasePurchaseCheckoutDeputyPersonPermission,
    hasSelectBusinessCasePurchaseCheckoutContractInformationPermission,
    hasSelectBusinessCaseSellingCheckoutDeputyPersonPermission,
  ] = usePermissions({
    permissionKeys: [
      'selectBusinessCaseSellingCheckoutContractInformation',
      'selectBusinessCasePurchaseCheckoutDeputyPerson',
      'selectBusinessCasePurchaseCheckoutContractInformation',
      'selectBusinessCaseSellingCheckoutDeputyPerson',
    ],
    scopes: {
      selectBusinessCaseSellingCheckoutContractInformation: {
        participation: businessCaseParticipation,
      },
      selectBusinessCasePurchaseCheckoutDeputyPerson: {participation: businessCaseParticipation},
      selectBusinessCasePurchaseCheckoutContractInformation: {
        participation: businessCaseParticipation,
      },
      selectBusinessCaseSellingCheckoutDeputyPerson: {participation: businessCaseParticipation},
    },
  });

  const canSelectDeputyPerson =
    order.orderDiscriminator === 'SALE'
      ? hasSelectBusinessCaseSellingCheckoutDeputyPersonPermission
      : hasSelectBusinessCasePurchaseCheckoutDeputyPersonPermission;

  const canSelectContract =
    order.orderDiscriminator === 'SALE'
      ? hasSelectBusinessCaseSellingCheckoutContractInformationPermission
      : hasSelectBusinessCasePurchaseCheckoutContractInformationPermission;

  if (issued || props.readonly || (!canSelectDeputyPerson && !canSelectContract)) {
    return (
      <CheckoutOrderBillingDetail
        checkoutContractsInformation={checkoutContractsInformation}
        order={order}
        hasContractSelectDisabled={!canSelectContract}
        hasDeputyPersonSelectDisabled={!canSelectDeputyPerson}
        {...props}
        data-testid={props['data-testid']}
      />
    );
  }

  return (
    <CheckoutSelectOrderBilling
      checkoutContractsInformation={checkoutContractsInformation}
      order={order}
      hasDeputyPersonSelectDisabled={!canSelectDeputyPerson}
      hasContractSelectDisabled={!canSelectContract}
      {...props}
      data-testid={props['data-testid']}
    />
  );
};
