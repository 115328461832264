import {showNotification} from 'platform/components';
import {Space, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {myProfileRoutes, testIds} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {MyProfileTemplate} from '../../components/MyProfileTemplate/MyProfileTemplate';

export function NotificationOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('detail', () =>
        navigate(composePath(myProfileRoutes.notificationDetail, {params: {id}}))
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <MyProfileTemplate
      data-testid={testIds.myProfile.notifications('header')}
      header={{
        title: i18n.t('page.settings.notifications.title'),
        breadcrumbs: [
          {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
          {label: i18n.t('page.settings.notifications.title'), isCurrentPage: true},
        ],
      }}
    >
      <Text color="secondary" size="small">
        {i18n.t('page.settings.notifications.subtitle')}
      </Text>
      <Space vertical={4} />
      <DataGrid
        gridCode="messaging-notification"
        actionCallback={actionCallback}
        data-testid={testIds.myProfile.notifications('datagrid')}
      />
    </MyProfileTemplate>
  );
}
