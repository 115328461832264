import {matchPath, useLocation, useSearchParams} from 'react-router-dom';

import {has} from 'ramda';
import {isNotNil, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  accountingRoutes,
  businessCaseRoutes,
  customerRoutes,
  employeeRoutes,
  interestRoutes,
  interestsRoutes,
  settingsRoutes,
  tiresInventoryRoutes,
  vehiclesRoutes,
  warehouseRoutes,
  workshopRoutes,
} from '@omnetic-dms/routes';

import {queryParams} from '../utils/queryParams';

type SingleSourceNavigationLink = {
  nested: string | string[];
  navigateTo: string;
  title: string;
  rowControlDetail?: string;
  sources?: never;
};

type MultiSourceNavigationLink = {
  nested: string | string[];
  navigateTo?: never;
  title?: never;
  sources: Record<
    string,
    {
      navigateTo: string;
      title: string;
      rowControlRoute?: string;
      rowControlDetail?: string;
    }
  >;
};
type NavigationLink = SingleSourceNavigationLink | MultiSourceNavigationLink;
type NavigationLinksType = Record<string, NavigationLink>;

/**
 * @example
 * [key: EntityId]{
 *      nested: string // witch part od route must be present in
 *      navigateTo: string // path that breadcrumb should navigate to after click
 *      title: string // text of the breadcrumb
 * }
 * @example
 * [key: EntityId]{
 *      nested: string
 *      sources: { // used when more than one place redirects to the same route
 *          [key: SourceId]: { // value of the query param 'source'
 *             navigateTo: string
 *             title: string
 *          }
 * }
 */
const NAVIGATION_LINKS: NavigationLinksType = {
  customers: {
    navigateTo: customerRoutes.overview,
    title: `${i18n.t('general.labels.backToCustomer')}`,
    rowControlDetail: customerRoutes.rowControlDetail,
    nested: [
      customerRoutes.GDPRConsents,
      customerRoutes.activityLogs,
      customerRoutes.businessCases,
      customerRoutes.create,
      customerRoutes.customerContracts,
      customerRoutes.customerInformation,
      customerRoutes.documents,
      customerRoutes.interests,
      customerRoutes.serviceCases,
      customerRoutes.tires,
      customerRoutes.detail,
      customerRoutes.vehicles,
    ],
  },
  suppliers: {
    nested: customerRoutes.detail,
    navigateTo: settingsRoutes.suppliers,
    title: `${i18n.t('general.labels.backToSuppliers')}`,
  },
  interests: {
    nested: interestsRoutes.tabDetail,
    navigateTo: interestsRoutes.home,
    title: `${i18n.t('general.labels.backToInterest')}`,
    rowControlDetail: interestsRoutes.tabDetail,
  },
  interest: {
    nested: interestRoutes.detail,
    navigateTo: interestRoutes.overview,
    title: `${i18n.t('general.labels.backToInterest')}`,
    rowControlDetail: interestRoutes.detail,
  },
  businessCase: {
    nested: [
      businessCaseRoutes.concept,
      businessCaseRoutes.overview,
      businessCaseRoutes.customer,
      businessCaseRoutes.buying,
      businessCaseRoutes.selling,
      businessCaseRoutes.testDrive,
      businessCaseRoutes.reservations,
      businessCaseRoutes.insurance,
      businessCaseRoutes.checkout,
      businessCaseRoutes.complaints,
      businessCaseRoutes.documents,
      businessCaseRoutes.activityLog,
    ],
    navigateTo: businessCaseRoutes.home,
    title: `${i18n.t('general.labels.backToBusinessCase')}`,
    rowControlDetail: businessCaseRoutes.detail,
  },
  vehicles: {
    nested: [
      vehiclesRoutes.detail,
      vehiclesRoutes.conditions,
      vehiclesRoutes.conditionDetail,
      vehiclesRoutes.conditionDetailTab,
      vehiclesRoutes.inspectionDetail,
      vehiclesRoutes.inspectionDetailTab,
      vehiclesRoutes.conditionsInspections,
      vehiclesRoutes.vehicleWarehouseInfo,
      vehiclesRoutes.finances,
      vehiclesRoutes.financesPrices,
      vehiclesRoutes.financesEarningsCosts,
      vehiclesRoutes.publishing,
      vehiclesRoutes.photos,
      vehiclesRoutes.photoByInspectionId,
      vehiclesRoutes.threeHundredAndSixtyPhotos,
      vehiclesRoutes.documents,
      vehiclesRoutes.customers,
      vehiclesRoutes.interests,
      vehiclesRoutes.testDrives,
      vehiclesRoutes.reservations,
      vehiclesRoutes.businessCases,
      vehiclesRoutes.complaints,
      vehiclesRoutes.activityLog,
      vehiclesRoutes.insights,
      vehiclesRoutes.vehicleData,
      vehiclesRoutes.features,
      vehiclesRoutes.serviceIntervals,
    ],
    navigateTo: vehiclesRoutes.home,
    title: `${i18n.t('general.labels.backToVehicle')}`,
    rowControlDetail: vehiclesRoutes.rowControlDetail,
  },
  create_service_case: {
    nested: workshopRoutes.draftServiceCase,
    navigateTo: workshopRoutes.serviceCases,
    title: `${i18n.t('general.labels.backToWorkshopCases')}`,
    rowControlDetail: workshopRoutes.draftServiceCase,
  },
  workshop_cases: {
    nested: workshopRoutes.serviceCaseDetail,
    sources: {
      'service-orders': {
        navigateTo: workshopRoutes.serviceOrders,
        title: `${i18n.t('general.labels.backToWorkshopOrders')}`,
        rowControlDetail: workshopRoutes.serviceCaseDetail,
      },
      'service-cases': {
        navigateTo: workshopRoutes.serviceCases,
        title: `${i18n.t('general.labels.backToWorkshopCases')}`,
        rowControlDetail: workshopRoutes.serviceCaseDetail,
      },
    },
  },
  serviceOrderIssues: {
    nested: [
      warehouseRoutes.serviceOrderIssueDetailOverview,
      warehouseRoutes.serviceOrderIssueDetailIssueNotes,
      warehouseRoutes.serviceOrderIssueDetailDocuments,
    ],
    navigateTo: warehouseRoutes.serviceOrderIssues,
    title: i18n.t('general.labels.backToServiceOrderIssues'),
    rowControlDetail: warehouseRoutes.serviceOrderIssueDetailRowControl,
  },
  issueNotes: {
    nested: [warehouseRoutes.issueNoteDetailOverview, warehouseRoutes.issueNoteDetailDocuments],
    navigateTo: warehouseRoutes.issueNotes,
    title: i18n.t('general.labels.backToIssueNotes'),
    rowControlDetail: warehouseRoutes.issueNoteDetailRowControl,
  },
  serviceOrderReturns: {
    nested: [warehouseRoutes.serviceOrderReturnsDetailOverview],
    navigateTo: warehouseRoutes.serviceOrderReturns,
    title: i18n.t('general.labels.backToServiceOrderReturns'),
    rowControlDetail: warehouseRoutes.serviceOrderReturnsDetailRowControl,
  },
  deliveryNotes: {
    nested: warehouseRoutes.deliveryNoteDetailOverview,
    navigateTo: warehouseRoutes.deliveryNoteList,
    title: i18n.t('general.labels.backToDeliveryNotes'),
    rowControlDetail: warehouseRoutes.deliveryNoteDetailRowControl,
  },
  warehouseReceiveNotes: {
    nested: [warehouseRoutes.receiveNoteDetailOverview, warehouseRoutes.receiveNoteDetailDocuments],
    navigateTo: warehouseRoutes.receiveNoteList,
    title: i18n.t('general.labels.backToReceiveNotes'),
    rowControlDetail: warehouseRoutes.receiveNoteDetailRowControl,
  },
  warehouseReceiveNoteCorrections: {
    nested: [warehouseRoutes.receiveNoteCorrectionDetailOverview],
    navigateTo: warehouseRoutes.receiveNoteCorrectionsList,
    title: i18n.t('general.labels.backToReceiveNoteCorrections'),
    rowControlDetail: warehouseRoutes.receiveNoteCorrectionDetailRowControl,
  },
  warehousesDirectSales: {
    nested: [
      warehouseRoutes.directSalesDetailOverview,
      warehouseRoutes.directSalesDetailDocuments,
      warehouseRoutes.directSalesDetailVehicle,
      warehouseRoutes.directSalesDetailCustomer,
    ],
    navigateTo: warehouseRoutes.directSalesList,
    title: i18n.t('general.labels.backToDirectSales'),
    rowControlDetail: warehouseRoutes.directSalesDetailRowControl,
  },
  warehousesDetail: {
    nested: [
      warehouseRoutes.articleDetailOverview,
      warehouseRoutes.articleDetailAvailability,
      warehouseRoutes.articleDetailInventories,
      warehouseRoutes.articleDetailMovements,
      warehouseRoutes.articleDetailReservations,
      warehouseRoutes.articleDetailSupplierArticles,
    ],
    navigateTo: warehouseRoutes.articleList,
    title: `${i18n.t('general.labels.backToWarehouseArticles')}`,
    rowControlDetail: warehouseRoutes.articleDetailRowControl,
  },
  supplierOrders: {
    nested: warehouseRoutes.supplierOrderDetailOverview,
    navigateTo: warehouseRoutes.supplierOrderList,
    title: i18n.t('general.labels.backToSupplierOrders'),
    rowControlDetail: warehouseRoutes.supplierOrderDetailRowControl,
  },
  accounting_invoice: {
    nested: [
      accountingRoutes.invoiceDetail,
      accountingRoutes.invoiceDraft,
      accountingRoutes.invoiceNew,
      accountingRoutes.newCorrectiveTaxDocument,
      accountingRoutes.correctiveTaxDocumentDetail,
      accountingRoutes.balanceInvoiceDetail,
      accountingRoutes.proformaInvoiceDetail,
      accountingRoutes.taxDocumentForPaymentDetail,
    ],
    navigateTo: accountingRoutes.invoiceOverview,
    title: `${i18n.t('general.labels.backToAccountingInvoices')}`,
  },
  accounting_cashReceipt: {
    nested: [
      accountingRoutes.cashReceiptDetail,
      accountingRoutes.createCashReceiptExpense,
      accountingRoutes.createCashReceiptIncome,
    ],
    navigateTo: accountingRoutes.cashReceiptsOverview,
    title: `${i18n.t('general.labels.backToAccountingReceipts')}`,
  },
  employees: {
    nested: [
      employeeRoutes.detail,
      employeeRoutes.organizationalPlacement,
      employeeRoutes.userDataAndRole,
      employeeRoutes.personalInfo,
      employeeRoutes.externalApiKeys,
      employeeRoutes.externalSystems,
      employeeRoutes.historyOfActivities,
    ],
    navigateTo: employeeRoutes.overview,
    title: `${i18n.t('general.labels.backToEmployees')}`,
    rowControlDetail: employeeRoutes.employeeDetailRowControl,
  },
  tireInventory: {
    nested: tiresInventoryRoutes.tireOrderDetail,
    sources: {
      'tire-orders': {
        navigateTo: tiresInventoryRoutes.tireOrders,
        title: `${i18n.t('general.labels.backToTireOrders')}`,
      },
      'tire-list': {
        navigateTo: tiresInventoryRoutes.tireList,
        title: `${i18n.t('general.labels.backToListOfTires')}`,
      },
    },
  },
  serviceCampaign: {
    nested: workshopRoutes.serviceCampaignDetail,
    navigateTo: workshopRoutes.serviceCampaigns,
    title: `${i18n.t('general.labels.backToServiceCampaigns')}`,
  },
};

export const useNavigationBreadCrumb = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const navigationLink = Object.values(NAVIGATION_LINKS).find((breadcrumbProps) => {
    const path = breadcrumbProps?.nested;

    if (isString(path)) {
      return matchPath({path}, location.pathname);
    }

    return path.some((subPath) => matchPath({path: subPath}, location.pathname));
  });

  const breadCrumbProps =
    isNotNil(navigationLink) && isMultiSource(navigationLink)
      ? navigationLink.sources[searchParams.get(queryParams.REDIRECT_SOURCE) ?? '']
      : navigationLink;

  const shouldDisplayBreadCrumb = isNotNilOrEmpty(breadCrumbProps);

  return [breadCrumbProps, shouldDisplayBreadCrumb] as const;
};

const isMultiSource = (obj: NavigationLink): obj is MultiSourceNavigationLink =>
  has('sources', obj);
