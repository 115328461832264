import i18n from '@omnetic-dms/i18n';
import {
  AuditData,
  AuditAsset,
  AuditCategoryOfStructure,
  AuditStructure,
  TRANSLATED_LANGUAGES,
  getNameAccordingToLocale,
  AuditCategoryUniqueKey,
  getParsedValue,
  AuditParamType,
  getFormFieldName,
  getParamFromCategory,
  AuditDamageValueBody,
  findCategoryByUniqueKey,
} from '@omnetic-dms/teas';

import {PhotosCategory, PhotosCategoryItemFile} from '../types/types';

const firstWheelsSetCategoryKeys: string[] = [
  AuditCategoryUniqueKey.LF_WHEEL,
  AuditCategoryUniqueKey.LR_WHEEL,
  AuditCategoryUniqueKey.RR_WHEEL,
  AuditCategoryUniqueKey.RF_WHEEL,
];

const damageCategories: string[] = [
  AuditCategoryUniqueKey.EXTERIOR_DAMAGE,
  AuditCategoryUniqueKey.INTERIOR_DAMAGE,
];

const notPhotosCategories: string[] = [
  AuditCategoryUniqueKey.OVERALL_COMMENT,
  AuditCategoryUniqueKey.TESTDRIVE,
];

const getAssetsByCategory = (categoryId: string, auditData: AuditData): AuditAsset[] | undefined =>
  auditData.assetsCategoryMap?.[categoryId]?.map((key) => auditData.assets?.[key]);

const getFilesFromAsset = (
  asset: AuditAsset,
  files?: PhotosCategoryItemFile[]
): PhotosCategoryItemFile[] => {
  const _files: PhotosCategoryItemFile[] = files ?? [];

  if (!asset) {
    return _files;
  }

  Object.values(asset).forEach((file, fileIndex) => {
    const meta = file.meta ? getParsedValue(file.meta) : null;

    _files.push({
      url: file.url,
      resizeUrl: file.resizeUrl,
      id: `${asset.paramDefinitionId}_${fileIndex}`,
      name: '',
      damageId: meta?.damageId,
    });
  });

  return _files;
};

const getFilesFromCategory = (
  category: AuditCategoryOfStructure,
  auditData: AuditData
): PhotosCategoryItemFile[] => {
  const files: PhotosCategoryItemFile[] = [];
  const assets = getAssetsByCategory(category.id, auditData);

  assets?.forEach((asset) => {
    files.concat(getFilesFromAsset(asset, files));
  });

  return files;
};

export const getPhotosData = (
  locale: TRANSLATED_LANGUAGES,
  auditData?: AuditData,
  auditStructure?: AuditStructure
): PhotosCategory[] => {
  const photosData: PhotosCategory[] = [];

  if (!auditData || !auditStructure) {
    return photosData;
  }

  let categoryIndex: number, childCategoryIndex: number;

  const createMainCategory = (mainCategory: AuditCategoryOfStructure) => {
    categoryIndex = photosData.findIndex((category) => category.id === mainCategory.id);
    if (categoryIndex < 0) {
      categoryIndex =
        photosData.push({
          id: mainCategory.id,
          name: getNameAccordingToLocale(locale, mainCategory.name),
          uniqueKey: mainCategory.uniqueKey,
          childCategories: [],
        }) - 1;
    }
  };

  const createChildCategory = (categoryId: string, name: string) => {
    childCategoryIndex = photosData[categoryIndex].childCategories.findIndex(
      (category) => category.id === categoryId
    );
    if (childCategoryIndex < 0) {
      childCategoryIndex =
        photosData[categoryIndex].childCategories.push({
          id: categoryId,
          name,
          items: [],
        }) - 1;
    }
  };

  auditStructure?.categories.forEach((mainCategory) => {
    if (notPhotosCategories.includes(mainCategory.uniqueKey)) {
      return;
    }

    if (mainCategory.uniqueKey === AuditCategoryUniqueKey.PHOTODOCUMENTATION) {
      mainCategory.childCategories?.forEach((childCategory) => {
        childCategory.paramDefinitions?.relatedActions?.forEach((paramDefinition) => {
          if (paramDefinition.type === AuditParamType.MORE_PHOTOS) {
            const categoryId = `${mainCategory.id}_more_photos`;
            const files = getFilesFromCategory(childCategory, auditData);

            if (!files.length) {
              return;
            }

            createMainCategory(mainCategory);
            createChildCategory(categoryId, i18n.t('entity.photo.labels.additionalPhotos'));
            photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
              files,
            });

            return;
          }

          const assetKey = getFormFieldName(mainCategory.id, paramDefinition.id);
          const asset = auditData.assets?.[assetKey];

          if (!asset) {
            return;
          }

          const categoryId = `${mainCategory.id}_photos`;
          createMainCategory(mainCategory);
          createChildCategory(categoryId, i18n.t('entity.photo.labels.mainPhotos'));

          photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
            files: getFilesFromAsset(asset),
            label: paramDefinition.label
              ? getNameAccordingToLocale(locale, paramDefinition?.label)
              : undefined,
          });
        });
      });

      return;
    }

    if (mainCategory.uniqueKey === AuditCategoryUniqueKey.WHEELS_AND_TIRES) {
      mainCategory.childCategories?.forEach((childCategory) => {
        if (firstWheelsSetCategoryKeys.includes(childCategory.uniqueKey)) {
          const files = getFilesFromCategory(childCategory, auditData);
          if (!files.length) {
            return;
          }

          createMainCategory(mainCategory);
          createChildCategory(
            `${mainCategory.id}_1`,
            i18n.t('entity.condition.labels.theFirstSet')
          );

          photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
            label: getNameAccordingToLocale(locale, childCategory.name),
            files,
          });
        }

        if (childCategory.uniqueKey === AuditCategoryUniqueKey.THE_SECOND_SET) {
          childCategory.childCategories?.forEach((secondSetCategory) => {
            secondSetCategory.childCategories?.forEach((category) => {
              const files = getFilesFromCategory(category, auditData);
              if (!files.length) {
                return;
              }

              createMainCategory(mainCategory);
              createChildCategory(
                `${mainCategory.id}_2`,
                getNameAccordingToLocale(locale, secondSetCategory.name)
              );
              photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
                label: getNameAccordingToLocale(locale, category.name),
                files,
              });
            });
          });
        }
      });

      return;
    }

    if (
      mainCategory.uniqueKey === AuditCategoryUniqueKey.EXTERIOR ||
      mainCategory.uniqueKey === AuditCategoryUniqueKey.INTERIOR
    ) {
      const addDamagePhotos = (
        category: AuditCategoryOfStructure,
        childCategory: AuditCategoryOfStructure
      ) => {
        const files = getFilesFromCategory(category, auditData);
        if (!files.length) {
          return;
        }

        const damageTypeParam = getParamFromCategory(category, AuditParamType.SELECTABLE_BUTTON);

        if (!damageTypeParam) {
          return;
        }
        const damageFieldName = getFormFieldName(category.id, damageTypeParam.id);
        const damageData = auditData.fields?.[damageFieldName];

        if (!Array.isArray(damageData)) {
          return;
        }

        (damageData as AuditDamageValueBody[]).forEach((damage) => {
          const {type: damageTypeValue, damageId} = damage;

          const damageTypeTranslation = damageTypeParam?.values?.find(
            (paramValue) => paramValue.value === damageTypeValue
          )?.label;

          if (!damageTypeTranslation) {
            return;
          }

          const damageType = getNameAccordingToLocale(locale, damageTypeTranslation);
          const categoryId = `${childCategory.id}_${damageTypeValue}`;
          const images = files.filter((file) => file.damageId === damageId);

          createMainCategory(mainCategory);
          createChildCategory(categoryId, damageType);

          photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
            label: i18n.t('general.labels.location'),
            title: getNameAccordingToLocale(locale, category.name),
            files: images,
          });
        });
      };

      mainCategory.childCategories?.forEach((childCategory) => {
        if (!damageCategories.includes(childCategory.uniqueKey)) {
          return;
        }

        childCategory.childCategories?.forEach((category) => {
          addDamagePhotos(category, childCategory);
          category.childCategories?.forEach((category) => {
            addDamagePhotos(category, childCategory);
          });
        });
      });
    }

    const addPhotosFromCategory = (
      category: AuditCategoryOfStructure,
      filesCategory?: AuditCategoryOfStructure
    ) => {
      const files = getFilesFromCategory(filesCategory ?? category, auditData);
      if (!files.length) {
        return;
      }

      createMainCategory(mainCategory);
      createChildCategory(category.id, getNameAccordingToLocale(locale, category.name));

      photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
        files,
      });
    };

    if (
      mainCategory.uniqueKey === AuditCategoryUniqueKey.TECHNICAL_CONDITION ||
      mainCategory.uniqueKey === AuditCategoryUniqueKey.MECHANICS
    ) {
      mainCategory.childCategories?.forEach((childCategory) => {
        if (
          childCategory.uniqueKey ===
          AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION
        ) {
          return;
        }

        childCategory.childCategories?.forEach((category) => {
          if (category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT_INSPECTION) {
            const equipment = category.childCategories?.find(
              (category) => category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT_VALIDATION
            );
            equipment?.childCategories?.forEach((category) => {
              const files = getFilesFromCategory(category, auditData);
              if (!files.length) {
                return;
              }

              createMainCategory(mainCategory);
              createChildCategory(
                childCategory.id,
                getNameAccordingToLocale(locale, childCategory.name)
              );

              photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
                label: getNameAccordingToLocale(locale, category.name),
                files,
              });
            });

            return;
          }

          addPhotosFromCategory(category);
        });
      });
    }

    if (mainCategory.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT) {
      const equipment = findCategoryByUniqueKey(
        mainCategory,
        AuditCategoryUniqueKey.EQUIPMENT_VALIDATION
      );

      equipment?.childCategories?.forEach((equipmentCategory) => {
        const files = getFilesFromCategory(equipmentCategory, auditData);
        if (!files.length) {
          return;
        }

        createMainCategory(mainCategory);
        createChildCategory(
          equipmentCategory.id,
          getNameAccordingToLocale(locale, equipmentCategory.name)
        );

        photosData[categoryIndex].childCategories[childCategoryIndex].items.push({
          files,
        });
      });
    }

    mainCategory.childCategories?.forEach((childCategory) => {
      addPhotosFromCategory(childCategory);
    });
  });

  return photosData;
};
