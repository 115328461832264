import {
  Button,
  Card,
  closeCurrentDialog,
  DataStatus,
  EmptyStatus,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useDeleteEmployeeLinkMutation,
  useGetEmployeeIntegrationQuery,
  useGetEmployeeIntegrationsQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, Section} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {PairingWithPlanner} from './components/PairingWithPlanner';

interface ExternalSystemsProps extends RequiredTestIdProps {}

export function ExternalSystems(props: ExternalSystemsProps) {
  const {id} = useParams();

  const {
    data: employeeIntegration,
    isLoading: isEmployeeIntegrationLoading,
    isError: isEmployeeIntegrationError,
  } = useGetEmployeeIntegrationQuery({employeeId: id ?? ''}, {skip: isNil(id)});
  const {
    data: employeeIntegrations,
    isLoading: isEmployeeIntegrationsLoading,
    isError: isEmployeeIntegrationsError,
  } = useGetEmployeeIntegrationsQuery();
  const [deleteEmployeeLink] = useDeleteEmployeeLinkMutation();

  const handleUnpair = () => {
    if (isNil(id)) {
      return;
    }

    openDeleteDialog({
      onConfirm: () =>
        deleteEmployeeLink({
          employeeId: id,
          contextTarget: 'PLANNER',
        })
          .unwrap()
          .catch(handleApiError),
    });
  };

  const isError = isEmployeeIntegrationError || isEmployeeIntegrationsError;
  const isLoading = isEmployeeIntegrationLoading || isEmployeeIntegrationsLoading;

  const employeePlannerIntegration = employeeIntegration?.find(
    (e) => e.contextTarget === 'PLANNER'
  );

  const integration = employeeIntegrations?.find(
    (integration) => integration.id === employeePlannerIntegration?.contextId
  );

  return (
    <Section>
      <Card
        title={i18n.t('page.employeeDetail.labels.externalSystem')}
        actions={
          integration
            ? [
                {
                  type: 'button',
                  variant: 'link',
                  title: i18n.t('general.actions.change'),
                  leftIcon: 'navigation/refresh',
                  onClick: () =>
                    openDialog(
                      <PairingWithPlanner
                        employeeId={id!}
                        onClose={closeCurrentDialog}
                        data-testid={suffixTestId('pairWithPlanner', props)}
                      />,
                      {title: i18n.t('general.actions.change')}
                    ),
                },
                {
                  type: 'button',
                  variant: 'dangerLink',
                  title: i18n.t('page.employeeDetail.labels.unpair'),
                  leftIcon: 'navigation/close',
                  onClick: handleUnpair,
                },
              ]
            : undefined
        }
      >
        <DataStatus isLoading={isLoading} isError={isError}>
          {integration ? (
            <VStack spacing={4}>
              <Text size="small" color="secondary">
                {i18n.t('page.employeeDetail.labels.pairingWithPlannerDescription')}
              </Text>
              <Card variant="inlineGrey" title={integration.label} />
            </VStack>
          ) : (
            <Box paddingVertical={6}>
              <VStack spacing={4} align="center">
                <EmptyStatus
                  headline={i18n.t('page.employeeDetail.labels.notPairedWithPlanner')}
                  subheadline={i18n.t('page.employeeDetail.labels.notPairedWithPlannerDescription')}
                  data-testid={suffixTestId('notPaired', props)}
                />
                <Button
                  leftIcon="content/link"
                  variant="secondary"
                  title={i18n.t('page.employeeDetail.actions.pairWithPlanner')}
                  isDisabled={isNilOrEmpty(id)}
                  onClick={() =>
                    openDialog(
                      <PairingWithPlanner
                        employeeId={id!}
                        onClose={closeCurrentDialog}
                        data-testid={suffixTestId('pairWithPlanner', props)}
                      />,
                      {title: i18n.t('page.employeeDetail.actions.pairWithPlanner')}
                    )
                  }
                  data-testid={suffixTestId('pairWithPlanner', props)}
                />
              </VStack>
            </Box>
          )}
        </DataStatus>
      </Card>
    </Section>
  );
}
