import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {
  UserDashboardSettingsResponseBody,
  useGetUserDashboardSettingsQuery,
  usePutUserDashboardSettingsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {myProfileRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {useNavigate} from 'shared';

import {MyProfileFooter} from '../../../components/MyProfileFooter';
import {yupDashboardFormSchema} from './yupDashboardFormSchema';

type DashboardSettingsFormType = UserDashboardSettingsResponseBody & {
  hasSalesFunnel: boolean;
};

export function DashboardForm() {
  const navigate = useNavigate();
  const [updateUserDashboardSettings] = usePutUserDashboardSettingsMutation();
  const {
    data: dashboardSettings,
    isLoading: isDashboardSettingsLoading,
    isError: hasDashboardError,
  } = useGetUserDashboardSettingsQuery();

  const handleSubmit: FormSubmitHandler<DashboardSettingsFormType> = async (values, _, reset) => {
    await updateUserDashboardSettings({
      salesFunnelInterests: values.salesFunnelInterests,
      salesFunnelBusinessCases: values.salesFunnelBusinessCases,
      vehicleStock: values.vehicleStock,
      latestVehicles: values.latestVehicles,
      latestBusinessCases: values.latestBusinessCases,
      latestInterests: values.latestInterests,
      notifications: values.notifications,
      myTasks: values.myTasks,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        reset(undefined, {keepValues: true});
      })
      .catch(handleApiError);
  };

  const onChangeHasSalesFunnel = (
    hasSalesFunnel: boolean,
    formApi: UseFormReturn<DashboardSettingsFormType>
  ) => {
    formApi.setValue('salesFunnelInterests', hasSalesFunnel);
    formApi.setValue('salesFunnelBusinessCases', hasSalesFunnel);
  };

  const onChangeHasSalesFunnelChild = (formApi: UseFormReturn<DashboardSettingsFormType>) => {
    const {salesFunnelInterests, salesFunnelBusinessCases} = formApi.getValues();

    if (!salesFunnelInterests && !salesFunnelBusinessCases) {
      formApi.setValue('hasSalesFunnel', false);
    }
  };

  const defaultValues: DashboardSettingsFormType = {
    salesFunnelBusinessCases: dashboardSettings?.salesFunnelBusinessCases ?? false,
    salesFunnelInterests: dashboardSettings?.salesFunnelInterests ?? false,
    hasSalesFunnel:
      (dashboardSettings?.salesFunnelBusinessCases || dashboardSettings?.salesFunnelInterests) ??
      false,
    vehicleStock: dashboardSettings?.vehicleStock ?? false,
    latestVehicles: dashboardSettings?.latestVehicles ?? false,
    latestBusinessCases: dashboardSettings?.latestBusinessCases ?? false,
    latestInterests: dashboardSettings?.latestInterests ?? false,
    notifications: dashboardSettings?.notifications ?? false,
    myTasks: dashboardSettings?.myTasks ?? false,
  };

  return (
    <Box maxWidth={200}>
      <DataStatus isLoading={isDashboardSettingsLoading} isError={hasDashboardError}>
        <Form<DashboardSettingsFormType>
          shouldWatchForUnsavedChanges
          defaultValues={defaultValues}
          schema={yupDashboardFormSchema}
          onSubmit={handleSubmit}
        >
          {(control, formApi) => {
            const hasSalesFunnel = formApi.watch('hasSalesFunnel');

            return (
              <>
                <VStack spacing={6}>
                  <FormField
                    control={control}
                    name="hasSalesFunnel"
                    label={i18n.t('page.myProfile.dashboard.labels.opportunitiesFunnel')}
                    type="switch"
                    onChange={(value) => onChangeHasSalesFunnel(value, formApi)}
                  />

                  <Box paddingLeft={12}>
                    <VStack spacing={6}>
                      <FormField
                        control={control}
                        name="salesFunnelInterests"
                        label={i18n.t('page.myProfile.dashboard.labels.interests')}
                        type="switch"
                        isDisabled={!hasSalesFunnel}
                        onChange={() => onChangeHasSalesFunnelChild(formApi)}
                      />
                      <FormField
                        control={control}
                        name="salesFunnelBusinessCases"
                        label={i18n.t('page.myProfile.dashboard.labels.businessCases')}
                        type="switch"
                        isDisabled={!hasSalesFunnel}
                        onChange={() => onChangeHasSalesFunnelChild(formApi)}
                      />
                    </VStack>
                  </Box>

                  <FormField
                    control={control}
                    name="vehicleStock"
                    label={i18n.t('page.myProfile.dashboard.labels.vehicleStockOverview')}
                    type="switch"
                  />

                  <FormField
                    control={control}
                    name="latestVehicles"
                    label={i18n.t('page.myProfile.dashboard.labels.latestVehicles')}
                    type="switch"
                  />

                  <FormField
                    control={control}
                    name="latestBusinessCases"
                    label={i18n.t('page.myProfile.dashboard.labels.latestBusinessCases')}
                    type="switch"
                  />

                  <FormField
                    control={control}
                    name="latestInterests"
                    label={i18n.t('page.myProfile.dashboard.labels.latestInterests')}
                    type="switch"
                  />

                  <FormField
                    control={control}
                    name="notifications"
                    label={i18n.t('page.myProfile.dashboard.labels.notifications')}
                    type="switch"
                  />

                  <FormField
                    control={control}
                    name="myTasks"
                    label={i18n.t('page.myProfile.dashboard.labels.showMyTasks')}
                    type="switch"
                  />
                </VStack>

                <MyProfileFooter
                  actions={[
                    {
                      type: 'button',
                      onClick: () => navigate(myProfileRoutes.dashboard),
                      title: i18n.t('general.actions.discardChanges'),
                      variant: 'secondary',
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      title: i18n.t('general.actions.saveAndClose'),
                    },
                  ]}
                />
              </>
            );
          }}
        </Form>
      </DataStatus>
    </Box>
  );
}
