import {
  Attributes,
  Button,
  ButtonGroup,
  ButtonProps,
  isFile,
  openDialog,
  Segment,
  Separator,
  showNotification,
  Upload,
} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useState} from 'react';

import {always, isNil, isNotNil, last} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  OrderDiscriminator,
  OrderPaymentResponseBody,
  OrderResponseBody,
  PaymentDiscriminator,
  useCancelOrderMutation,
  useCreatePaymentFileMutation,
  useGetBusinessCaseQuery,
  useGetCheckoutQuery,
  useGetParticipationQuery,
  useUploadFileMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError, usePermissions} from '@omnetic-dms/shared';

import {buildArray, Nullish, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {getPaymentInvoiceInfo} from '../../../utils/getPaymentInvoiceInfo';
import {PaymentForm} from '../../PaymentForm/components/PaymentForm';
import {PaymentPriceBox} from '../../PaymentForm/components/PaymentPriceBox';
import {useGenerateInternalInvoice} from '../hooks/useGenerateInternalInvoice';
import {useGetPaymentDetailAttributes} from '../hooks/useGetPaymentDetailAttributes';
import {PaymentSegmentValue} from '../types/PaymentSegmentValue';
import {isSegmentValue} from '../utils/isSegmentValue';
import {PaymentsTable} from './PaymentsTable';

const segmentOptions = [
  {value: 'detail', label: i18n.t('general.labels.detail')},
  {value: 'payments', label: i18n.t('page.accounting.invoiceDetailPayments.title')},
];

interface DepositPaymentDetailProps extends TestIdProps {
  payment: OrderPaymentResponseBody;
  checkoutId: string;
  order: OrderResponseBody;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
  handleAddAnotherPayment: () => void;
  canAddAnotherPayment: boolean;
  isAddPaymentPending: boolean;
}

export function PaymentDetail(props: DepositPaymentDetailProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const {data: checkout} = useGetCheckoutQuery({checkoutId: props.checkoutId});
  const offer = businessCase?.offers[0];
  const [cancelOrder, {isLoading: isCancelingOrder}] = useCancelOrderMutation();

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: businessCaseId,
      resourceId: EntityResourceIds.businessCase,
    },
    {skip: isNil(businessCaseId)}
  );

  const [hasConfirmBusinessCaseSellingPaymentManuallyPermission] = usePermissions({
    permissionKeys: ['payBalance'],
    scopes: {
      payBalance: {
        paymentMethod: props.payment.paymentMethod,
        participation: businessCaseParticipation,
      },
    },
  });

  const handleCancelPayment = () =>
    cancelOrder({
      checkoutId: props.checkoutId,
      orderId: props.order.id,
      paymentId: props.payment.id,
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(() => props.refreshBusinessCaseCheckoutInfo(null))
      .catch(handleApiError);

  const hasSellingPrice = isNotNilOrEmpty(offer?.saleVehicles);
  const hasPurchasePrice = isNotNilOrEmpty(offer?.purchaseBrokerageVehicles);

  const canConfirmPaymentManually = hasSellingPrice
    ? hasConfirmBusinessCaseSellingPaymentManuallyPermission
    : true;

  const isPaymentPending = props.payment.paymentState === 'PENDING';
  const [segmentValue, setSegmentValue] = useState<PaymentSegmentValue>('detail');

  const {generateInternalInvoiceDocument, generateInternalInvoiceDocumentDeductible, isLoading} =
    useGenerateInternalInvoice(props.order, props.payment.id, props.checkoutId);

  const [detailRows] = useGetPaymentDetailAttributes(props);

  const handleUpdateSegment = (val: string) => {
    if (!isSegmentValue(val)) {
      return;
    }

    setSegmentValue(val);
  };

  const handlePayment = () => {
    const {id, type} = getPaymentInvoiceInfo(props.payment);

    const isPaymentPartial = props.payment.paymentDiscriminator !== 'DEPOSIT' && isNotNil(id);

    openDialog(
      <PaymentForm
        invoiceId={id}
        documentType={type}
        isPartialPaymentEnabled={isPaymentPartial}
        checkoutId={props.checkoutId}
        orderId={props.order.id}
        payment={props.payment}
        businessCaseId={businessCase?.id}
        data-testid={suffixTestId('paymentForm', props)}
      />,
      {
        title: i18n.t('entity.invoice.labels.paymentOfInvoice', {
          number: last(props.payment.invoices)?.name,
        }),
        scrollBehavior: 'outside',
      }
    );
  };

  const isDeductibleVehicle =
    (props.order.orderDiscriminator === 'SALE' &&
      props.order.items.some((item) => item.deductible)) ||
    checkout?.orders.some(
      (order) => order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE' && order.items[0].deductible
    );

  const isBrokerageInternalDocument =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE' ||
    !!businessCase?.brokerageBusinessCaseId;
  const isInternalDocumentGenerationPlausible = props.payment.paymentState !== 'CONCEPT';

  const isPaymentInCorrectStateToGenerateInternalInvoice = match<
    [PaymentDiscriminator, OrderDiscriminator, boolean],
    boolean
  >([
    props.payment.paymentDiscriminator,
    props.order.orderDiscriminator,
    isBrokerageInternalDocument,
  ])
    .with(
      ['BALANCE', 'PURCHASE_BROKERAGE_FEES', Pattern.boolean],
      ['PURCHASE', 'PURCHASE_BROKERAGE_FEES', false],
      ['BALANCE', 'SALE', false],
      ['PURCHASE', 'SALE', false],
      always(true)
    )
    .otherwise(always(false));

  const canGenerateInternalInvoiceForBrokerage =
    isInternalDocumentGenerationPlausible &&
    hasPurchasePrice &&
    isPaymentInCorrectStateToGenerateInternalInvoice;

  const canGenerateInternalInvoice =
    isInternalDocumentGenerationPlausible &&
    hasSellingPrice &&
    isPaymentInCorrectStateToGenerateInternalInvoice;

  const [uploadFile] = useUploadFileMutation();
  const [createPaymentFile] = useCreatePaymentFileMutation();

  const canEditBusinessCase = match(businessCase?.businessCaseState)
    .with('CLOSED', 'UNSUCCESSFUL', always(false))
    .otherwise(always(true));

  return (
    <VStack spacing={4}>
      <PaymentPriceBox payment={props.payment} />
      <Separator spacing={0} />
      <Box width={73}>
        <Segment value={segmentValue} options={segmentOptions} onChange={handleUpdateSegment} />
      </Box>

      <Show when={segmentValue === 'detail'}>
        <Attributes
          size="quarter"
          rows={detailRows}
          data-testid={suffixTestId('paymentDetailAttributes', props)}
        />
      </Show>

      <Show when={segmentValue === 'payments'}>
        <PaymentsTable
          payment={props.payment}
          orderId={props.order.id}
          checkoutId={props.checkoutId}
          orderDiscriminator={props.order.orderDiscriminator}
        />
      </Show>

      <HStack spacing={2} justify="flex-end">
        <Show when={props.canAddAnotherPayment}>
          <Box flexGrow={1}>
            <Button
              variant="link"
              leftIcon="content/add_circle"
              onClick={props.handleAddAnotherPayment}
              isLoading={props.isAddPaymentPending}
              title={i18n.t('entity.checkout.addAnotherPayment')}
              data-testid={testIds.businessCase.checkout('addAnotherPayment')}
            />
          </Box>
        </Show>

        <Show when={props.payment.paymentDiscriminator === 'PURCHASE' && canEditBusinessCase}>
          <Show when={props.payment.paymentState !== 'PAID'}>
            <Button
              variant="dangerOutlined"
              title={i18n.t('entity.checkout.actions.cancelPayment')}
              data-testid={suffixTestId('cancelPayment', props)}
              leftIcon="content/remove_circle"
              iconColor="severity.danger"
              isLoading={isCancelingOrder}
              onClick={handleCancelPayment}
            />
          </Show>

          <Upload
            type="button"
            errorIcon="navigation/cancel"
            uploadIcon="file/upload"
            uploadText={i18n.t('entity.document.actions.uploadDocument')}
            buttonVariant="secondary"
            data-testid={suffixTestId('uploadFiles', props)}
            customRequest={async ({file, onSuccess, onError}) => {
              try {
                if (!isFile(file)) {
                  return;
                }

                const data = await uploadFile({file}).unwrap();

                await createPaymentFile({
                  paymentId: props.payment.id,
                  checkoutId: props.checkoutId,
                  orderId: props.order.id,
                  fileData: {
                    fileId: data.fileId,
                    remoteId: data.fileId,
                    fileFileId: data.fileId,
                    name: data.file.name,
                    fileUri: data.fileUri,
                    documentType: 'PAYMENT_ATTACHMENT',
                    createdAt: null,
                    createdBy: null,
                  },
                });

                onSuccess?.(data);
              } catch (error: any) {
                handleApiError(error?.response);
                onError?.(error);
              }
            }}
          />
        </Show>
        <ButtonGroup
          align="right"
          buttons={buildArray<ButtonProps>()
            .when(canGenerateInternalInvoice || canGenerateInternalInvoiceForBrokerage, {
              onClick: isDeductibleVehicle
                ? generateInternalInvoiceDocumentDeductible
                : generateInternalInvoiceDocument,
              isLoading,
              variant: 'outlined',
              title: i18n.t('entity.checkout.actions.generateInternalInvoice'),
              'data-testid': suffixTestId('generateInternalInvoice', props),
            })
            .when(isPaymentPending, {
              title: i18n.t('entity.order.actions.pay'),
              onClick: handlePayment,
              isDisabled: !canConfirmPaymentManually,
              'data-testid': suffixTestId('pay', props),
            })}
        />
      </HStack>
    </VStack>
  );
}
