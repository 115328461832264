import {isFeatureEnabled} from 'feature-flags';
import {Action, Attributes, Card} from 'platform/components';
import {Heading, Hide, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {
  EntityResourceIds,
  GetVehicleApiResponseV2,
  useGetParticipationQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {EntityCustomFields, usePermissions} from '@omnetic-dms/shared';

import {composePath} from 'shared';

import {useVehicleDetailsParameters} from '../hooks/useVehicleDetailsParameters';

interface CarDetailsDetailsProps {
  vehicle: GetVehicleApiResponseV2;
  isSaleVehicle: boolean;
}

export function VehicleDetail(props: CarDetailsDetailsProps) {
  const navigate = useNavigate();

  const [
    modelSpecifications,
    engineSpecifications,
    consumptionAndEmissionSpecifications,
    metricsSpecifications,
  ] = useVehicleDetailsParameters(props.vehicle);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    recordId: props.vehicle.id,
    resourceId: EntityResourceIds.vehicle,
  });

  const [canUpdateVehicle, canSetCustomVehicleId, canEditCustomFields] = usePermissions({
    permissionKeys: ['updateVehicle', 'setCustomIdVehicle', 'vehicleCustomFields'],
    scopes: {
      updateVehicle: {participation: vehicleParticipation},
      setCustomIdVehicle: {participation: vehicleParticipation},
    },
  });
  const canEditVehicle = canUpdateVehicle && canSetCustomVehicleId;

  const navigateToEditVehicle = () => {
    if (isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return navigate(
        composePath(props.isSaleVehicle ? vehiclesRoutes.edit : vehiclesRoutes.editServiceVehicle, {
          params: {id: props.vehicle.id},
          queryParams: {tab: 'basic-information'},
        })
      );
    }

    return navigate(composePath(vehiclesRoutes.edit, {params: {id: props.vehicle.id}}));
  };

  const isTrailerOrSemiTrailer =
    props.vehicle.type === 'VEHICLETYPE_TRAILER' ||
    props.vehicle.type === 'VEHICLETYPE_SEMI_TRAILER';

  const editAction: Action = {
    variant: 'link',
    type: 'button',
    leftIcon: 'image/edit',
    onClick: navigateToEditVehicle,
    title: i18n.t('general.actions.edit'),
  };

  return (
    <VStack spacing={2}>
      <Card
        title={i18n.t('entity.vehicle.labels.modelSpecification')}
        actions={
          canEditVehicle
            ? [
                {
                  ...editAction,
                  'data-testid': testIds.vehicles.detail('vehicle-edit-model-specifications'),
                },
              ]
            : undefined
        }
        data-testid={testIds.vehicles.detail('generalInformation')}
      >
        <Attributes
          rows={modelSpecifications}
          data-testid={testIds.vehicles.detail('vehicle-model-specifications')}
        />
      </Card>

      <Card
        title={i18n.t('entity.vehicle.labels.additionalInformation')}
        actions={
          canEditVehicle
            ? [
                {
                  ...editAction,
                  'data-testid': testIds.vehicles.detail('vehicle-edit-additional-info'),
                },
              ]
            : undefined
        }
        data-testid={testIds.vehicles.detail('additionalInformation')}
      >
        <VStack spacing={1}>
          <Hide when={isTrailerOrSemiTrailer}>
            <Heading size={4}>{i18n.t('entity.vehicle.labels.engine')}</Heading>
            <Attributes
              rows={engineSpecifications}
              data-testid={testIds.vehicles.detail('vehicle-model-engine')}
            />
            <Heading size={4}>
              {i18n.t('entity.vehicle.labels.consumptionAndEmissionSectionTitle')}
            </Heading>
            <Attributes
              rows={consumptionAndEmissionSpecifications}
              data-testid={testIds.vehicles.detail(
                'vehicle-model-consumptionAndEmissionSectionTitle'
              )}
            />
          </Hide>

          <Heading size={4}>{i18n.t('entity.vehicle.labels.dimensions')}</Heading>
          <Attributes
            rows={metricsSpecifications}
            data-testid={testIds.vehicles.detail('vehicle-model-dimensions')}
          />
        </VStack>
      </Card>

      <EntityCustomFields
        recordId={props.vehicle.id}
        resourceId={EntityResourceIds.vehicle}
        isEditable={canEditCustomFields}
        data-testid={testIds.vehicles.detail('vehicle-custom-fields')}
      />
    </VStack>
  );
}
