import {isFeatureEnabled} from 'feature-flags';

/* eslint-disable filename-export/match-named-export */
import {DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {useParams} from 'react-router-dom';

import {isNil, not} from 'ramda';
import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  BusinessCaseResponseBody,
  EntityResourceIds,
  VehicleTypeEnumObject,
  businessCaseApi,
  saleVehicleApi,
  useCreateOfferMutation,
  useCreateSaleVehicleMutation,
  useGetBusinessCaseQuery,
  useGetCustomerV2Query,
  useGetParticipationQuery,
  useGetSaleVehicleComplaintsQuery,
  useLazyGetVehicleV2Query,
  useSwitchOfferSaleVehicleMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {
  ActivityLogs,
  ErrorPage,
  NavigationItem,
  Page,
  VehicleReservations,
  getCustomerName,
  handleApiError,
  useDocumentContextCount,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
  usePermissions,
} from '@omnetic-dms/shared';
import {getVehicleMakeModels, useApiDispatch, useRouter, useThunkDispatch} from '@omnetic-dms/teas';

import {buildArray, composePath, useNavigate} from 'shared';

import {BusinessCaseDocuments} from '../../BusinessCaseDocuments';
import {BusinessCaseTabItem} from '../../BusinessCaseTabItem';
import {BusinessCaseCheckout} from '../../components/BusinessCaseCheckout/BusinessCaseCheckout';
import {BusinessCaseHeader} from '../../components/BusinessCaseHeader/BusinessCaseHeader';
import {BusinessCaseOffersCreateVehicle} from '../../components/BusinessCaseOffers/BusinessCaseOffersCreateVehicle';
import {BusinessCaseOffersSelectVehicleDialog} from '../../components/BusinessCaseOffers/BusinessCaseOffersSelectVehicleDialog';
import {BusinessCasePurchaseVehicles} from '../../components/BusinessCaseOffers/BusinessCasePurchaseVehicles/BusinessCasePurchaseVehicles';
import {BusinessCaseSaleVehicles} from '../../components/BusinessCaseOffers/BusinessCaseSaleVehicles/BusinessCaseSaleVehicles';
import {BusinessCaseOverview} from '../../components/BusinessCaseOverview/BusinessCaseOverview';
import {BusinessCaseTestDrive} from '../../components/BusinessCaseTestDrive/BusinessCaseTestDrive';
import {SelectVehicleContextProvider} from '../../components/SelectVehicleContextProvider';
import {useSelectVehicleContext} from '../../hooks/useSelectVehicleContext';
import {BusinessCaseComplaintDetail} from '../BusinessCaseComplaintDetail/BusinessCaseComplaintDetail';
import {BusinessCaseInsurance} from '../BusinessCaseInsurance/BusinessCaseInsurance';
import {BusinessCaseOverview as BusinessCaseOverviewV2} from '../BusinessCaseOverview/BusinessCaseOverview';
import {BusinessCaseCustomerDetail} from './(sections)/BusinessCaseCustomer/BusinessCaseCustomerDetail';

type BusinessCaseDetailProps = {
  businessCase: BusinessCaseResponseBody;
};

const BusinessCaseDetail = (props: BusinessCaseDetailProps) => {
  const router = useRouter();
  const dispatch = useThunkDispatch();
  const apiDispatch = useApiDispatch();

  const [createSaleVehicle] = useCreateSaleVehicleMutation();
  const [switchToSaleVehicle] = useSwitchOfferSaleVehicleMutation();
  const [createOffer] = useCreateOfferMutation();
  const [getVehicle] = useLazyGetVehicleV2Query();
  const [businessCaseId] = router.params.dynamic;

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: props.businessCase?.id ?? ''},
    {skip: isNilOrEmpty(props.businessCase?.id)}
  );
  const {data: customer} = useGetCustomerV2Query(
    {customerId: businessCase?.customerId ?? ''},
    {skip: isNilOrEmpty(businessCase?.customerId)}
  );
  const allowedActions = businessCase?.actions;

  const {
    setCreateVehicleModalOpen,
    setSelectSaleVehicleOpen,
    isSelectSaleVehicleOpen,
    isVehicleModalOpen,
  } = useSelectVehicleContext();

  const toggleSelectVehicle = () => {
    setSelectSaleVehicleOpen(!isSelectSaleVehicleOpen);
  };

  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();

  const onAddVehicleToBuyClose = () => {
    setCreateVehicleModalOpen(false, 'IN_STOCK');
  };

  const offer = businessCase?.offers?.[0];
  const saleVehicle = offer?.saleVehicles?.[0];

  const {data: complaints} = useGetSaleVehicleComplaintsQuery(
    {saleVehicleId: businessCase?.saleVehicleId ?? ''},
    {skip: isNil(businessCase?.saleVehicleId)}
  );

  const documentsCountBadge = useDocumentContextCount('business-case', businessCase?.id ?? '');

  const navigate = useNavigate();

  const addVehicleToSell = async (vehicleId: string): Promise<void> => {
    if (!businessCaseId) {
      return;
    }

    const offerId = offer?.id;

    // Offer already exists
    if (offerId) {
      await createSaleVehicle({
        businessCaseId,
        offerId,
        offerSaleVehicleRequestBody: {vehicleId},
      })
        .unwrap()
        .catch(handleApiError);
    } else {
      await createOffer({
        businessCaseId,
        offerRequestBody: {vehicleToSaleId: vehicleId},
      });
    }

    const {data: vehicleDetail} = await getVehicle({vehicleId});

    if (vehicleDetail) {
      await apiDispatch(getVehicleMakeModels.action, {
        vehicleType: vehicleDetail.type || VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR,
        make: vehicleDetail?.make ?? undefined,
      });
    }

    dispatch(
      businessCaseApi.util.invalidateTags([
        {type: 'BusinessCaseDetail', id: businessCaseId},
        {type: 'BusinessCaseActions', id: businessCaseId},
      ])
    );

    await dispatch(saleVehicleApi.endpoints.getSaleVehicle.initiate({vehicleId}));

    navigate(composePath(businessCaseRoutes.selling, {params: {id: businessCaseId}}));
  };

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: saleVehicle?.vehicleId ?? '',
    },
    {skip: isNil(saleVehicle?.vehicleId)}
  );

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: businessCase?.id ?? '',
    },
    {skip: isNilOrEmpty(businessCase?.id)}
  );

  const [
    hasComplaintReadPermission,
    hasVehicleReadActiveReservationPermission,
    hasVehicleReadReservationHistoryPermission,
    canReadDocuments,
  ] = usePermissions({
    permissionKeys: [
      'complaintRead',
      'vehicleReadActiveReservation',
      'vehicleReadReservationHistory',
      'readDocuments',
    ],
    scopes: {
      complaintRead: {participation: businessCaseParticipation},
      vehicleReadActiveReservation: {participation: vehicleParticipation},
      vehicleReadReservationHistory: {participation: vehicleParticipation},
    },
  });

  const navigationItems: NavigationItem[] = buildArray<NavigationItem>([
    {
      id: BusinessCaseTabItem.OVERVIEW,
      label: i18n.t('page.businessCase.labels.overview'),
      content: isFeatureEnabled(featureFlags.BUSINESS_CASE_OVERVIEW) ? (
        <BusinessCaseOverviewV2 />
      ) : (
        <BusinessCaseOverview />
      ),
      href: composePath(businessCaseRoutes.overview, {params: {id: businessCaseId}}),
      hasSeparator: true,
      'data-testid': testIds.businessCase.detail('overview'),
    },
    {
      id: BusinessCaseTabItem.CUSTOMER,
      label: i18n.t('entity.customer.labels.customer'),
      content: <BusinessCaseCustomerDetail />,
      href: composePath(businessCaseRoutes.customer, {params: {id: businessCaseId}}),
      hasCounter: true,
      'data-testid': testIds.businessCase.detail('customer'),
    },
    {
      id: BusinessCaseTabItem.BUYING,
      label: i18n.t('entity.businessCase.labels.buyingOrCommission'),
      content: <BusinessCasePurchaseVehicles />,
      href: composePath(businessCaseRoutes.buying, {params: {id: businessCaseId}}),
      isDisabled:
        not(allowedActions?.purchaseViewVehicleDetail) ||
        businessCase?.businessCaseType === 'SELLING',
      hasCounter: true,
      'data-testid': testIds.businessCase.detail('buying'),
    },
    {
      id: BusinessCaseTabItem.SELLING,
      label: i18n.t('page.businessCase.labels.selling'),
      content: <BusinessCaseSaleVehicles />,
      href: composePath(businessCaseRoutes.selling, {params: {id: businessCaseId}}),
      isDisabled:
        not(allowedActions?.saleViewVehicleDetail) || businessCase?.businessCaseType === 'BUYING',
      hasCounter: true,
      'data-testid': testIds.businessCase.detail('selling'),
    },
  ])
    .add({
      id: BusinessCaseTabItem.TEST_DRIVE,
      label: i18n.t('entity.businessCase.labels.testDrive'),
      content: <BusinessCaseTestDrive />,
      href: composePath(businessCaseRoutes.testDrive, {params: {id: businessCaseId}}),
      isDisabled: isNilOrEmpty(saleVehicle),
      hasCounter: true,
      'data-testid': testIds.businessCase.detail('testDrive'),
    })
    .when(hasVehicleReadActiveReservationPermission && hasVehicleReadReservationHistoryPermission, {
      id: BusinessCaseTabItem.RESERVATIONS,
      label: i18n.t('entity.vehicle.labels.reservations'),
      content: (
        <VehicleReservations
          vehicleId={saleVehicle?.vehicleId ?? ''}
          customerId={customer?.id}
          businessCaseId={businessCaseId}
        />
      ),
      href: composePath(businessCaseRoutes.reservations, {params: {id: businessCaseId}}),
      isDisabled: not(saleVehicle?.vehicleId),
      hasCounter: true,
      'data-testid': testIds.businessCase.detail('reservations'),
    })
    .whenFeatureEnabled(featureFlags.SALES_INSURANCE_COMPARISON_V1, {
      id: BusinessCaseTabItem.INSURANCE,
      label: i18n.t('entity.insurance.labels.title'),
      content: <BusinessCaseInsurance />,
      href: composePath(businessCaseRoutes.insurance, {params: {id: businessCaseId}}),
      isDisabled:
        not(saleVehicle?.vehicleId) ||
        (businessCase?.businessCaseType !== 'SELLING' && businessCase?.businessCaseType !== 'SWAP'),
      hasCounter: true,
      'data-testid': testIds.businessCase.detail('insurance'),
    })
    .add({
      id: BusinessCaseTabItem.CHECKOUT,
      label: i18n.t('entity.businessCase.labels.checkout'),
      content: <BusinessCaseCheckout />,
      href: composePath(businessCaseRoutes.checkout, {params: {id: businessCaseId}}),
      isDisabled: not(allowedActions?.checkoutViewDetail),
      hasCounter: true,
      hasSeparator: true,
      'data-testid': testIds.businessCase.detail('checkout'),
    })
    .when(
      hasComplaintReadPermission &&
        isFeatureEnabled(featureFlags.SALES_CLAIMS) &&
        businessCase?.businessCaseType === 'SELLING',
      {
        id: 'businessCaseComplaints',
        label: i18n.t('entity.vehicle.labels.complaints'),
        content: <BusinessCaseComplaintDetail />,
        href: composePath(businessCaseRoutes.complaints, {params: {id: businessCaseId}}),
        badge: isPositive(complaints?.length)
          ? {
              value: complaints?.length ?? 0,
              colorScheme: 'neutral',
            }
          : undefined,
        'data-testid': testIds.businessCase.detail('complaints'),
      }
    )
    .when(canReadDocuments, {
      id: BusinessCaseTabItem.DOCUMENTS,
      label: i18n.t('page.businessCase.documents.title'),
      content: <BusinessCaseDocuments />,
      href: composePath(businessCaseRoutes.documents, {params: {id: businessCaseId}}),
      badge: documentsCountBadge,
      'data-testid': testIds.businessCase.detail('documents'),
    })
    .whenFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS, {
      id: 'activityLogs',
      href: composePath(businessCaseRoutes.activityLog, {params: {id: businessCaseId}}),
      label: i18n.t('general.labels.activityLogs'),
      content: <ActivityLogs code="business-case-audit-log" />,
      'data-testid': testIds.vehicles.detail('navigation-item-activityLogs'),
    });

  const closeCustomerWidget = () => {
    setSelectSaleVehicleOpen(false);
  };

  const onSelectSaleVehicle = async (id: string) => {
    if (offer?.saleVehicles?.[0]) {
      await switchToSaleVehicle({
        businessCaseId,
        offerId: offer.id,
        vehicleId: id,
      })
        .unwrap()
        .catch(handleApiError);

      const {data: vehicleDetail} = await getVehicle({vehicleId: id});

      if (vehicleDetail) {
        await apiDispatch(getVehicleMakeModels.action, {
          vehicleType: vehicleDetail.type || VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR,
          make: vehicleDetail?.make ?? undefined,
        });
      }

      await dispatch(saleVehicleApi.endpoints.getSaleVehicle.initiate({vehicleId: id}));
      dispatch(
        businessCaseApi.util.invalidateTags([{type: 'BusinessCaseDetail', id: businessCaseId}])
      );
    } else {
      await addVehicleToSell(id);
      const vehicleData = await getVehicle({vehicleId: id});
      handleSearchAuthorizationProfiles(
        {
          vehicleMake: vehicleData?.data?.make ?? '',
          vehicleType: vehicleData?.data?.type ?? null,
          vehicleModelFamily: vehicleData?.data?.modelFamily ?? null,
          vehicleModelFamilyGroup: vehicleData?.data?.modelFamilyGroup ?? null,
        },
        businessCase?.customerId,
        {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
      );
    }
    toggleSelectVehicle();
  };

  return (
    <>
      <Helmet title={i18n.t('page.businessCase.title', {name: `${getCustomerName(customer)}`})} />
      <Page
        navigation={navigationItems}
        onNavigate={closeCustomerWidget}
        data-testid={testIds.businessCase.home('content')}
        header={<BusinessCaseHeader />}
      />

      <Show when={isSelectSaleVehicleOpen}>
        <BusinessCaseOffersSelectVehicleDialog
          isOpen={isSelectSaleVehicleOpen}
          onSubmit={onSelectSaleVehicle}
          onClose={toggleSelectVehicle}
        />
      </Show>

      <Show when={isVehicleModalOpen}>
        <BusinessCaseOffersCreateVehicle onClose={onAddVehicleToBuyClose} />
      </Show>
    </>
  );
};

export const BusinessCaseDetailWrapper = () => {
  const {id: businessCaseId} = useParams();
  const {data: businessCase, isError} = useGetBusinessCaseQuery(
    {businessCaseId: businessCaseId ?? ''},
    {skip: isNilOrEmpty(businessCaseId)}
  );

  if (isNilOrEmpty(businessCaseId)) {
    return <ErrorPage message={i18n.t('page.businessCase.notifications.notFound')} />;
  }

  return (
    <DataStatus isError={isError}>
      <SelectVehicleContextProvider>
        {businessCase && <BusinessCaseDetail key={businessCaseId} businessCase={businessCase} />}
      </SelectVehicleContextProvider>
    </DataStatus>
  );
};
