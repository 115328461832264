import {
  Button,
  ButtonGroup,
  Chips,
  ChipsOption,
  DataStatus,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack, Space} from 'platform/foundation';

import {useState} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AudatexCalculationSettings,
  useGetMetadaServiceCaseQuery,
  useGetVehicleV2Query,
  usePostServiceOrderAudatexTaskCalculationsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, buildArray, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, RowData} from 'features/datagrid';

import {ImportSettings} from './ImportSettings';

const DEFAULT_SETTINGS = [
  {
    value: true,
    key: 'material' as const,
    label: i18n.t('entity.calculationType.labels.material'),
    additionalInfo: i18n.t('entity.calculationType.labels.materialInfo'),
  },
  {
    value: true,
    key: 'labour' as const,
    label: i18n.t('entity.calculationType.labels.labour'),
    additionalInfo: i18n.t('entity.calculationType.labels.labourInfo'),
  },
  {
    value: true,
    key: 'paintWork' as const,
    label: i18n.t('entity.calculationType.labels.paintWork'),
  },
  {
    value: true,
    key: 'paintPreparationWork' as const,
    label: i18n.t('entity.calculationType.labels.paintPreparationWork'),
    additionalInfo: i18n.t('entity.calculationType.labels.paintPreparationWorkInfo'),
  },
  {
    value: true,
    key: 'paintMaterial' as const,
    label: i18n.t('entity.calculationType.labels.paintMaterial'),
  },
  {
    value: true,
    key: 'paintConstantMaterial' as const,
    label: i18n.t('entity.calculationType.labels.paintConstantMaterial'),
  },
  {
    value: true,
    key: 'pcofPart' as const,
    label: i18n.t('entity.calculationType.labels.pcofPart'),
    additionalInfo: i18n.t('entity.calculationType.labels.pcofPartInfo'),
  },
];

interface AudatexCalculationsProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  onClose: () => void;
}

export function AudatexCalculations(props: AudatexCalculationsProps) {
  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId: props.serviceCaseId});
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleV2Query(
    {vehicleId: serviceCase?.vehicleId ?? ''},
    {skip: isNil(serviceCase?.vehicleId)}
  );

  const [postServiceOrderAudatexTaskCalculations, {isLoading}] =
    usePostServiceOrderAudatexTaskCalculationsMutation();

  const [selectedCalculation, setCalculation] = useState<
    {taskId: string; caseId: string} | Nullish
  >();
  const [settings, setSetings] = useState(DEFAULT_SETTINGS);
  const [selectedCalculationType, setCalculationType] = useState('SERVICE_ORDER');

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([
      filter,
      {
        serviceOrderId: props.serviceOrderId,
        vehicleVin: selectedCalculationType === 'VEHICLE' ? vehicle?.vin : undefined,
        calculationType: selectedCalculationType,
      },
    ]);

  const handleChipsChange = (value: string[] | Nullish) => {
    if (isNil(value)) {
      return;
    }

    setCalculationType(value[0]);
  };

  const handleConfirm = () => {
    if (isNil(selectedCalculation)) {
      return;
    }

    const settingsObject = settings.reduce((acc, setting) => {
      acc[setting.key] = setting.value;
      return acc;
    }, {} as AudatexCalculationSettings);

    postServiceOrderAudatexTaskCalculations({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {...selectedCalculation, settings: settingsObject},
    })
      .unwrap()
      .then(props.onClose)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleRowSelection = (rows: RowData[]) => {
    const castedRows = rows as {caseId?: {value?: string}; taskId?: {value?: string}}[];
    const taskId = head(castedRows)?.taskId?.value;
    const caseId = head(castedRows)?.caseId?.value;

    if (isNil(taskId) || isNil(caseId)) {
      return;
    }

    setCalculation({
      taskId,
      caseId,
    });
  };

  const chipOptions = buildArray<ChipsOption>([
    {label: i18n.t('entity.calculationType.labels.serviceOrder'), value: 'SERVICE_ORDER'},
  ])
    .when(isNotNilOrEmpty(vehicle?.vin), {
      label: i18n.t('entity.calculationType.labels.vehicle'),
      value: 'VEHICLE',
    })
    .add({
      label: i18n.t('entity.calculationType.labels.allCalculations'),
      value: 'ALL_CALCULATIONS',
    });

  return (
    <DataStatus
      isLoading={isServiceCaseLoading || isVehicleLoading}
      isError={isServiceCaseError || isVehicleError}
    >
      <HStack spacing={4}>
        <Box width={90}>
          <ImportSettings
            settings={settings}
            setSetings={setSetings}
            data-testid={suffixTestId('importSettings', props)}
          />
        </Box>
        <Box flex={1}>
          <Chips
            value={[selectedCalculationType]}
            onChange={handleChipsChange}
            options={chipOptions}
            data-testid={suffixTestId('calculationType', props)}
          />
          <Separator />
          <Box height={170}>
            <DataGrid
              // need to change queryModifier, so cannot use `useRefreshDataGrid`
              // eslint-disable-next-line no-restricted-syntax
              key={selectedCalculationType}
              gridCode="service-order-audatex-calculation"
              onRowSelectionChange={handleRowSelection}
              queryModifier={queryModifier}
              data-testid={suffixTestId('datagrid', props)}
            />
          </Box>
          <Space vertical={4} />
        </Box>
      </HStack>
      <ButtonGroup align="right">
        <Button
          title={i18n.t('general.actions.discard')}
          variant="secondary"
          onClick={props.onClose}
          data-testid={suffixTestId('discard', props)}
        />
        <Button
          title={i18n.t('general.actions.confirm')}
          variant="primary"
          onClick={handleConfirm}
          isLoading={isLoading}
          isDisabled={isNilOrEmpty(selectedCalculation)}
          data-testid={suffixTestId('confirm', props)}
        />
      </ButtonGroup>
    </DataStatus>
  );
}
