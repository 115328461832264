import {isFeatureEnabled} from 'feature-flags';
import {Card} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {indexBy, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  selectSaleVehicle,
  useGetParticipationQuery,
  useGetVehicleV2Query,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {usePermissions} from '@omnetic-dms/shared';
import {
  FeatureMarketAnalysisCard,
  selectFeatureCategories,
  selectInteriorMaterials,
  selectVehicleFeatures,
  useRouter,
  VehicleCatalogueEnumOption,
} from '@omnetic-dms/teas';

import {composePath, parseDate} from 'shared';

import {FeaturesDetail} from './FeaturesDetail';

export function VehicleFeatures() {
  const router = useRouter();
  const {id} = router.params;

  const navigate = useNavigate();

  const {data: saleVehicle} = useSelector(selectSaleVehicle(id as string));
  const {data: vehicleDetail} = useGetVehicleV2Query({vehicleId: id as string}, {skip: isNil(id)});

  const {data: vehicleParticipation} = useGetParticipationQuery({
    recordId: id as string,
    resourceId: EntityResourceIds.vehicle,
  });

  const [canUpdateVehicle, canSetCustomVehicleId, canReadBusinessCase] = usePermissions({
    permissionKeys: ['updateVehicle', 'setCustomIdVehicle', 'businessCaseRead'],
    scopes: {
      updateVehicle: {participation: vehicleParticipation},
      setCustomIdVehicle: {participation: vehicleParticipation},
    },
  });
  const canEditVehicle = canUpdateVehicle && canSetCustomVehicleId;

  const categories = useSelector(selectFeatureCategories(vehicleDetail?.type));
  const features = useSelector(selectVehicleFeatures(vehicleDetail?.type));
  const interiorMaterials = useSelector(selectInteriorMaterials(vehicleDetail?.type));
  const flattenedFeatures = useMemo(() => flattenFeatures(features), [features]);
  const flattenedFeaturesById = indexBy((item) => item.value, flattenedFeatures);

  const vehicleYear: number | null =
    (isNotNil(vehicleDetail?.firstRegistrationOn)
      ? parseDate(vehicleDetail?.firstRegistrationOn).getFullYear()
      : null) ??
    (isNotNil(vehicleDetail?.manufacturedOn)
      ? parseDate(vehicleDetail?.manufacturedOn).getFullYear()
      : null);

  const vehicleFeatures = useMemo(
    () =>
      vehicleDetail?.features?.map((vehicleFeature) => ({
        ...flattenedFeaturesById[vehicleFeature.key],
        isHighlighted: vehicleFeature.isHighlighted,
      })) ?? [],
    [flattenedFeaturesById, vehicleDetail?.features]
  );

  const analysedFeatureDefinitions = [
    ...flattenFeatures(features),
    ...interiorMaterials.map((item) => ({
      value: item.value,
      label: item.label,
    })),
  ];

  const analysedFeatures = useMemo(() => {
    const result = vehicleDetail?.features?.map((feature) => feature.key) ?? [];

    // Interior material pseudo-feature value (workaround based on Alpha implementation)
    const interiorMaterial = vehicleDetail?.interiorMaterial;
    if (isNotNil(interiorMaterial)) {
      result.push(interiorMaterial);
    }

    return result;
  }, [vehicleDetail?.features, vehicleDetail?.interiorMaterial]);

  const handleOnClick = () => {
    if (!id) {
      return;
    }

    if (!saleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return navigate(
        composePath(vehiclesRoutes.editServiceVehicle, {
          params: {id: id as string},
          queryParams: {tab: 'features'},
        })
      );
    }

    return navigate(
      composePath(vehiclesRoutes.editDetailTab, {params: {id: id as string, tab: 'features'}})
    );
  };

  return (
    <HStack spacing={4} wrap>
      <Box flex={1}>
        <Card
          title={i18n.t('general.labels.allFeatures')}
          actions={
            canEditVehicle
              ? [
                  {
                    type: 'button',
                    variant: 'link',
                    leftIcon: 'image/edit',
                    onClick: handleOnClick,
                    'data-testid': testIds.vehicles.features('allFeatures-edit'),
                    title: i18n.t('general.actions.edit'),
                  },
                ]
              : undefined
          }
        >
          <FeaturesDetail
            categories={categories}
            features={vehicleFeatures}
            addFeaturesAction={{
              title: i18n.t('entity.vehicle.actions.addFeatures'),
              onClick: handleOnClick,
              isDisabled: !canEditVehicle,
            }}
          />
        </Card>
      </Box>
      <Show when={!!saleVehicle}>
        <Show when={isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase}>
          <VStack width={['100%', '100%', 100, 100]} spacing={4}>
            <Show when={saleVehicle?.id}>
              <FeatureMarketAnalysisCard
                data-testid={testIds.vehicles.features('features')}
                allFeatures={analysedFeatureDefinitions}
                vehicleFeatures={analysedFeatures}
                vehicleMake={vehicleDetail?.make ?? ''}
                vehicleModel={vehicleDetail?.modelFamily ?? ''}
                vehicleYear={vehicleYear}
              />
            </Show>
          </VStack>
        </Show>
      </Show>
      <Show when={!saleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)}>
        <VStack width={['100%', '100%', 100, 100]} spacing={4}>
          <FeatureMarketAnalysisCard
            data-testid={testIds.vehicles.features('features')}
            allFeatures={analysedFeatureDefinitions}
            vehicleFeatures={analysedFeatures}
            vehicleMake={vehicleDetail?.make ?? ''}
            vehicleModel={vehicleDetail?.modelFamily ?? ''}
            vehicleYear={vehicleYear}
          />
        </VStack>
      </Show>
    </HStack>
  );
}

function flattenFeatures(
  featureItems: readonly VehicleCatalogueEnumOption[]
): VehicleCatalogueEnumOption[] {
  return featureItems.flatMap((item) => {
    const {children, ...itemWithoutChildren} = item;
    return [...flattenFeatures(children || []), itemWithoutChildren];
  });
}
