import {Menu} from 'platform/components';

import {useLocation} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {myProfileRoutes} from '@omnetic-dms/routes';
import {NavigationItem} from '@omnetic-dms/shared';

import {buildArray, useNavigate} from 'shared';

export function MyProfileNavigation() {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const menuItems = buildArray<NavigationItem>([
    {
      id: 'my-profile-personal-information',
      label: i18n.t('entity.user.labels.personalInformation'),
      href: myProfileRoutes.personalInformation,
      leftIcon: 'social/person',
    },
    {
      id: 'my-profile-security',
      label: i18n.t('entity.user.labels.security'),
      href: myProfileRoutes.security,
      leftIcon: 'hardware/security',
    },
    {
      id: 'my-profile-dashboard',
      label: i18n.t('page.myProfile.dashboard.sidebarTitle'),
      href: myProfileRoutes.dashboard,
      leftIcon: 'action/dashboard',
    },
    {
      id: 'my-profile-language',
      label: i18n.t('entity.user.labels.langAndTime'),
      href: myProfileRoutes.language,
      leftIcon: 'action/language',
    },
    {
      id: 'my-profile-notifications',
      label: i18n.t('page.settings.notifications.title'),
      href: myProfileRoutes.notifications,
      leftIcon: 'alert/notification_important',
    },
  ]);

  const allItems = menuItems;
  const activeItem = allItems.find((item) => item.href && pathname.includes(item.href));

  const handleItemChange = (id: string) => {
    const item = allItems.find((item) => item.id === id);
    if (item?.href) {
      navigate(item.href);
    }
  };

  return (
    <Menu
      items={menuItems}
      activeItemId={activeItem?.id}
      onItemChange={handleItemChange}
      isSearchable
      data-testid="menu"
    />
  );
}
