import {Form, FormButton, FormField, showNotification} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';

import {useChangeCurrentUserPasswordMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {yupChangePasswordSchema} from './yupChangePasswordFormSchema';

type ChangePasswordFormType = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export function ChangePasswordForm() {
  const [changePassword] = useChangeCurrentUserPasswordMutation();

  const handleSubmit = async ({currentPassword, newPassword}: ChangePasswordFormType) => {
    await changePassword({
      changeCurrentUserPasswordRequestBody: {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t(`general.notifications.passwordChanged`)))
      .catch(handleApiError);
  };

  return (
    <Form<ChangePasswordFormType>
      shouldWatchForUnsavedChanges
      defaultValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      schema={yupChangePasswordSchema}
      mode="onBlur"
      onSubmit={handleSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={6}>
          <FormField
            control={control}
            isRequired
            type="password"
            name="currentPassword"
            label={i18n.t('page.myProfile.labels.currentPassword')}
          />

          <Grid columns={2} spacing={4}>
            <GridItem>
              <FormField
                control={control}
                type="password"
                isRequired
                name="newPassword"
                label={i18n.t('page.myProfile.labels.newPassword')}
              />
            </GridItem>
            <GridItem>
              <FormField
                control={control}
                isRequired
                type="password"
                name="confirmPassword"
                label={i18n.t('page.myProfile.labels.confirmPassword')}
              />
            </GridItem>
          </Grid>

          <Box maxWidth={18}>
            <FormButton
              type="submit"
              isDisabled={!formApi.formState.isValid}
              title={i18n.t('general.actions.change')}
              control={control}
            />
          </Box>
        </VStack>
      )}
    </Form>
  );
}
