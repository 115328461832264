import flagsmith from 'flagsmith';
import {IFlagsmithFeature, IState} from 'flagsmith/types';
import {Button, Card, Search, Separator, showNotification, Switch} from 'platform/components';
import {Grid, Hide, HStack, Scroll, Space, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {filter, isEmpty, map, pipe} from 'ramda';
import {isFalsy, isTrue} from 'ramda-adjunct';

import {browserStorageKey} from '@omnetic-dms/config';

import {getFixedTranslation} from '../utils/getFixedTranslation';

type FeatureWithKey = IFlagsmithFeature & {key: string};

const getFlagsFromState =
  (searchString: string | null) =>
  (state: IState<string, string>): [string, IFlagsmithFeature][] =>
    Object.entries(state?.flags ?? {}).filter(
      ([key]) => !searchString || key.toLowerCase().includes(searchString.toLowerCase())
    ) ?? [];

const sortFlagsAlphabetically = (flags: [string, IFlagsmithFeature][]) =>
  flags.sort(([a], [b]) => a.localeCompare(b));

export function FeatureFlagsMock() {
  const isMocking = localStorage.getItem(browserStorageKey.MOCK_FLAGS_KEY) !== null;
  const [, setCount] = useState(0);
  const [updateFlags, setUpdatedFlags] = useState<FeatureWithKey[]>([]);
  const rerenderComponent = () => setCount((prev) => prev + 1);
  const [searchString, setSearchString] = useState<string | null>(null);

  const flagsmithState = flagsmith.getState();

  const flags = pipe(
    getFlagsFromState(searchString),
    filter(([key]: [string, IFlagsmithFeature]) => !updateFlags.some((item) => item.key === key)),
    sortFlagsAlphabetically,
    map(([key, value]) => ({key, ...value}))
  )(flagsmithState);

  const toggleFlag = (key: string, enabled: boolean) => {
    const prevFlagState = flagsmithState.flags?.[key];

    if (!prevFlagState) {
      // TS handle - if somehow we are trying to update flag that doesn't exist
      showNotification.error("Flag doesn't exist");
      return;
    }

    const newFlagsmithState = {
      ...flagsmithState,
      flags: {...flagsmithState.flags, [key]: {...prevFlagState, enabled}},
    };

    setUpdatedFlags((prevState) =>
      prevState.some((item) => item.key === key)
        ? prevState.map((item) => (item.key === key ? {...item, enabled} : item))
        : [...prevState, {...prevFlagState, enabled, key}]
    );

    flagsmith.setState(newFlagsmithState);

    try {
      localStorage.setItem('BULLET_TRAIN_DB', JSON.stringify(newFlagsmithState));
    } catch (error) {
      // should never happen. If it does, something is very wrong
      showNotification.error('Updating cache failed, contact Martin Slaby for debug');

      setTimeout(() => {
        window.localStorage.clear();
        window.location.reload();
      }, 2000);
    }
    rerenderComponent();
  };

  const handleToggleIsMocking = (val: boolean) => {
    if (isTrue(val)) {
      localStorage.setItem(browserStorageKey.MOCK_FLAGS_KEY, 'true');
      rerenderComponent();
      return;
    }

    localStorage.removeItem(browserStorageKey.MOCK_FLAGS_KEY);
    rerenderComponent();
    localStorage.removeItem('BULLET_TRAIN_DB');
    window.location.reload();
  };

  return (
    <Card
      control={{
        type: 'switch',
        value: isMocking,
        onChange: handleToggleIsMocking,
      }}
      title={getFixedTranslation('page.settings.labels.featureFlagsMock')}
      isExpanded={isMocking}
      variant="inlineGrey"
      flags={[
        isMocking
          ? {
              label: getFixedTranslation('general.labels.on'),
              colorScheme: 'orange',
              isSubtle: true,
            }
          : {
              label: getFixedTranslation('general.labels.off'),
              colorScheme: 'neutral',
              isSubtle: true,
            },
      ]}
    >
      <VStack spacing={2}>
        <HStack spacing={2}>
          <Search value={searchString} onChange={setSearchString} />
          <Button
            title={getFixedTranslation('page.settings.labels.refreshPage')}
            onClick={() => window.location.reload()}
            variant="secondary"
          />
        </HStack>

        <Hide when={isEmpty(updateFlags)}>
          <Text size="small" color="link">
            {getFixedTranslation('page.settings.labels.updatedFlags')}
          </Text>
          <Separator spacing={0} />

          <Grid columns={2} align="flex-end" spacing={2}>
            {updateFlags.map(({key, enabled}) => (
              <HStack key={key}>
                <Switch value={enabled} label={key} onChange={(val) => toggleFlag(key, val)} />
              </HStack>
            ))}
          </Grid>
        </Hide>

        <Grid columns={2} align="flex-end">
          <Text size="small" color="success">
            {getFixedTranslation('page.settings.labels.enabledFlags')}
          </Text>
          <Text size="small" color="danger">
            {getFixedTranslation('page.settings.labels.disabledFlags')}
          </Text>
        </Grid>
        <Separator spacing={0} />
      </VStack>
      <Scroll auto maxHeight={100}>
        <Space vertical={4} />
        <Grid columns={2}>
          <VStack spacing={2}>
            {flags
              .filter(({enabled}) => isTrue(enabled))
              .map(({key, enabled}) => (
                <HStack key={key}>
                  <Switch value={enabled} label={key} onChange={(val) => toggleFlag(key, val)} />
                </HStack>
              ))}
          </VStack>
          <VStack spacing={2}>
            {flags
              .filter(({enabled}) => isFalsy(enabled))
              .map(({key, enabled}) => (
                <HStack key={key}>
                  <Switch value={enabled} label={key} onChange={(val) => toggleFlag(key, val)} />
                </HStack>
              ))}
          </VStack>
        </Grid>
      </Scroll>
    </Card>
  );
}
