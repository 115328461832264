export const dashboardRoutes = {
  home: '/',
} as const;

export const loginRoutes = {
  login: '/login',
  loginWorkspace: `/login/workspace`,
  loginChangePassword: `/login/change-password`,
  loginForgotPassword: `/login/forgot-password`,
} as const;

export const quickPurchaseRoutes = {
  auctionsList: '/quick-purchase/auctions',
} as const;

export const employeeRoutes = {
  overview: '/employees',
  createEmployee: '/employees/create-employee',
  detail: '/employees/:id',
  personalInfo: '/employees/:id/personalInfo',
  organizationalPlacement: '/employees/:id/organizationalPlacement',
  userDataAndRole: '/employees/:id/userDataAndRole',
  externalApiKeys: '/employees/:id/externalApiKeys',
  externalSystems: '/employees/:id/externalSystems',
  historyOfActivities: '/employees/:id/historyOfActivities',
  employeeDetailRowControl: '/employees/:id/*',
} as const;

export const accountingRoutes = {
  accountingOverview: '/accounting',
  invoiceOverview: '/accounting/invoices',
  cashReceiptsOverview: '/accounting/cash-receipts',
  invoiceNew: '/accounting/invoice/new',
  newCorrectiveTaxDocument: '/accounting/invoice/correctiveTaxDocument/new/:id',
  invoiceDraft: '/accounting/invoice/draft/:id',
  invoiceDetail: '/accounting/invoice/:id',
  balanceInvoiceDetail: '/accounting/invoice/balance-invoice/:id',
  correctiveTaxDocumentDetail: '/accounting/invoice/correctiveTaxDocument/:id',
  taxDocumentForPaymentDetail: '/accounting/invoice/taxDocumentForPayment/:id',
  proformaInvoiceDetail: '/accounting/invoice/proformaInvoice/:id',
  cashReceiptNestedRoute: '/accounting/cash-receipt/:id/:type',
  cashReceiptDetail: '/accounting/cash-receipt/:id',
  createCashReceiptExpense: '/accounting/cash-receipt/new/expense',
  createCashReceiptIncome: '/accounting/cash-receipt/new/income',
} as const;

export const vehiclesRoutes = {
  home: '/vehicles',
  create: '/vehicles/create',
  createServiceVehicle: '/vehicles/create-service-vehicle',
  clone: '/vehicles/clone',
  cloneServiceVehicle: '/vehicles/:id/clone-service-vehicle',
  rowControlDetail: '/vehicles/:id/:section/*',
  detail: '/vehicles/:id/details',
  businessCases: '/vehicles/:id/business-cases',
  complaints: '/vehicles/:id/complaints',
  edit: '/vehicles/:id/edit',
  editServiceVehicle: '/vehicles/:id/edit-service-vehicle',
  vehicleData: '/vehicles/:id/vehicle-data',
  publishing: '/vehicles/:id/publishing',
  photos: '/vehicles/:id/photos/:tab',
  photoByInspectionId: '/vehicles/:id/photos/:tab/:inspectionId',
  features: '/vehicles/:id/features',
  serviceIntervals: '/vehicles/:id/service-intervals',
  editDetailTab: '/vehicles/:id/edit/:tab',
  conditionsInspections: '/vehicles/:id/conditions/inspections',
  inspectionDetail: '/vehicles/:id/conditions/inspections/:inspectionId',
  inspectionDetailTab: '/vehicles/:id/conditions/inspections/:inspectionId/:inspectionTab',
  conditions: '/vehicles/:id/conditions',
  conditionDetail: '/vehicles/:id/conditions/condition',
  conditionDetailTab: '/vehicles/:id/conditions/condition/:conditionTab',
  finances: '/vehicles/:id/finances',
  financesPrices: '/vehicles/:id/finances/prices',
  financesEarningsCosts: '/vehicles/:id/finances/earnings-costs',
  vehicleWarehouseInfo: '/vehicles/:id/warehouse-info',
  interests: '/vehicles/:id/interests',
  customers: '/vehicles/:id/customers',
  documents: '/vehicles/:id/documents',
  insights: '/vehicles/:id/insights',
  reservations: '/vehicles/:id/reservations',
  testDrives: '/vehicles/:id/test-drives',
  ownership: '/vehicles/:id/ownership',
  historyOfRepairs: '/vehicles/:id/history-of-repairs',
  postponedJobs: '/vehicles/:id/postponed-jobs',
  DownloadEventVehiclePhotos: 'vehicle-photos',
  DownloadEventCondition: 'condition',
  DownloadEventInspections: 'inspections',
  DownloadEventServiceCase: 'service-case',
  DownloadEventDocuments: 'documents',
  inAdvertisement: '/vehicles/in-advertisement',
  vehicleOverview: '/vehicles',
  vehicleOpenSearch: '/vehicles/vehicle-opensearch',
  saleVehicleOpenSearch: '/vehicles/sale-vehicle-opensearch',
  serviceVehicleOpenSearch: '/vehicles/service-vehicle-opensearch',
  vehicleWarehouses: '/vehicles/warehouses',
  warehouseMovements: '/vehicles/warehouseMovements',
  threeHundredAndSixtyPhotos: '/vehicles/:id/360photos',
  activityLog: '/vehicles/:id/activity-log',
  serviceCampaigns: '/vehicles/:id/service-campaigns',
  tires: '/vehicles/:id/tires',
} as const;

export const sourcingRoutes = {
  classifieds_comparison: '/sourcing/classifieds/comparison',
  classifieds_userToBuy: '/sourcing/classifieds/user-to-buy',
  classifieds_userHidden: '/sourcing/classifieds/user-hidden',
  classifieds_userFavourite: '/sourcing/classifieds/user-favourite',
  home: '/sourcing',
  classifieds: '/sourcing/classifieds',
  vehicleDetail: '/sourcing/vehicle/:adId',
} as const;

export const customerRoutes = {
  overview: '/customer',
  create: '/customer/create',
  detail: '/customer/:id',
  customerInformation: '/customer/:id/customerInformation',
  GDPRConsents: '/customer/:id/GDPRConsents',
  businessCases: '/customer/:id/businessCases',
  interests: '/customer/:id/interests',
  vehicles: '/customer/:id/vehicles',
  documents: '/customer/:id/documents',
  serviceCases: '/customer/:id/serviceCases',
  customerContracts: '/customer/:id/customerContracts',
  activityLogs: '/customer/:id/activityLogs',
  tires: '/customer/:id/tires',
  rowControlDetail: '/customer/:id/*',
} as const;

export const inspectionRoutes = {
  inspection: '/inspection',
} as const;

export const analyticsRoutes = {
  overview: '/analytics',
  dashboard: '/analytics/:id',
} as const;

export const businessCaseRoutes = {
  home: '/business-case',
  openSearch: '/business-case/open-search',
  complaintsList: '/business-case/complaints',
  concept: '/business-case/concept',
  detail: '/business-case/:id/:section',
  overview: '/business-case/:id/overview',
  customer: '/business-case/:id/customer',
  buying: '/business-case/:id/buying',
  testDrive: '/business-case/:id/test-drive',
  selling: '/business-case/:id/selling',
  checkout: '/business-case/:id/checkout',
  documents: '/business-case/:id/documents',
  reservations: '/business-case/:id/reservations',
  insurance: '/business-case/:id/insurance',
  activityLog: '/business-case/:id/activity-log',
  complaints: '/business-case/:id/complaints',
} as const;

export const interestRoutes = {
  overview: '/interest',
  create: '/interest/create',
  detail: '/interest/:id',
} as const;

export const interestsRoutes = {
  home: '/interest',
  concept: '/interest/concept',
  wildcard: '/interest/:id/*',
  tabDetail: '/interest/:id/:tab',
  overview: '/interest/:id',
} as const;

export const addCarRoute = '/add-car';

export const myProfileRoutes = {
  home: '/my-profile',
  language: '/my-profile/language',
  personalInformation: '/my-profile/personal-information',
  dashboard: '/my-profile/dashboard',
  security: '/my-profile/security',
  notifications: '/my-profile/notification',
  notificationDetail: '/my-profile/notification/:id',
} as const;

export const settingsRoutes = {
  home: '/settings',
  advertising: '/settings/advertising',
  backgroundRemoval: '/settings/advertising/background-removal',
  // remove after this feature flag is removed "settings_background_removal"
  backgroundRemovalOld: '/settings/advertising/background-removal-old',
  backgroundRemovalCreate: '/settings/advertising/background-removal/create',
  backgroundRemovalDetail: '/settings/advertising/background-removal/:id',
  advertisingPlatforms: '/settings/advertising/platforms',
  advertisingPlatformsDetail: '/settings/advertising/platforms/:code',
  advertisingPromoPhotos: '/settings/advertising/promotional-photos',
  advertisingPromoPhotosCreate: '/settings/advertising/promotional-photos/create',
  advertisingPromoPhotosDetail: '/settings/advertising/promotional-photos/:id',
  // remove after this feature flag is removed "SETTINGS_WATERMARKS"
  advertisingWatermarkingOld: '/settings/advertising/watermark-old',
  advertisingWatermarking: '/settings/advertising/watermark',
  advertisingWatermarkingCreate: '/settings/advertising/watermark/create',
  advertisingWatermarkingDetail: '/settings/advertising/watermark/:id',
  salePreset: '/settings/advertising/sale-preset',
  salePresetCreate: '/settings/advertising/sale-preset/create',
  salePresetDetail: '/settings/advertising/sale-preset/:id',
  exportParameters: '/settings/export-parameters',
  advertisingQrCodeDefinition: '/settings/advertising/qr-code',
  notificationsOverview: '/settings/notification',
  notificationsDetail: '/settings/notification/:id',
  businessCase: '/settings/business-case',
  businessCaseDueDates: '/settings/business-case/due-dates',
  businessCaseDueDatesDetail: '/settings/business-case/due-dates/:id',
  businessCaseDueDatesNew: '/settings/business-case/due-dates/new',
  businessCaseDefinition: '/settings/business-case/business-case-definition',
  businessCaseCentralizedPricing: '/settings/business-case/centralized-pricing',
  businessCaseCommissionFee: '/settings/business-case/commission-fee',
  businessCaseEditCommissionFee: '/settings/business-case/commission-fee/:id',
  businessCaseCreateCommissionFee: '/settings/business-case/commission-fee/new',
  interest: '/settings/interest',
  interestDefinition: '/settings/interest/interest-definition',
  interestDefinitionDetail: '/settings/interest/interest-definition/:id',
  productCatalog: '/settings/product-catalog',
  codelistDetail: '/settings/codelists/:id',
  codelists: '/settings/codelists',
  customFields: '/settings/custom-fields',
  vehicleTopMakes: '/settings/top-makes',
  signatures: '/settings/signatures',
  vehicleSeriesDefinition: '/settings/vehicle-series-definition',
  vehicleNewSeriesDefinition: '/settings/vehicle-series-definition/new',
  vehicleEditSeriesDefinition: '/settings/vehicle-series-definition/:id',
  vehicleInspections: '/settings/inspections',
  vehicleInspectionDetail: '/settings/inspections/:id',
  vehicleInspectionsNew: '/settings/inspections/new',
  vehicleCondition: '/settings/condition',
  vehicleParking: '/settings/parking',
  esignoDetail: '/settings/esigno',
  vehicleParkingAdd: '/settings/parking/add',
  vehicleParkingEdit: '/settings/parking/:id',
  customerCodeDefinition: '/settings/customer-definition',
  customerCodeDefinitionDetail: '/settings/customer-definition/:id',
  roleManagement: '/settings/role-management',
  roleManagementDetail: '/settings/role-management/:roleId',
  userManagement: '/settings/user-management',
  userManagementAddUser: '/settings/user-management/add-user',
  userManagementDetail: '/settings/user-management/:id',
  documentTemplates: '/settings/document-templates',
  vatTotalAndCalculationOverview: '/settings/vat-total-and-calculation',
  vatTotalAndCalculationDetail: '/settings/vat-total-and-calculation/:id',
  vatTotalAndCalculationNew: '/settings/vat-total-and-calculation/new',
  warehousesList: '/settings/warehouse/warehouses',
  warehousesCreate: '/settings/warehouse/warehouses/create',
  warehousesDetail: '/settings/warehouse/warehouses/:id',
  warehousesMarketingCode: '/settings/warehouse/marketing-code',
  warehousesMarketingCodeCreate: '/settings/warehouse/marketing-code/create',
  warehousesMarketingCodeDetail: '/settings/warehouse/marketing-code/:id',
  warehousesDirectSaleVariants: '/settings/warehouse/direct-sale-variants',
  warehousesDirectSaleVariantsCreate: '/settings/warehouse/direct-sale-variants/create',
  warehousesDirectSaleVariantsDetail: '/settings/warehouse/direct-sale-variants/:id',
  warehousesServiceOrderIssueVariants: '/settings/warehouse/service-order-issue-variants',
  warehousesServiceOrderIssueVariantsCreate:
    '/settings/warehouse/service-order-issue-variants/create',
  warehousesServiceOrderIssueVariantsDetail: '/settings/warehouse/service-order-issue-variants/:id',
  warehousesDiscountGroup: '/settings/warehouse/discount-group',
  warehousesDiscountGroupCreate: '/settings/warehouse/discount-group/create',
  warehousesDiscountGroupDetail: '/settings/warehouse/discount-group/:id',
  warehousesAccount: '/settings/warehouse/account',
  warehousesAccountCreate: '/settings/warehouse/account/create',
  warehousesAccountDetail: '/settings/warehouse/account/:id',
  warehousesArticleCategory: '/settings/warehouse/article-category',
  warehousesArticleCategoryCreate: '/settings/warehouse/article-category/create',
  warehousesArticleCategoryDetail: '/settings/warehouse/article-category/:id',
  warehousesManufacturers: '/settings/warehouse/manufacturers',
  warehousesManufacturersCreate: '/settings/warehouse/manufacturers/create',
  warehousesManufacturersDetail: '/settings/warehouse/manufacturers/:id',
  warehousesMeasurementUnits: '/settings/warehouse/measurementUnits',
  warehousesMeasurementUnitsCreate: '/settings/warehouse/measurementUnits/create',
  warehousesMeasurementUnitsDetail: '/settings/warehouse/measurementUnits/:id',
  warehousesSupplierOrderVariants: '/settings/warehouse/warehouseSupplierOrderVariants',
  warehousesSupplierOrderVariantsCreate:
    '/settings/warehouse/warehouseSupplierOrderVariants/create',
  warehousesSupplierOrderVariantsDetail: '/settings/warehouse/warehouseSupplierOrderVariants/:id',
  suppliers: '/settings/suppliers',
  gdprConsentTypes: '/settings/gdpr-consent-types',
  gdprConsentTypesNew: '/settings/gdpr-consent-types/new',
  gdprConsentTypesEdit: '/settings/gdpr-consent-types/:id',
  tenant: '/settings/tenant',
  billingInformation: '/settings/billing-information',
  billingInformationEdit: '/settings/billing-information/:id',
  billingInformationNew: '/settings/billing-information/new',
  seriesDefinition: '/settings/series-definition',
  seriesDefinitionNew: '/settings/series-definition/new',
  seriesDefinitionEdit: '/settings/series-definition/:id',
  messageTemplates: '/settings/message-templates',
  messageTemplatesNew: '/settings/message-templates/new/:type',
  messageTemplatesEdit: '/settings/message-templates/edit/:id/:type',
  branches: '/settings/branches',
  branchesNew: '/settings/branches/new',
  branchesEdit: '/settings/branches/:id',
  cashRegisters: '/settings/cash-registers',
  proformaGeneration: '/settings/proforma-generation',
  proformaGenerationDetail: '/settings/proforma-generation/:id',
  proformaGenerationNew: '/settings/proforma-generation/new',
  taxDocumentIssuedType: '/settings/tax-document/issued-type',
  taxDocumentIssuedTypeDetail: '/settings/tax-document/issued-type/:id',
  taxDocumentIssuedTypeNew: '/settings/tax-document/issued-type/new',
  taxDocumentImportType: '/settings/tax-document/import-type',
  taxDocumentImportTypeDetail: '/settings/tax-document/import-type/:branchId',
  taxDocumentImportTypeNew: '/settings/tax-document/import-type/new',
  invoiceCanceling: '/settings/invoice-canceling',
  invoiceCancelingDetail: '/settings/invoice-canceling/:id',
  accountingErrorLog: '/settings/accounting-error-logs',
  accountingErrorLogDetail: '/settings/accounting-error-logs/:id',
  accountingActivityLog: '/settings/accounting-activity-logs',
  accountingMatrixManagement: '/settings/accounting-matrix-management',
  newInvoiceCanceling: '/settings/invoice-canceling/new',
  invoiceMarginOverview: '/settings/invoice-margin',
  newInvoiceMargin: '/settings/invoice-margin/new',
  invoiceMarginDetail: '/settings/invoice-margin/:id',
  invoiceCostOverview: '/settings/invoice-costs',
  generatedDocuments: '/settings/generated-documents',
  invoiceCostNew: '/settings/invoice-cost/new',
  invoiceCostDetail: '/settings/invoice-cost/:id',
  cashRegistersDetail: '/settings/cash-registers/:id',
  newCashRegister: '/settings/cash-registers/new',
  cashLimits: '/settings/cash-limits',
  cashLimitsAdd: '/settings/cash-limits/add',
  cashLimitsEdit: '/settings/cash-limits/:id',
  invoiceDefinition: '/settings/invoice-definition',
  ProformaCreationOverview: '/settings/proforma-creation',
  ProformaCreationEdit: '/settings/proforma-creation/:id',
  ProformaCreationCreate: '/settings/proforma-creation/new',
  documentRoundingDefinitions: '/settings/document-rounding-definitions',
  documentRoundingDefinitionsAdd: '/settings/document-rounding-definitions/add',
  documentRoundingDefinitionsEdit: '/settings/document-rounding-definitions/:id/edit',
  preAccountingProcessingOverview: '/settings/pre-accounting-processing',
  preAccountingProcessingDetail: '/settings/pre-accounting-processing/:id',
  preAccountingProcessingNew: '/settings/pre-accounting-processing/new',
  vehicleServices: '/settings/vehicle-services',
  vehicleServicesCebia: '/settings/vehicle-services/cebia',
  vehicleServicesFeatures: '/settings/vehicle-services/features',
  vehicleServicesRokVy: '/settings/vehicle-services/rokvy',
  vehicleWarehouseManagement: '/settings/vehicle-warehouse-management/',
  vehicleWarehouseCreate: '/settings/vehicle-warehouse-management/create',
  vehicleWarehouseEdit: '/settings/vehicle-warehouse-management/:id',
  smsGateways: '/settings/sms-gateways',
  smsGatewaysKonzulta: '/settings/sms-gateways/konzulta',
  emailServices: '/settings/email-services',
  emailServicesSmtp: '/settings/email-services/smtp',
  autostacjaSettings: '/settings/autostacja',
  autostacjaSettingsSubscriptionKey: '/settings/autostacja/subscription-key',
  jbr: '/settings/jbr',
  jbrSettings: '/settings/jbr-settings',
  customerVerificationServices: '/settings/customer-verification-services',
  customerVerificationDistraints: '/settings/customer-verification-services/distraints',
  reservation: '/settings/reservation',
  locale: '/settings/locale',
  workshopPresetsCategory: '/settings/workshop-presets-category',
  workshopPresetsCategoryCreate: '/settings/workshop-presets-category/create',
  workshopPresetsCategoryDetail: '/settings/workshop-presets-category/:id',
  inspections: '/settings/inspectionsV2',
  inspectionCreate: '/settings/inspectionsV2/create',
  inspectionDetail: '/settings/inspectionsV2/:id',
  checkinInspection: '/settings/checkin-inspection',
  handoverInspection: '/settings/handover-inspection',
  workType: '/settings/work-type',
  workTypeCreate: '/settings/work-type/create',
  workTypeDetail: '/settings/work-type/:id',
  vehicleGroups: '/settings/vehicle-groups',
  vehicleGroupsCreate: '/settings/vehicle-groups/create',
  vehicleGroupsDetail: '/settings/vehicle-groups/:id',
  employeeCompetences: '/settings/employee-competences',
  employeeCompetencesCreate: '/settings/employee-competences/create',
  employeeCompetencesDetail: '/settings/employee-competences/:id',
  labourRates: '/settings/labour-rates',
  labourRatesCreate: '/settings/labour-rates/create',
  labourRatesDetail: '/settings/labour-rates/:id',
  employeeCostCenters: '/settings/employee-cost-centers',
  employeeCostCentersCreate: '/settings/employee-cost-centers/create',
  employeeCostCentersDetail: '/settings/employee-cost-centers/:id',
  employeeWorkTeams: '/settings/employee-work-teams',
  employeeWorkTeamsCreate: '/settings/employee-work-teams/create',
  employeeWorkTeamsDetail: '/settings/employee-work-teams/:id',
  employeeProfessions: '/settings/employee-professions',
  employeeProfessionsCreate: '/settings/employee-professions/create',
  employeeProfessionsDetail: '/settings/employee-professions/:id',
  serviceCaseType: '/settings/service-case-type',
  serviceCaseTypeCreate: '/settings/service-case-type/create',
  serviceCaseTypeDetail: '/settings/service-case-type/:id',
  serviceOrderVariants: '/settings/service-order-variants',
  serviceOrderVariantsCreate: '/settings/service-order-variants/create',
  serviceOrderVariantsDetail: '/settings/service-order-variants/:id',
  labourCatalogues: '/settings/labour-catalogues',
  labourCataloguesCreate: '/settings/labour-catalogues/create',
  labourCataloguesDetail: '/settings/labour-catalogues/:id',
  workshopCustomerGroups: '/settings/workshop-customer-groups',
  workshopCustomerGroupCreate: '/settings/workshop-customer-groups/create',
  workshopCustomerGroupDetail: '/settings/workshop-customer-groups/:id',
  workshopSmallUnitMaterial: '/settings/workshop-small-unit-material',
  workshopSmallUnitMaterialDetail: '/settings/workshop-small-unit-material/:id',
  workshopSmallUnitMaterialCreate: '/settings/workshop-small-unit-material/create',
  customerContracts: '/settings/customer-contracts',
  customerContractsCreate: '/settings/customer-contracts/create',
  customerContractsDetail: '/settings/customer-contracts/:id',
  tiresInventory: '/settings/tires-inventory',
  tiresInventoryCreate: '/settings/tires-inventory/create',
  tiresInventoryDetail: '/settings/tires-inventory/:id',
  tireManufacturers: '/settings/tire-manufacturers',
  tireLocations: '/settings/tire-locations',
  tireStorageLocation: '/settings/tire-storage-locations',
  tireCreateStorageLocation: '/settings/tire-storage-locations/new',
  tireStorageLocationDetail: '/settings/tire-storage-locations/:id',
  tireWarehouses: '/settings/tire-warehouses',
  tireWarehousesCreate: '/settings/tire-warehouses/create',
  tireWarehousesDetail: '/settings/tire-warehouses/:id',
  workshopServicePackages: '/settings/workshop-service-packages',
  workshopCreateServicePackages: '/settings/workshop-service-packages/create',
  workshopEditServicePackages: '/settings/workshop-service-packages/:id',
  workshopMeasurementUnits: '/settings/measurement-units',
  workshopMeasurementUnitCreate: '/settings/measurement-units/create',
  workshopMeasurementUnitDetail: '/settings/measurement-units/:id',
  workshopServiceOrderGroups: '/settings/service-groups',
  workshopServiceOrderGroupCreate: '/settings/service-groups/create',
  workshopServiceOrderGroupDetail: '/settings/service-groups/:id',
} as const;

export const workshopRoutes = {
  overview: '/workshop',
  serviceCases: '/workshop/service-cases',
  serviceOrders: '/workshop/service-orders',
  serviceCasesOpenSearch: '/workshop/service-cases-open-search',
  createServiceCase: '/workshop/service-case/create',
  serviceCaseDetail: '/workshop/service-cases/:id',
  draftServiceCase: '/workshop/draft-service-case/:id',
  directSales: '/workshop/direct-sales',
  labourOperationSettings: '/workshop/labour-operation-settings',
  serviceCampaigns: '/workshop/service-campaigns',
  serviceCampaignDetail: '/workshop/service-campaign/:id',
  serviceCampaignsUnfinished: '/workshop/service-campaigns/unfinished',
  postponedRequests: '/workshop/postponed-requests',
} as const;

export const warehouseRoutes = {
  articleList: '/warehouses/article-list',
  articleDetailRowControl: '/warehouses/:warehouseId/article/:id/:section*',
  articleDetailOverview: '/warehouses/:warehouseId/article/:id/overview',
  articleDetailAvailability: '/warehouses/:warehouseId/article/:id/availability',
  articleDetailInventories: '/warehouses/:warehouseId/article/:id/inventories',
  articleDetailMovements: '/warehouses/:warehouseId/article/:id/movements',
  articleDetailReservations: '/warehouses/:warehouseId/article/:id/reservations',
  articleDetailSupplierArticles: '/warehouses/:warehouseId/article/:id/supplierArticles',
  serviceOrderIssueNew: '/warehouses/service-order-issue/:serviceOrderIssueNoteId/new',
  serviceOrderIssueCreate: '/warehouses/service-order-issue/create',
  serviceOrderIssues: '/warehouses/service-order-issues',
  serviceOrderIssueDetailRowControl: '/warehouses/service-order-issue/:id/:section*',
  serviceOrderIssueDetailOverview: '/warehouses/service-order-issue/:id/overview',
  serviceOrderIssueDetailIssueNotes: '/warehouses/service-order-issue/:id/issueNotes',
  serviceOrderIssueDetailDocuments: '/warehouses/service-order-issue/:id/documents',
  issueNotes: '/warehouses/issue-notes',
  issueNoteDetailRowControl: '/warehouses/issue-notes/:id/:section*',
  issueNoteDetailOverview: '/warehouses/issue-notes/:id/overview',
  issueNoteDetailDocuments: '/warehouses/issue-notes/:id/documents',
  serviceOrderReturns: '/warehouses/service-order-returns',
  serviceOrderReturnsCreate: '/warehouses/service-order-returns/create',
  serviceOrderReturnsDetailRowControl: '/warehouses/service-order-returns/:id/:section*',
  serviceOrderReturnsDetailOverview: '/warehouses/servicer-order-returns/:id/overview',
  serviceOrderReturnsDetailDocuments: '/warehouses/servicer-order-returns/:id/documents',
  deliveryNoteList: '/warehouses/delivery-note-list',
  deliveryNoteDetailRowControl: '/warehouses/delivery-note/:id/:section*',
  deliveryNoteDetailOverview: '/warehouses/delivery-note/:id/overview',
  receiveNoteList: '/warehouses/receive-note-list',
  receiveNoteCreate: '/warehouses/receive-note-create',
  receiveNoteDetailRowControl: '/warehouses/receive-note-detail/:receiveNoteId/:section*',
  receiveNoteDetailOverview: '/warehouses/receive-note-detail/:receiveNoteId/overview',
  receiveNoteDetailDocuments: '/warehouses/receive-note-detail/:receiveNoteId/documents',
  receiveNoteCorrectionCreate:
    '/warehouses/receive-note-detail/:receiveNoteId/receive-note-correction-create',
  receiveNoteCorrectionDetailRowControl: '/warehouses/receive-note-correction-detail/:id/:section*',
  receiveNoteCorrectionDetailOverview: '/warehouses/receive-note-correction-detail/:id/overview',
  receiveNoteCorrectionDetailDocuments: '/warehouses/receive-note-correction-detail/:id/documents',
  receiveNoteCorrectionsList: '/warehouses/receive-note-corrections-list',
  priceList: '/warehouses/price-list',
  directSalesList: '/warehouses/direct-sales',
  directSalesCreate: '/warehouses/direct-sales/create',
  directSalesVariantChange: '/warehouses/direct-sales/:id/variant/:directSaleVariantId/change',
  directSalesDetailRowControl: '/warehouses/direct-sales/:id/:section*',
  directSalesDetailOverview: '/warehouses/direct-sales/:id/overview',
  directSalesDetailDocuments: '/warehouses/direct-sales/:id/documents',
  directSalesDetailVehicle: '/warehouses/direct-sales/:id/vehicle',
  directSalesDetailCustomer: '/warehouses/direct-sales/:id/customer',
  supplierOrders: '/warehouses/supplier-orders',
  purchaseList: '/warehouses/purchase-list',
  supplierOrderList: '/warehouses/supplier-order-list',
  supplierOrderDetailRowControl: '/warehouses/supplier-order/:id/:section*',
  supplierOrderDetailOverview: '/warehouses/supplier-order/:id/overview',
  supplierOrderDetailDocuments: '/warehouses/supplier-order/:id/documents',
} as const;

export const tiresInventoryRoutes = {
  overview: '/tires-inventory',
  tireOrders: '/tires-inventory/tire-orders',
  tireList: '/tires-inventory/tire-list',
  createTireOrder: '/tires-inventory/tire-order/create',
  tireOrderDetail: '/tires-inventory/tire-order/:id',
} as const;

export const taskManagerRoutes = {
  taskList: '/task-manager',
} as const;

export const restrictedAreaRoutes = {
  home: '/restrictedArea',
} as const;

export const redirectRoute = '/redirect';
export const notFoundRoute = '/not-found';

export const sharePresetRoute = '/share-preset/:code/:sharedPresetId';
