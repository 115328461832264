import {array, bool, object, SchemaOf, string} from 'yup';

import {DeepPartial} from 'react-hook-form';

import {CreateRoundingDefinitionRequestBody, ValidityRequestBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {parseDate, yupString} from 'shared';

export type DocumentRoundingDefinitionFormType = Omit<
  CreateRoundingDefinitionRequestBody,
  'branchId'
> & {
  branchId: string | null;
  isSystem?: boolean;
};

const documentRoundingDefinitionValidityValidations = (
  defaultValue?: DeepPartial<DocumentRoundingDefinitionFormType>
): SchemaOf<DocumentRoundingDefinitionFormType['validities']> =>
  array().of(
    object()
      .shape({
        validFrom: yupString.required(),
        roundType: yupString.required() as SchemaOf<ValidityRequestBody['roundType']>,
        roundStep: yupString.required() as SchemaOf<ValidityRequestBody['roundStep']>,
        taxRounding: bool().required(i18n.t('general.validations.fieldIsRequired')),
      })
      .test('unique', i18n.t('entity.accounting.validations.validFromUnique'), function (value) {
        const parent = this.parent as DocumentRoundingDefinitionFormType['validities'];

        // Checks if `validFrom` are identical in whole array
        if (
          parent
            .filter((v) => v !== value)
            .some((v) => {
              if (!v.validFrom || !value.validFrom) {
                return false;
              }

              const validFrom = parseDate(v.validFrom).toDateString();
              const thisValidFrom = parseDate(value.validFrom).toDateString();

              if (validFrom === thisValidFrom) {
                return true;
              }
              return false;
            })
        ) {
          throw this.createError({
            path: `${this.path}.validFrom`,
          });
        }

        return true;
      })
      .test('noPast', i18n.t('entity.accounting.validations.noPast'), function (value) {
        const nowDate = parseDate(new Date().toDateString()).getTime();
        const validFromDate = value.validFrom
          ? parseDate(parseDate(value.validFrom).toDateString()).getTime()
          : 0;

        // check if date already exists in values (values are passed only while editing)
        const existingItem = defaultValue?.validities?.find(
          (item) => item?.validFrom === value.validFrom
        )?.validFrom;

        if (existingItem) {
          const defaultValueItem = parseDate(parseDate(existingItem).toDateString()).getTime();

          // Checking if defaultValue is equal to field value - to allow old definitions (edit) passing through this validation
          if (defaultValueItem === validFromDate) {
            return true;
          }
        }

        // Checking if field value is from the past
        if (validFromDate < nowDate) {
          return this.createError({
            path: `${this.path}.validFrom`,
          });
        }

        return true;
      })
  );

export const documentRoundingDefinitionFormValidations = (
  edit?: boolean,
  defaultValue?: DeepPartial<DocumentRoundingDefinitionFormType>
): SchemaOf<DocumentRoundingDefinitionFormType> =>
  object().shape({
    branchId: edit ? (string().nullable() as SchemaOf<string>) : string().required(),
    paymentType: yupString.required() as SchemaOf<
      CreateRoundingDefinitionRequestBody['paymentType']
    >,
    validities: documentRoundingDefinitionValidityValidations(defaultValue),
    isSystem: bool().notRequired(),
  });
