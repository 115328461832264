import validatePhone from 'phone';
import {
  Button,
  Card,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, Grid, GridItem, Heading, HStack, VStack} from 'platform/foundation';
import {mixed, object} from 'yup';

import {useState} from 'react';
import {DeepPartial} from 'react-hook-form';

import {values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  PhoneNumber,
  useGetCurrentUserInfoQuery,
  useGetCustomerV2Query,
  useSignDocumentRemotelyMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, yupString} from 'shared';

import {usePhoneNumbers} from '../../../hooks/usePhoneNumbers';
import {getNaturalPersonFullName} from '../../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../../utils/handleApiError';
import {CustomerSelectChoice} from '../../CustomerSelectChoice/CustomerSelectChoice';
import {SignatoriesFormValues} from '../types/SignatoriesFormValues';
import {StepContentProps} from '../types/StepContentProps';
import {UserSelectChoice} from './UserSelectChoice';

export function SignatoriesSelectStep(props: StepContentProps) {
  const {countriesOptions} = usePhoneNumbers();
  const [sign] = useSignDocumentRemotelyMutation({fixedCacheKey: 'signRemotely'});

  const [phoneInputKey, setPhoneInputKey] = useState(0);
  const refreshPhoneInputKey = () => setPhoneInputKey((prev) => prev + 1);

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {skip: isNilOrEmpty(props.customerId)}
  );

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserError,
  } = useGetCurrentUserInfoQuery();

  const handleSubmit: FormSubmitHandler<SignatoriesFormValues> = async (data) => {
    await sign({
      fileId: props.fileId,
      signDocumentRemotelyRequestBody: {
        signatoryPersons: values(data),
      },
    })
      .unwrap()
      .then(() => props.setSigningStep('Remote_sentToSignatories'))
      .catch((err) => {
        handleApiError(err, {silent: true});
        props.setSigningStep('Remote_error');
      });
  };

  const isLoading = isLoadingCustomer || isCurrentUserLoading;
  const isError = isCustomerError || isCurrentUserError;

  const customerInfo = customer?.foundingPerson ?? customer?.contacts?.[0];

  const defaultValues: DeepPartial<SignatoriesFormValues> = {
    seller: {
      id: 'sig1',
      name: getNaturalPersonFullName(currentUser) ?? undefined,
      email: currentUser?.email,
      phoneNumber: currentUser?.phoneNumber ?? undefined,
    },
    customer: {
      id: 'sig2',
      name: getNaturalPersonFullName(customerInfo) ?? undefined,
      email: customerInfo?.emails?.[0]?.email,
      phoneNumber: customerInfo?.phoneNumbers?.[0] ?? undefined,
    },
  };

  return (
    <VStack spacing={8}>
      <Heading size={3}>{i18n.t('entity.document.labels.signatories')}</Heading>

      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<SignatoriesFormValues>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          schema={schema}
        >
          {(control, formApi) => (
            <>
              <VStack spacing={4}>
                <Card variant="inlineGrey" title={i18n.t('entity.customer.labels.customer')}>
                  <Grid columns={3}>
                    <GridItem span={3}>
                      <CustomerSelectChoice
                        onCustomerSelect={(data) => {
                          formApi.setValue('customer.email', data.email);
                          formApi.setValue('customer.phoneNumber.number', data.phoneNumber);
                          formApi.setValue('customer.phoneNumber.prefix', data.prefix);
                          formApi.setValue('customer.phoneNumber.countryCode', data.countryCode);
                          formApi.setValue('customer.name', data.name);
                        }}
                        onAfterCustomerSelect={refreshPhoneInputKey}
                        data-testid={suffixTestId('CustomerSelectChoice', props)}
                      />
                    </GridItem>

                    <FormField
                      control={control}
                      type="text"
                      name="customer.name"
                      label={i18n.t('entity.person.labels.firstName')}
                      data-testid={suffixTestId('customer-name', props)}
                    />

                    <FormField
                      control={control}
                      type="email"
                      name="customer.email"
                      label={i18n.t('general.labels.email')}
                      data-testid={suffixTestId('customer-email', props)}
                    />

                    <FormField
                      control={control}
                      type="phone"
                      key={phoneInputKey}
                      name="customer.phoneNumber"
                      label={i18n.t('general.labels.phone')}
                      countries={countriesOptions}
                      data-testid={suffixTestId('customer-phoneNumber', props)}
                    />
                  </Grid>
                </Card>

                <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.seller')}>
                  <Grid columns={3}>
                    <GridItem span={3}>
                      <UserSelectChoice
                        formApi={formApi}
                        defaultUserId={currentUser?.id ?? null}
                        onAfterUserSelect={refreshPhoneInputKey}
                        data-testid={suffixTestId('UserSelectChoice', props)}
                      />
                    </GridItem>

                    <FormField
                      control={control}
                      type="text"
                      name="seller.name"
                      label={i18n.t('entity.person.labels.firstName')}
                      data-testid={suffixTestId('seller-name', props)}
                    />

                    <FormField
                      control={control}
                      type="email"
                      name="seller.email"
                      label={i18n.t('general.labels.email')}
                      data-testid={suffixTestId('seller-email', props)}
                    />

                    <FormField
                      control={control}
                      type="phone"
                      key={phoneInputKey}
                      name="seller.phoneNumber"
                      label={i18n.t('general.labels.phone')}
                      countries={countriesOptions}
                      data-testid={suffixTestId('seller-phoneNumber', props)}
                    />
                  </Grid>
                </Card>
              </VStack>

              <DialogFooter>
                <Box paddingHorizontal={0}>
                  <HStack justify="space-between">
                    <Button
                      title={i18n.t('entity.document.actions.goToModeSelect')}
                      variant="dangerOutlined"
                      onClick={() => props.setSigningStep('modeSelect')}
                      data-testid={suffixTestId('modeSelect', props)}
                    />

                    <FormButton
                      title={i18n.t('general.actions.continue')}
                      variant="primary"
                      type="submit"
                      control={control}
                      data-testid={suffixTestId('continue', props)}
                    />
                  </HStack>
                </Box>
              </DialogFooter>
            </>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const phoneNumberSchema = mixed()
  .test(
    'isPhone',
    i18n.t('general.validations.invalidPhoneNumber'),
    (phone?: PhoneNumber | Nullish) => {
      if (isNilOrEmpty(phone?.number)) {
        return true;
      }
      return validatePhone(phone?.number || '', {
        country: phone?.countryCode,
        validateMobilePrefix: false,
      }).isValid;
    }
  )
  .test(
    'required',
    i18n.t('general.validations.fieldIsRequired'),
    (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
  );

const schema = object({
  seller: object({
    email: yupString.email().required(),
    phoneNumber: phoneNumberSchema,
    name: yupString.required(),
  }).required(),
  customer: object({
    email: yupString.email().required(),
    phoneNumber: phoneNumberSchema,
    name: yupString.required(),
  }).required(),
});
