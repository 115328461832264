import {Form, FormField, Separator} from 'platform/components';
import {Grid, GridItem, Text, VStack} from 'platform/foundation';
import * as yup from 'yup';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {useBranches} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {TireWarehouseForm} from '../types/TireWarehouseForm';
import {externalWarehouseFactory} from '../utils/externalWarehouseFactory';
import {ExternalWarehouses} from './ExternalWarehouses';

const yupSchema = yup.object({
  name: yupString.required(),
  branchId: yupString.required(),
  hasExternalWarehouse: yup.boolean(),
  externalWarehouses: yup.array().when('hasExternalWarehouse', {
    is: true,
    then: yup.array().of(
      yup.object({
        warehouseId: yupString.required(),
        isDefault: yup.boolean(),
      })
    ),
  }),
});

interface GeneralTabProps {
  onSubmit: (data: TireWarehouseForm) => void;
  isEditing?: boolean;
  defaultValues?: TireWarehouseForm;
  isLoading?: boolean;
}

export function GeneralTab(props: GeneralTabProps) {
  const branchesQuery = useBranches();
  const navigate = useNavigate();

  const getDefaultData = (): TireWarehouseForm => {
    if (props.defaultValues) {
      return props.defaultValues;
    }

    return {
      name: '',
      branchId: '',
      storageLocationSeparator: '',
      transportDays: 0,
      hasExternalWarehouse: false,
      externalWarehouses: [externalWarehouseFactory(true)],
    };
  };

  return (
    <Form defaultValues={getDefaultData()} schema={yupSchema}>
      {(control, formApi) => (
        <VStack spacing={6}>
          <Grid columns={2}>
            <GridItem>
              <FormField
                type="text"
                control={control}
                name="name"
                label={i18n.t('general.labels.name')}
                isDisabled={props.isLoading}
                isRequired
              />
            </GridItem>
            <GridItem>
              <FormField
                type="choice"
                control={control}
                name="branchId"
                label={i18n.t('entity.branch.labels.branchId')}
                isDisabled={props.isLoading || branchesQuery.isLoading}
                options={branchesQuery.branchOptions}
                isRequired
              />
            </GridItem>
            <GridItem>
              <FormField
                type="text"
                control={control}
                name="storageLocationSeparator"
                label={i18n.t('entity.tireWarehouses.labels.separator')}
                tooltip={i18n.t('entity.tireWarehouses.labels.separatorTooltip')}
                isDisabled={props.isLoading}
                maxLength={1}
                isCounterVisible
              />
            </GridItem>
            <GridItem>
              <FormField
                type="number"
                control={control}
                name="transportDays"
                label={i18n.t('entity.tireWarehouses.labels.daysToTransfer')}
                tooltip={i18n.t('entity.tireWarehouses.labels.daysToTransferTooltip')}
                isDisabled={props.isLoading}
                isStepperVisible
                minStepperValue={0}
              />
            </GridItem>
          </Grid>
          <Separator />
          <Text size="small" color="tertiary">
            {i18n.t('entity.tireWarehouses.labels.externalDescription')}
          </Text>
          <FormField
            type="switch"
            control={control}
            name="hasExternalWarehouse"
            label={i18n.t('entity.tireWarehouses.labels.externalTireWarehouse')}
            isDisabled={props.isLoading}
          />
          {formApi.watch('hasExternalWarehouse') && (
            <ExternalWarehouses control={control} formApi={formApi} isLoading={props.isLoading} />
          )}
          <SettingsFooter
            actions={[
              {
                type: 'button',
                title: props.isEditing
                  ? i18n.t('general.actions.discardChanges')
                  : i18n.t('general.actions.discard'),
                variant: 'secondary',
                onClick: () =>
                  props.isEditing ? navigate(settingsRoutes.tireWarehouses) : navigate(-1),
                isDisabled: props.isLoading,
              },
              {
                type: 'form-button',
                control,
                title: props.isEditing
                  ? i18n.t('general.labels.saveChanges')
                  : i18n.t('general.actions.save'),
                buttonType: 'submit',
                onClick: () => {
                  formApi.handleSubmit((result) => {
                    props.onSubmit({
                      ...result,
                      externalWarehouses: result.hasExternalWarehouse
                        ? result.externalWarehouses
                        : [],
                    });
                  })();
                },
                isDisabled: props.isLoading,
              },
            ]}
          />
        </VStack>
      )}
    </Form>
  );
}
