import {isFeatureEnabled} from 'feature-flags';
import {Action, DataStatus} from 'platform/components';

import {Helmet} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetVehicleWarehouseManagementSettingsQuery,
  useListPermissionExpressionQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {Main, PageTab, permissions, usePermissions} from '@omnetic-dms/shared';

import {buildArray, useNavigate} from 'shared';

import {AdvertisedVehicles} from './(sections)/AdvertisedVehicles/AdvertisedVehicles';
import {OSSaleVehicles} from './(sections)/OSSaleVehicles/OSSaleVehicles';
import {OSServiceVehicles} from './(sections)/OSServiceVehicles/OSServiceVehicles';
import {OSVehicles} from './(sections)/OSVehicles/OSVehicles';
import {VehiclesOverview} from './(sections)/VehiclesOverview/VehiclesOverview';
import {VehicleWarehouses} from './(sections)/VehicleWarehouses/VehicleWarehouses';

export function Vehicles() {
  const navigate = useNavigate();
  const location = useLocation();

  const {data: vehicleWarehouseSettings, isLoading} =
    useGetVehicleWarehouseManagementSettingsQuery();

  const isVehicleWarehouseEnabled =
    isFeatureEnabled(featureFlags.SALES_VEHICLE_WAREHOUSE_MANAGEMENT) &&
    vehicleWarehouseSettings?.isEnabled;

  const isWarehousesPage = location.pathname === vehiclesRoutes.vehicleWarehouses;

  const [canReadWarehouseInfo] = usePermissions({
    permissionKeys: ['vehicleReadWarehouseInformation'],
  });

  const [canPutVehicleUpForSale, canPutVehicleUpToBuy, canReadBusinessCase] = usePermissions({
    permissionKeys: ['vehiclePutUpForSale', 'vehiclePutUpToBuy', 'businessCaseRead'],
  });
  const canCreateSaleVehicle = canPutVehicleUpForSale && canPutVehicleUpToBuy;

  // TODO: T20-56667 [FE] consider creating isNotDisallowed function/hook for permissions evaluation
  const {data: userPermissions} = useListPermissionExpressionQuery();

  const readAdvertisingSettingsPermission = userPermissions?.actions?.find(
    ({actionId, resourceId}) =>
      actionId === permissions.readAdvertisingSettings.actionId &&
      resourceId === permissions.readAdvertisingSettings.resourceId
  );

  const isReadAdvertisingSettingsAvailable =
    isNotNilOrEmpty(readAdvertisingSettingsPermission) &&
    readAdvertisingSettingsPermission!.permissionsExpression.result !== 'DISALLOW';

  const isOSVehicleShowedAndNotSwapped: boolean =
    isFeatureEnabled(featureFlags.SALES_360_VEHICLES) &&
    !isFeatureEnabled(featureFlags.REPLACE_VEHICLE_DG_WITH_OPENSEARCH);

  const tabs = buildArray<PageTab>()
    .when(isOSVehicleShowedAndNotSwapped, {
      id: 'vehicle-opensearch',
      title: i18n.t('page.vehicles.360vehicleView.title'),
      href: vehiclesRoutes.vehicleOpenSearch,
      content: <OSVehicles />,
    })
    .whenNot(isOSVehicleShowedAndNotSwapped, {
      id: 'all',
      title: i18n.t('page.vehicles.title'),
      href: vehiclesRoutes.home,
      content: <VehiclesOverview />,
    })
    .when(
      (isFeatureEnabled(featureFlags.ACL_SALES) || canReadBusinessCase) &&
        isReadAdvertisingSettingsAvailable,
      {
        id: 'inAdvertisement',
        title: i18n.t('page.vehicles.inAdvertisement.title'),
        href: vehiclesRoutes.inAdvertisement,
        content: <AdvertisedVehicles />,
      }
    )
    .when(canReadWarehouseInfo && isVehicleWarehouseEnabled, {
      id: 'vehicleWarehouses',
      title: i18n.t('page.vehicles.vehicleWarehouses.title'),
      href: vehiclesRoutes.vehicleWarehouses,
      content: <VehicleWarehouses />,
    })
    .when(isFeatureEnabled(featureFlags.SALES_360_VEHICLES) && !isOSVehicleShowedAndNotSwapped, {
      id: 'vehicle-opensearch',
      title: i18n.t('page.vehicles.360vehicleView.title'),
      href: vehiclesRoutes.vehicleOpenSearch,
      content: <OSVehicles />,
    })
    .when(isOSVehicleShowedAndNotSwapped, {
      id: 'all',
      title: i18n.t('page.vehicles.title'),
      href: vehiclesRoutes.home,
      content: <VehiclesOverview />,
    })
    .whenFeatureEnabled(featureFlags.SALE_VEHICLE_OPENSEARCH, {
      id: 'saleVehicleOpenSearch',
      title: i18n.t('entity.saleVehicle.labels.title'),
      href: vehiclesRoutes.saleVehicleOpenSearch,
      content: <OSSaleVehicles />,
    })
    .whenFeatureEnabled(featureFlags.SERVICE_VEHICLE_OPENSEARCH, {
      id: 'serviceVehicleOpenSearch',
      title: i18n.t('entity.serviceVehicle.labels.title'),
      href: vehiclesRoutes.serviceVehicleOpenSearch,
      content: <OSServiceVehicles />,
    });

  const actions = buildArray<Action>()
    .when(isVehicleWarehouseEnabled && isWarehousesPage, {
      type: 'button',
      title: i18n.t('entity.vehicleWarehouse.labels.warehouseMovements'),
      variant: 'outlined',
      onClick: () => {
        navigate(vehiclesRoutes.warehouseMovements);
      },
      'data-testid': 'warehouseMovements',
    })
    .whenFeatureEnabled(featureFlags.AFS_SERVICE_CASE_VEHICLE, {
      type: 'dropdown-button',
      title: i18n.t('entity.vehicle.labels.createVehicle'),
      variant: 'primary',
      menuItems: [
        {
          label: i18n.t('entity.vehicle.labels.createSaleVehicle'),
          onClick: () => {
            navigate(vehiclesRoutes.create);
          },
          leftIcon: 'maps/directions_car',
          isDisabled: !canCreateSaleVehicle,
        },
        {
          leftIcon: 'action/build',
          label: i18n.t('entity.vehicle.labels.createServiceVehicle'),
          onClick: () => {
            navigate(vehiclesRoutes.createServiceVehicle);
          },
        },
      ],
      'data-testid': 'createVehicle',
    })
    .whenFeatureNotEnabled(featureFlags.AFS_SERVICE_CASE_VEHICLE, {
      type: 'button',
      title: i18n.t('entity.vehicle.labels.createVehicle'),
      variant: 'primary',
      onClick: () => {
        navigate(vehiclesRoutes.create);
      },
      'data-testid': 'createVehicle',
    });

  return (
    <>
      <Helmet title={i18n.t('page.vehicles.title')} />
      <DataStatus isLoading={isLoading} minHeight={100}>
        <Main isFullHeight data-testid={testIds.vehicles.home()} actions={actions} tabs={tabs} />
      </DataStatus>
    </>
  );
}
