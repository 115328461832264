import {Module, ModuleItem} from '@omnetic-dms/api';
import {moduleKeys} from '@omnetic-dms/config';
import {NotificationDetailWrapper, NotificationOverviewWrapper} from '@omnetic-dms/my-profile';
import {dashboardRoutes, myProfileRoutes, sharePresetRoute} from '@omnetic-dms/routes';

import {buildArray} from 'shared';

import {Homepage} from '../../../pages/Homepage/Homepage';
import {DashboardSettings} from '../../../pages/Profile/DashboardSettings/DashboardSettings';
import {Language} from '../../../pages/Profile/Language/Language';
import {PersonalInformation} from '../../../pages/Profile/PersonalInformation/PersonalInformation';
import {Security} from '../../../pages/Profile/Security/Security';
import {SharePreset} from '../../../pages/SharePreset/SharePreset';

type ContentArrayType = {
  isMyProfileEnabled: boolean;
};

const contentArray = ({isMyProfileEnabled}: ContentArrayType) =>
  buildArray<ModuleItem>([
    {
      id: 'core-share-preset',
      routerProps: {
        path: sharePresetRoute,
        element: <SharePreset />,
      },
    },
    {
      id: 'my-profile-notification',
      routerProps: {
        path: myProfileRoutes.notifications,
        element: <NotificationOverviewWrapper />,
      },
    },
    {
      id: 'my-profile-notification-detail',
      routerProps: {
        path: myProfileRoutes.notificationDetail,
        element: <NotificationDetailWrapper />,
      },
    },
  ])
    .whenNot(isMyProfileEnabled, {
      id: 'core-my-profile-language',
      routerProps: {
        path: myProfileRoutes.language,
        element: <Language />,
      },
    })
    .whenNot(isMyProfileEnabled, {
      id: 'core-my-profile-personalInformation',
      routerProps: {
        path: myProfileRoutes.personalInformation,
        element: <PersonalInformation />,
      },
    })
    .whenNot(isMyProfileEnabled, {
      id: 'core-my-profile-security',
      routerProps: {
        path: myProfileRoutes.security,
        element: <Security />,
      },
    })
    .whenNot(isMyProfileEnabled, {
      id: 'core-my-profile-dashboard',
      routerProps: {
        path: myProfileRoutes.dashboard,
        element: <DashboardSettings />,
      },
    });

export const CoreModule = (data: ContentArrayType): Module => ({
  id: moduleKeys.core,
  layoutProps: {
    title: 'page.homepage.labels.title',
    icon: 'action/dashboard',
    iconV2: 'sidebar/dashboard',
  },
  routerProps: {
    path: dashboardRoutes.home,
    element: <Homepage />,
  },
  content: contentArray(data),
});
