import {Button} from 'platform/components';

import {isNotNil} from 'ramda';

import {browserStorageKey} from '@omnetic-dms/config';

import {getFixedTranslation} from '../utils/getFixedTranslation';

export function CacheCleanup() {
  const handleCacheCleanup = () => {
    const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

    localStorage.clear();
    sessionStorage.clear();

    if (isNotNil(accessToken)) {
      sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, accessToken);
    }

    window.location.reload();
  };

  return (
    <Button
      title={getFixedTranslation('page.settings.labels.cacheCleanup')}
      onClick={handleCacheCleanup}
      data-testid="layout-header-devSettings-cacheCleanup"
      variant="secondary"
      leftIcon="action/restore_page"
    />
  );
}
