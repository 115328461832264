import {ValidityRequestBody} from '@omnetic-dms/api';

import {getApiDateString} from 'shared';

export const getEmptyValidityItem = (validFrom?: Date): ValidityRequestBody => ({
  roundType: 'ALGEBRAIC',
  roundStep: '0.01',
  validFrom: getApiDateString(validFrom ?? new Date()),
  taxRounding: false,
});
