import {object, ref} from 'yup';

import i18n from '@omnetic-dms/i18n';
import {yupPasswordValidation} from '@omnetic-dms/shared';

import {yupString} from 'shared';

export const yupChangePasswordSchema = object().shape({
  currentPassword: yupString.required(),
  newPassword: yupPasswordValidation,
  confirmPassword: yupString
    .required()
    .oneOf([ref('newPassword'), null], i18n.t('general.notifications.errorPasswordsNotMatch')),
});
