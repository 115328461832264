import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  Alert,
  Button,
  Card,
  DataStatus,
  Dropdown,
  DropdownActionMenuItem,
  DropdownItem,
  EmptyStatus,
  Flag,
  TabProps,
  Tabs,
  closeCurrentDialog,
  getFilePreviewUrl,
  openConfirmDialog,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Box, Grid, GridItem, HStack, Hide, Show, Space} from 'platform/foundation';
import {Pattern, match} from 'ts-pattern';

import {FC} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {always, defaultTo, head, isNil, last, not, path, pipe} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BusinessCaseResponseBody,
  EntityResourceIds,
  FileResponseBody,
  NewOfferPurchaseBrokerageVehicleResponseBody,
  NewOfferPurchaseVehicleResponseBody,
  OfferResponseBody,
  tenantApi,
  useChangeToPurchaseBrokeragePurchaseVehicleMutation,
  useChangeToStockPurchaseVehicleMutation,
  useCreatePurchaseOfferDocumentMutation,
  useDeleteOfferPurchaseBrokerageVehicleMutation,
  useDeleteOfferPurchaseVehicleMutation,
  useGetBusinessCaseQuery,
  useGetCentralizedPricingQuery,
  useGetParticipationQuery,
  useGetPurchaseOfferTemplatesQuery,
  useGetVehicleQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {
  DocumentsDialog,
  Main,
  handleApiError,
  queryParams,
  useBusinessCaseActions,
  usePermissions,
  usePricePermissions,
} from '@omnetic-dms/shared';
import {VehicleWidget, selectActiveBranch, useRouter} from '@omnetic-dms/teas';

import {buildArray, composePath, useBoolean, useQueryState, useRequiredParams} from 'shared';

import {isBrokerageVehicle} from '../../../utils/isBrokerageVehicle';
import {BusinessCasePurchaseVehicleBrokerageFees} from './BusinessCasePurchaseVehicleBrokerageFees/BusinessCasePurchaseVehicleBrokerageFees';
import {BusinessCasePurchaseVehicleBrokerageFeesSummary} from './BusinessCasePurchaseVehicleBrokerageFeesSummary';
import {BusinessCasePurchaseVehicleBuying} from './BusinessCasePurchaseVehicleBuying/BusinessCasePurchaseVehicleBuying';
import {BusinessCasePurchaseVehicleCheck} from './BusinessCasePurchaseVehicleCheck/BusinessCasePurchaseVehicleCheck';
import {BusinessCasePurchaseVehicleCosts} from './BusinessCasePurchaseVehicleCosts/BusinessCasePurchaseVehicleCosts';
import {BusinessCasePurchaseVehiclePriceSummary} from './BusinessCasePurchaseVehiclePriceSummary';
import {BusinessCasePurchaseVehiclePricing} from './BusinessCasePurchaseVehiclePricing';
import {BusinessCaseSelectPurchaseVehicleTypeDialog} from './BusinessCaseSelectPurchaseVehicleTypeDialog';

const TAB_IDS = {
  COSTS: 'costs',
  PRICING: 'pricing',
  BUYING: 'buying',
  BROKERAGE_FEES: 'brokerageFees',
  VEHICLE_CHECK: 'vehicleCheck',
};

interface PurchaseVehiclesLocationState {
  makeSaleVehicleAvailableForSale?: boolean;
}

export const BusinessCasePurchaseVehicles: FC = () => {
  const router = useRouter();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTabId, setActiveTabId] = useQueryState(queryParams.BUSINESS_CASE_PURCHASE_VEHICLE);

  const isFirstTabActive = isNil(activeTabId) || activeTabId === TAB_IDS.COSTS;
  const isLastTabActive = activeTabId === TAB_IDS.VEHICLE_CHECK;

  const isAfterPricing =
    activeTabId !== TAB_IDS.COSTS &&
    activeTabId !== TAB_IDS.PRICING &&
    activeTabId !== TAB_IDS.BROKERAGE_FEES;

  const makeSaleVehicleAvailableForSale = (location?.state as PurchaseVehiclesLocationState)
    ?.makeSaleVehicleAvailableForSale;

  const activeBranch = useSelector(selectActiveBranch);

  tenantApi.endpoints.getBranch.useQuery({branchId: activeBranch});

  const {id: businessCaseId} = useRequiredParams();

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [
    hasCreateBusinessCasePurchase,
    hasAddVehicleToBuyPermission,
    canViewBusinessCasePurchase,
    canChangeToBrokerage,
    canChangeToStock,
    canRemoveVehicle,
    canPutVehicleUpForSale,
    canPutVehicleUpToBuy,
  ] = usePermissions({
    permissionKeys: [
      'createBusinessCasePurchase',
      'addVehicleToBuy',
      'viewBusinessCasePurchase',
      'changeBusinessCasePurchaseToBrokerage',
      'changeBusinessCasePurchaseToStock',
      'removeBusinessCasePurchaseVehicle',
      'vehiclePutUpForSale',
      'vehiclePutUpToBuy',
    ],
    scopes: {
      viewBusinessCasePurchase: {participation: businessCaseParticipation},
      changeBusinessCasePurchaseToBrokerage: {participation: businessCaseParticipation},
      changeBusinessCasePurchaseToStock: {participation: businessCaseParticipation},
      removeBusinessCasePurchaseVehicle: {participation: businessCaseParticipation},
    },
  });

  const canCreatePurchase = hasCreateBusinessCasePurchase && hasAddVehicleToBuyPermission;

  const {
    canViewAnyOfPurchasePriceFields,
    canViewAnyOfSalePriceFields,
    canReadVehicleCosts: hasReadCostsPermission,
    canReadBusinessCaseBrokerageFees: canViewBusinessCaseBrokerageFees,
  } = usePricePermissions({businessCaseRecordId: businessCaseId, vehicleRecordId: null});

  const canReadPrices = canViewAnyOfPurchasePriceFields || canViewAnyOfSalePriceFields;
  const canCreateSaleVehicle = canPutVehicleUpForSale && canPutVehicleUpToBuy;

  const {
    data: businessCase,
    refetch,
    isLoading: isLoadingBusinessCase,
  } = useGetBusinessCaseQuery({businessCaseId}, {skip: isNilOrEmpty(businessCaseId)});

  const {data: purchaseOfferTemplates} = useGetPurchaseOfferTemplatesQuery();
  const menuItems: DropdownActionMenuItem[] | undefined = purchaseOfferTemplates?.map(
    (template) => ({
      label: template.title,
      onClick: () => handleCreateDocuments(template.id),
    })
  );

  const [createPurchaseOfferDocument, {isLoading: isCreatePurchaseOfferDocumentLoading}] =
    useCreatePurchaseOfferDocumentMutation();

  const allowedActions = businessCase?.actions;
  const offer = businessCase?.offers[0];
  const offerId = offer?.id;
  const purchaseVehicleOffer = businessCase?.offers?.[0]?.purchaseVehicles?.[0];
  const purchaseBrokerageVehicleOffer = businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0];
  const isPurchaseBrokerage = businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE';
  const purchaseVehicle = isPurchaseBrokerage
    ? purchaseBrokerageVehicleOffer
    : purchaseVehicleOffer;

  const {data: vehicleDetail, isLoading: isLoadingVehicleDetail} = useGetVehicleQuery(
    {vehicleId: purchaseVehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(purchaseVehicle?.vehicleId)}
  );

  const isStock = purchaseVehicle && !isBrokerageVehicle(purchaseVehicle);
  const isBrokerage = purchaseVehicle && isBrokerageVehicle(purchaseVehicle);

  const {data: centralizedPricing} = useGetCentralizedPricingQuery();

  const [deletePurchaseVehicle] = useDeleteOfferPurchaseVehicleMutation();
  const [deletePurchaseBrokerageVehicle] = useDeleteOfferPurchaseBrokerageVehicleMutation();
  const [changeToPurchaseBrokerageVehicle] = useChangeToPurchaseBrokeragePurchaseVehicleMutation();
  const [changeToStockVehicle] = useChangeToStockPurchaseVehicleMutation();

  const isBuyingType =
    businessCase?.businessCaseInternalType === 'BUYING' ||
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE';

  const canReadCosts = isBuyingType ? hasReadCostsPermission : true;

  const centralizedPricingValue = pipe(
    path<boolean>([0, 'value']),
    defaultTo(false)
  )(centralizedPricing);

  const isCentralizedPricingEnabled =
    centralizedPricingValue && isFeatureEnabled(featureFlags.SALES_CENTRALIZED_PRICING);

  const [id] = router.params.dynamic;
  const isDraft = id === 'concept';

  const {isActionEnabled} = useBusinessCaseActions(id);

  const [isPurchaseVehicleTypeOpen, , , togglePurchaseVehicleType] = useBoolean(false);

  const handleChangeTradeTypeToStock = async (): Promise<void> => {
    if (offerId) {
      await changeToStockVehicle({
        businessCaseId,
        offerId,
        offerPurchaseVehicleId: purchaseVehicle?.id as string,
      })
        .unwrap()
        .catch(handleApiError);
    }
  };

  const handleChangeTradeTypeToBrokerage = async (): Promise<void> => {
    if (offerId) {
      await changeToPurchaseBrokerageVehicle({
        businessCaseId,
        offerId,
        offerPurchaseVehicleId: purchaseVehicle?.id as string,
      })
        .unwrap()
        .catch(handleApiError);
    }
  };

  const buyingTabIcon = match<[boolean, boolean | undefined], TabProps['icon'] | undefined>([
    isFeatureEnabled(featureFlags.SALES_CENTRALIZED_PRICING),
    isStock,
  ])
    .with([true, Pattern.any], always(undefined))
    .with(
      [false, true],
      always(
        isStock &&
          !isPurchaseBrokerage &&
          (purchaseVehicle as NewOfferPurchaseVehicleResponseBody)?.buying?.confirmed
          ? 'success'
          : 'pending'
      )
    )
    .with(
      [false, false],
      always(
        (purchaseVehicle as NewOfferPurchaseBrokerageVehicleResponseBody)?.brokerageFees
          ? 'success'
          : 'pending'
      )
    )
    .otherwise(always(undefined));

  const pricingTabIcon = match<
    [boolean, boolean, boolean | undefined, boolean | undefined],
    TabProps['icon'] | undefined
  >([
    isFeatureEnabled(featureFlags.SALES_CENTRALIZED_PRICING),
    businessCase?.businessCaseInternalType === 'BUYING',
    (purchaseVehicle as NewOfferPurchaseVehicleResponseBody)?.isPricingConfirmedSimplified,
    purchaseVehicle?.pricing?.confirmed,
  ])
    .with([true, Pattern.any, Pattern.any, Pattern.any], always(undefined))
    .with([false, true, true, Pattern.any], always('success'))
    .with([false, false, Pattern.any, true], always('success'))
    .otherwise(always('pending'));

  // after pricing continue only when BC is not draft or checkout view is enabled
  const isContinueDisabled =
    isAfterPricing && (isDraft || allowedActions?.checkoutViewDetail !== true);

  const handleVehicleDelete = () => {
    const deleteMutation = isBrokerage ? deletePurchaseBrokerageVehicle : deletePurchaseVehicle;

    openDeleteDialog({
      onConfirm: () =>
        deleteMutation({
          businessCaseId,
          offerId: offerId ?? '',
          offerPurchaseVehicleId: purchaseVehicle?.vehicleId ?? '',
          offerPurchaseBrokerageVehicleId: purchaseVehicle?.vehicleId ?? '',
        })
          .unwrap()
          .then(() => {
            showNotification.success();
          })
          .catch(handleApiError),
    });
  };

  const tabs = buildArray<TabProps>()
    .when(canReadCosts, {
      id: TAB_IDS.COSTS,
      title: i18n.t('general.labels.costs'),
      'data-testid': testIds.businessCase.buying('costs'),
      content: <BusinessCasePurchaseVehicleCosts key={activeTabId} refreshBusinessCase={refetch} />,
    })
    .when(canReadPrices, {
      id: TAB_IDS.PRICING,
      title: i18n.t('page.businessCase.labels.pricing'),
      'data-testid': testIds.businessCase.buying('pricing'),
      icon: pricingTabIcon,
      content: offer ? (
        <BusinessCasePurchaseVehiclePricing
          key={activeTabId}
          isCentralizedPricingEnabled={isCentralizedPricingEnabled}
          isBrokerage={isBrokerage}
          offer={offer}
        />
      ) : null,
    })
    .when(isStock && isCentralizedPricingEnabled, {
      id: TAB_IDS.BUYING,
      title: i18n.t('page.businessCase.labels.typeBuying'),
      'data-testid': testIds.businessCase.buying('buying'),
      icon: buyingTabIcon,
      content: (
        <BusinessCasePurchaseVehicleBuying
          purchaseVehicle={purchaseVehicle as NewOfferPurchaseVehicleResponseBody}
        />
      ),
    })
    .whenNot(isStock || !canViewBusinessCaseBrokerageFees, {
      id: TAB_IDS.BROKERAGE_FEES,
      title: i18n.t('page.businessCase.labels.brokerageFees'),
      'data-testid': testIds.businessCase.buying('brokerageFees'),
      icon: buyingTabIcon,
      content: <BusinessCasePurchaseVehicleBrokerageFees />,
    })
    .add({
      id: TAB_IDS.VEHICLE_CHECK,
      title: i18n.t('entity.vehicle.labels.vehicleCheck'),
      'data-testid': testIds.businessCase.buying('vehicleCheck'),
      content: <BusinessCasePurchaseVehicleCheck vehicle={vehicleDetail} />,
    });

  const moveToNextTab = () => {
    const activeTabIndex = tabs.findIndex((item) => item.id === activeTabId);
    const nextTabId = tabs[activeTabIndex + 1]?.id;

    if (isNilOrEmpty(nextTabId)) {
      return;
    }

    setActiveTabId(nextTabId);
  };

  const moveToPreviousTab = () => {
    const activeTabIndex = tabs.findIndex((item) => item.id === activeTabId);
    const previousTabId = tabs[activeTabIndex - 1]?.id;
    if (isNilOrEmpty(previousTabId)) {
      return;
    }

    setActiveTabId(previousTabId);
  };

  const onForward = () => {
    if (isLastTabActive) {
      router.push(composePath(businessCaseRoutes.checkout, {params: {id: businessCaseId}}), {
        shallow: true,
      });
      return;
    }

    moveToNextTab();
  };

  const onBackward = () => moveToPreviousTab();

  const handleCreateDocuments = (templateId: string) =>
    createPurchaseOfferDocument({
      purchaseOfferRequestBody: {businessCaseId, templateId},
    })
      .unwrap()
      .then((businessCase: BusinessCaseResponseBody) => {
        const offerFile = pipe(
          (businessCase: BusinessCaseResponseBody) => head(businessCase?.offers),
          (offer: OfferResponseBody | undefined) => offer?.files,
          (files) => last(files ?? [])
        )(businessCase);

        if (isNil(offerFile)) {
          return;
        }

        const document: FileResponseBody = {
          id: offerFile.fileFileId ?? '',
          filename: offerFile.name,
          fileUri: offerFile.fileUri,
          pdfFilename: offerFile.name + '.pdf',
          pdfUri: getFilePreviewUrl(offerFile.fileUri),
        };

        openDialog(
          <DocumentsDialog
            customerId={businessCase.customerId}
            documents={[document]}
            text={i18n.t('page.businessCase.labels.offerForCustomerNote')}
          />,
          {
            title: i18n.t('page.businessCase.labels.offerForCustomer'),
            buttons: [
              {
                variant: 'secondary',
                title: i18n.t('general.actions.close'),
                onClick: closeCurrentDialog,
              },
            ],
          }
        );
      })
      .catch(handleApiError);

  return (
    <Main
      actions={buildArray<Action>().when(
        isNotNilOrEmpty(purchaseOfferTemplates) &&
          not(isPurchaseBrokerage) &&
          not(businessCase?.businessCaseState === 'CONCEPT'),
        {
          title: i18n.t('page.businessCase.actions.generateOffer'),
          type: 'dropdown-button',
          rightIcon: 'navigation/expand_more',
          variant: 'outlined',
          isLoading: isCreatePurchaseOfferDocumentLoading,
          menuItems: menuItems ?? [],
        }
      )}
    >
      <DataStatus isLoading={isLoadingBusinessCase || isLoadingVehicleDetail}>
        <Show when={makeSaleVehicleAvailableForSale}>
          <Alert
            type="banner"
            variant="warning"
            title={i18n.t('entity.vehicle.notifications.makeVehicleAvailableForSale')}
            onClose={() =>
              navigate(composePath(businessCaseRoutes.buying, {params: {id: businessCaseId}}))
            }
            data-testid={testIds.businessCase.buying('alert')}
          />
          <Space vertical={4} />
        </Show>
        <Grid columns={1}>
          <GridItem>
            {purchaseVehicle && vehicleDetail && businessCaseId ? (
              <Card>
                <Box paddingBottom={8}>
                  <VehicleWidget
                    data-testid={testIds.businessCase.buying('vehicle')}
                    vehicle={vehicleDetail}
                    purchaseVehicle={purchaseVehicleOffer}
                    purchaseBrokeraggeVehicle={purchaseBrokerageVehicleOffer}
                    showMileagePurchase
                    saleVehicleId={businessCase?.saleVehicleId ?? ''}
                    extraFlags={
                      <Flag
                        label={
                          isStock
                            ? i18n.t(`entity.vehicle.labels.typeInStock`)
                            : i18n.t(`entity.vehicle.labels.typeBrokerage`)
                        }
                        colorScheme={isStock ? 'yellow' : 'teal'}
                        size="small"
                        data-testid={testIds.businessCase.buying('vehicleFlag')}
                      />
                    }
                    extraControls={
                      <HStack spacing={2}>
                        <Show
                          when={
                            canChangeToBrokerage &&
                            isActionEnabled('CHANGE_TO_PURCHASE_VEHICLE_TO_BROKERAGE')
                          }
                        >
                          <Button
                            variant="outlined"
                            title={i18n.t(`page.businessCase.labels.transitionToBrokerage`)}
                            onClick={() =>
                              openConfirmDialog({
                                text: i18n.t('page.businessCase.labels.switchTypeToBrokerage'),
                                onConfirm: handleChangeTradeTypeToBrokerage,
                              })
                            }
                            data-testid={testIds.businessCase.buying('transitionTo')}
                          />
                        </Show>
                        <Show
                          when={
                            canChangeToStock &&
                            isActionEnabled('CHANGE_TO_STOCK_PURCHASE_BROKERAGE_VEHICLE')
                          }
                        >
                          <Button
                            variant="outlined"
                            title={i18n.t(`page.businessCase.labels.transitionToStock`)}
                            onClick={() =>
                              openConfirmDialog({
                                text: i18n.t('page.businessCase.labels.switchTypeToStock'),
                                onConfirm: handleChangeTradeTypeToStock,
                              })
                            }
                            data-testid={testIds.businessCase.buying('transitionTo')}
                          />
                        </Show>
                        <Show
                          when={canRemoveVehicle && businessCase?.actions?.deletePurchaseVehicle}
                        >
                          <Dropdown
                            dropdownControl={
                              <Button
                                variant="outlined"
                                leftIcon="navigation/more_vert"
                                title={i18n.t('page.businessCase.labels.actions')}
                                data-testid={testIds.businessCase.buying('deleteDropdownButton')}
                              />
                            }
                            data-testid={testIds.businessCase.buying('deleteDropdown')}
                          >
                            <DropdownItem
                              severity="danger"
                              label={i18n.t('page.businessCase.actions.removeVehicle')}
                              leftIcon="action/delete"
                              onClick={handleVehicleDelete}
                              isDisabled={!businessCase?.actions?.deletePurchaseVehicle}
                            />
                          </Dropdown>
                        </Show>
                      </HStack>
                    }
                  />
                </Box>
                <Show when={canReadPrices}>
                  <Box paddingBottom={6}>
                    {isBrokerage ? (
                      <BusinessCasePurchaseVehicleBrokerageFeesSummary
                        purchaseVehicle={
                          purchaseVehicle as NewOfferPurchaseBrokerageVehicleResponseBody
                        }
                        businessCaseId={businessCaseId}
                      />
                    ) : (
                      <BusinessCasePurchaseVehiclePriceSummary
                        purchaseVehicle={purchaseVehicle as NewOfferPurchaseVehicleResponseBody}
                        expectedCosts={offer?.otherExpectedCosts}
                        businessCaseId={businessCaseId}
                        canReadCosts={canReadCosts}
                      />
                    )}
                  </Box>
                </Show>

                <Box>
                  <Tabs
                    data-testid={testIds.businessCase.buying()}
                    variant="condensed"
                    activeTabId={activeTabId ?? 'costs'}
                    onChange={setActiveTabId}
                    tabs={tabs}
                    isLazy
                  />
                </Box>
              </Card>
            ) : (
              <Box paddingTop={20} paddingBottom={4}>
                <EmptyStatus
                  data-testid={testIds.businessCase.buying('noVehicles')}
                  headline={i18n.t('page.businessCase.notifications.noVehicleAddedToPurchase')}
                  action={
                    canCreatePurchase && canViewBusinessCasePurchase
                      ? {
                          onClick: togglePurchaseVehicleType,
                          title: String(i18n.t`entity.vehicle.actions.addVehicleToBuy`),
                          isDisabled:
                            !isActionEnabled('ADD_VEHICLE_TO_BUY') || !canCreateSaleVehicle,
                          leftIcon: 'content/add_circle',
                        }
                      : undefined
                  }
                />
              </Box>
            )}
          </GridItem>
          <Show when={purchaseVehicle && vehicleDetail}>
            <HStack justify={isFirstTabActive ? 'flex-end' : 'space-between'}>
              <Hide when={isFirstTabActive}>
                <Button
                  data-testid={testIds.businessCase.buying('back')}
                  onClick={onBackward}
                  variant="outlined"
                  leftIcon="navigation/chevron_left"
                  title={i18n.t('general.actions.back')}
                />
              </Hide>
              <Button
                data-testid={testIds.businessCase.buying('continue')}
                isDisabled={isContinueDisabled}
                onClick={onForward}
                variant="outlined"
                rightIcon="navigation/chevron_right"
                title={i18n.t('general.actions.continue')}
              />
            </HStack>
          </Show>
        </Grid>
        <BusinessCaseSelectPurchaseVehicleTypeDialog
          isOpen={isPurchaseVehicleTypeOpen}
          handleClose={togglePurchaseVehicleType}
        />
      </DataStatus>
    </Main>
  );
};
