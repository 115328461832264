import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  Actions,
  Attributes,
  AttributesRow,
  Card,
  DataStatus,
  Flag,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {Heading, Hide, Link, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {useLocation} from 'react-router-dom';

import {isNil, startsWith} from 'ramda';

import {
  CashRegisterDocumentResponseBody,
  EntityResourceIds,
  VehicleActiveReservationApiResponse,
  VehicleReservationResponseBody,
  useCancelVehicleReservationDepositMutation,
  useGetCashRegisterQuery,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useRefundVehicleReservationDepositMutation,
  useRenderIncomeCashReceiptDocumentMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {accountingRoutes, businessCaseRoutes, testIds} from '@omnetic-dms/routes';

import {
  Nullish,
  TestIdProps,
  buildArray,
  composePath,
  convertStringToCamelCase,
  getApiDateTimeString,
  openFile,
  parseDate,
  suffixTestId,
} from 'shared';

import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../../utils/handleApiError';

interface ActiveReservationBailDetailProps extends TestIdProps {
  reservation: VehicleReservationResponseBody | VehicleActiveReservationApiResponse;
  vehicleId: string;
  cashRegisterDocument?: CashRegisterDocumentResponseBody | Nullish;
  isCanceled?: boolean;
  onCreateFutureAgreement?: () => void;
}

export function ActiveReservationBailDetail(props: ActiveReservationBailDetailProps) {
  const [renderIncomeDocument] = useRenderIncomeCashReceiptDocumentMutation();
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();
  const {pathname} = useLocation();
  const {data: saleVehicle} = useGetSaleVehicleQuery({vehicleId: props.vehicleId});
  const isBusinessCasePage = startsWith('/business-case/', pathname);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: saleVehicle?.saleBusinessCaseId ?? '',
    },
    {skip: isNil(saleVehicle?.saleBusinessCaseId)}
  );

  const {
    data: cashRegister,
    isLoading: isCashRegisterLoading,
    isError: isCashRegisterError,
  } = useGetCashRegisterQuery(
    {
      cashRegisterId: props.cashRegisterDocument?.cashRegisterId ?? '',
    },
    {skip: !props.cashRegisterDocument?.cashRegisterId}
  );

  const [cancelAdvanceDeposit] = useCancelVehicleReservationDepositMutation();
  const [refundAdvanceDeposit] = useRefundVehicleReservationDepositMutation();

  const [
    hasVehicleCancelReservationDepositPermissions,
    hasVehicleRefundReservationDepositPermissions,
    hasReadBusinessCaseSellingPermission,
    canReadDocuments,
  ] = usePermissions({
    permissionKeys: [
      'vehicleCancelReservationDeposit',
      'vehicleRefundReservationDeposit',
      'viewBusinessCaseSelling',
      'readDocuments',
    ],
    scopes: {
      vehicleCancelReservationDeposit: {participation: vehicleParticipation},
      vehicleRefundReservationDeposit: {participation: vehicleParticipation},
      viewBusinessCaseSelling: {participation: businessCaseParticipation},
    },
  });

  if (isNil(props.reservation.paidDeposit)) {
    return null;
  }

  const handleGeneratePDF = async () => {
    await renderIncomeDocument({
      renderIncomeCashReceiptDocumentRequestBody: {
        cashRegisterDocumentId: props.reservation.paidDeposit!.incomeCashReceiptId,
      },
    })
      .unwrap()
      .then((documentUrl) => {
        if (documentUrl) {
          openFile(documentUrl.pdfUrl);
        }
      })
      .catch(handleApiError);
  };

  const headerActions = buildArray<Action>()
    .add({
      leftIcon: 'action/open_in_new',
      type: 'button',
      title: i18n.t('entity.accounting.labels.cashReceipt'),
      variant: 'ghostLink',
      isDisabled: !canReadDocuments,
      onClick: () =>
        window.open(
          composePath(accountingRoutes.cashReceiptDetail, {
            params: {id: props.cashRegisterDocument?.id},
          }),
          '_blank'
        ),
    })
    .when(hasReadBusinessCaseSellingPermission && !isBusinessCasePage, {
      leftIcon: 'action/open_in_new',
      type: 'button',
      title: i18n.t('page.businessCase.labels.businessCase'),
      variant: 'ghostLink',
      onClick: () =>
        window.open(
          composePath(businessCaseRoutes.overview, {
            params: {
              id: props.reservation.businessCase?.id,
            },
          }),
          '_blank'
        ),
    });

  const attributesRows: AttributesRow[] | undefined =
    props.cashRegisterDocument && cashRegister
      ? [
          {
            label: i18n.t('general.labels.amount'),
            value: formatCurrency(
              Number(props.cashRegisterDocument.totalPriceWithVat.amount),
              props.cashRegisterDocument.totalPriceWithVat.currency,
              2
            ),
          },
          {
            label: i18n.t('entity.accounting.labels.paymentMethod'),
            value: i18n.t(
              `entity.invoice.paymentMethod.${convertStringToCamelCase(
                props.cashRegisterDocument.paymentMethod
              )}`
            ),
          },
          {
            label: i18n.t('general.labels.issuedOn'),
            value: formatDateTime(
              'dateShort',
              parseDate(props.cashRegisterDocument.issuedAt) || new Date()
            ),
          },
          {
            label: i18n.t('general.labels.cashRegister'),
            value: cashRegister.name,
          },
          {
            label: i18n.t('general.labels.note'),
            value: props.cashRegisterDocument.note,
          },
          {
            label: i18n.t('entity.vehicle.labels.incomeDocument'),
            content: (
              <Link
                size="xSmall"
                isDisabled={!canReadDocuments}
                onClick={handleGeneratePDF}
                data-testid={testIds.accounting.cashReceiptDetail('generatePdf')}
                title={`${props.cashRegisterDocument.number}_${i18n.t(
                  'entity.vehicle.labels.incomeDocument'
                )}`}
              />
            ),
          },
        ]
      : undefined;

  const handleCancelAdvanceDeposit = () =>
    openConfirmDialog({
      text: i18n.t('entity.vehicle.labels.cancelAdvanceDeposit', {
        documentNumber: props.cashRegisterDocument?.number,
      }),
      onConfirm: async () => {
        await cancelAdvanceDeposit({
          vehicleId: props.vehicleId,
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('entity.vehicle.labels.advanceDepositCanceled'));
          })
          .catch(handleApiError);
      },
    });

  const handleRefundAdvanceDeposit = () =>
    openConfirmDialog({
      text: i18n.t('entity.vehicle.labels.refundDeposit', {
        documentNumber: props.cashRegisterDocument?.number,
      }),
      onConfirm: async () => {
        await refundAdvanceDeposit({
          vehicleId: props.vehicleId,
          'X-Branch': saleVehicle?.branchId ?? '',
          body: {
            issuedAt: getApiDateTimeString(new Date()),
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('entity.vehicle.labels.depositRefunded'));
          })
          .catch(handleApiError);
      },
    });

  return (
    <>
      <DataStatus
        isLoading={isCashRegisterLoading}
        isError={isCashRegisterError}
        isEmpty={!props.cashRegisterDocument || !cashRegister}
      >
        <Show when={props.cashRegisterDocument && cashRegister}>
          <Card variant="inlineGrey">
            <VStack spacing={4}>
              <Heading size={4}>{i18n.t('entity.vehicle.labels.advanceDeposit')}</Heading>
              <Actions
                actions={headerActions}
                data-testid={suffixTestId('activeReservationBail-actions', props)}
              />
              <Show when={props.cashRegisterDocument?.state === 'cancelled'}>
                <Flag
                  label={i18n.t('entity.cashRegister.state.cancelled')}
                  colorScheme="red"
                  data-testid={suffixTestId('activeReservationBail-flag', props)}
                />
              </Show>
              <Attributes
                rows={attributesRows}
                data-testid={suffixTestId('activeReservationBail-attributes', props)}
              />
            </VStack>
          </Card>
        </Show>
      </DataStatus>
      <Hide when={props.isCanceled}>
        <Actions
          align="right"
          data-testid={testIds.businessCase.reservations('activeReservationDetail')}
          actions={buildArray<Action>()
            .when(
              props.reservation.customer?.id &&
                props.reservation.businessCase?.id &&
                isFeatureEnabled(featureFlags.VEHICLE_RESERVATION_FUTURE_AGREEMENT),
              {
                title: i18n.t('entity.vehicle.labels.createContract'),
                onClick: props.onCreateFutureAgreement,
                type: 'button',
                variant: 'outlined',
              }
            )
            .when(
              props.reservation?.paidDeposit &&
                hasVehicleCancelReservationDepositPermissions &&
                hasVehicleRefundReservationDepositPermissions,
              {
                title: i18n.t('entity.checkout.actions.cancelPayment'),
                onClick: handleCancelAdvanceDeposit,
                type: 'button',
                variant: 'dangerOutlined',
                leftIcon: 'content/remove_circle',
              }
            )
            .when(
              props.reservation?.paidDeposit &&
                hasVehicleCancelReservationDepositPermissions &&
                hasVehicleRefundReservationDepositPermissions,
              {
                title: i18n.t('entity.vehicle.labels.refund'),
                onClick: handleRefundAdvanceDeposit,
                type: 'button',
                variant: 'outlined',
                leftIcon: 'action/swap_horiz',
              }
            )}
        />
      </Hide>
    </>
  );
}
