import {BaseFlagProps, ColorSchemeType, Flags, Parameters} from 'platform/components';
import {Box, Center, Clickable, HStack, Icon, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  DashboardInterestResponseBody,
  EntityResourceIds,
  useGetParticipationQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {interestsRoutes} from '@omnetic-dms/routes';
import {
  getInterestStateFlagProps,
  getInterestTypeFlagProps,
  usePermissions,
} from '@omnetic-dms/shared';

import {TestIdProps, buildArray, composePath, suffixTestId, useNavigate} from 'shared';

interface LatestInterestItemProps extends TestIdProps {
  interest: DashboardInterestResponseBody;
}

export function LatestInterestItem(props: LatestInterestItemProps) {
  const navigate = useNavigate();

  const {data: interestParticipation} = useGetParticipationQuery({
    recordId: props.interest.id,
    resourceId: EntityResourceIds.interest,
  });

  const [canReadTag] = usePermissions({
    permissionKeys: ['interestTagRead'],
    scopes: {
      interestTagRead: {participation: interestParticipation},
    },
  });

  const onClickOpenInterestDetail = () => {
    navigate(composePath(interestsRoutes.overview, {params: {id: props.interest.id}}));
  };

  const flags = generateInterestFlags(props.interest, canReadTag);
  const parameters = generateInterestParameters(props.interest);

  return (
    <Clickable key={props.interest.id} onClick={onClickOpenInterestDetail}>
      <HStack spacing={4} minWidth={100}>
        <Box
          minWidth={10}
          maxWidth={10}
          height={10}
          backgroundColor="palettes.neutral.30.100"
          borderRadius="small"
          overflow="hidden"
          flex={1}
        >
          <Center height="100%" width="100%">
            <Icon value="content/next_week" size={6} color="palettes.neutral.800.100" />
          </Center>
        </Box>
        <Box flex={3}>
          <VStack spacing={1}>
            <HStack spacing={1}>
              <Flags
                spacing={1}
                flags={flags}
                shouldWrap
                data-testid={suffixTestId('latest-interest-item-flags', props)}
              />
            </HStack>
            <Text alternative>{props.interest.customer}</Text>
          </VStack>
        </Box>
        <Box flex={3}>
          <VStack spacing={1}>
            <HStack spacing={1}>
              <Parameters
                size="xSmall"
                parameters={parameters}
                data-testid={suffixTestId('latest-interest-item-parameters', props)}
              />
            </HStack>
            <Text alternative>{props.interest.vehicle}</Text>
          </VStack>
        </Box>
        <Box flex={3} />
      </HStack>
    </Clickable>
  );
}

function generateInterestFlags(
  interest: DashboardInterestResponseBody,
  canReadTag: boolean
): BaseFlagProps[] {
  const stateFlag = getInterestStateFlagProps(interest.state);
  const typeFlag = getInterestTypeFlagProps(interest.type);

  const flags = buildArray<BaseFlagProps>()
    .when(isNotNil(stateFlag), stateFlag!)
    .when(isNotNil(typeFlag), typeFlag!);

  flags.forEach((flag) => (flag.size = 'small'));

  if (canReadTag) {
    interest.tags.map((tag) =>
      flags.add({
        label: tag.label,
        size: 'small',
        isSubtle: true,
        colorScheme: tag.color as ColorSchemeType,
      })
    );
  }
  return flags;
}

function generateInterestParameters(interest: DashboardInterestResponseBody): string[] {
  const parameters = buildArray<string>().when(isNotNilOrEmpty(interest.source), interest.source!);

  if (isNotNilOrEmpty(interest.owner)) {
    parameters.push(interest.owner!);
  } else {
    parameters.add(i18n.t('general.labels.unassigned'));
  }

  return parameters;
}
