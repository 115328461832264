import {last} from 'ramda';

import {DrillDownLevel} from '@omnetic-dms/api';

export const shouldSkipModelLevel = (queryLevels: DrillDownLevel[]) => {
  const lastLevel = last(queryLevels);
  const isLastLevelModelFamilyGroup = lastLevel?.level === 'model_family_group';
  const lastLevelValue = lastLevel?.values[0]; // i.e. MAKE_MAZDA-MODELFAMILY_6
  const lastLevelValueModelWithType = lastLevelValue?.split('-')[1];
  const lastLevelValueType = lastLevelValueModelWithType?.split('_')[0];
  const isLastLevelValueModelFamily = lastLevelValueType === 'MODELFAMILY';

  return isLastLevelModelFamilyGroup && isLastLevelValueModelFamily;
};
