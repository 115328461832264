import {Grid, Hide, Show} from 'platform/foundation';

import {isNil} from 'ramda';

import {EntityResourceIds, useGetParticipationQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {CommentsWidget, RestrictedArea, usePermissions} from '@omnetic-dms/shared';

interface BusinessCaseSaleVehicleCommentsProps {
  vehicleId?: string;
}

export function BusinessCaseSaleVehicleComments(props: BusinessCaseSaleVehicleCommentsProps) {
  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: props.vehicleId ?? '',
    },
    {skip: isNil(props.vehicleId)}
  );

  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
  ] = usePermissions({
    permissionKeys: ['vehicleCommentRead', 'vehicleCommentAdd', 'vehicleCommentDelete'],
    scopes: {
      vehicleCommentAdd: {participation: vehicleParticipation},
      vehicleCommentRead: {participation: vehicleParticipation},
    },
  });

  if (isNil(props.vehicleId)) {
    return null;
  }

  return (
    <Grid columns={2}>
      <Hide when={hasVehicleCommentReadPermission}>
        <RestrictedArea shouldHideRedirectButton />
      </Hide>
      <Show when={hasVehicleCommentReadPermission}>
        <CommentsWidget
          cardVariant="inlineWhite"
          title={i18n.t('entity.vehicle.labels.vehicleComments')}
          resourceId={EntityResourceIds.vehicle}
          recordId={props.vehicleId}
          isAddDisabled={!hasVehicleCommentAddPermission}
          isDeleteDisabled={!hasVehicleCommentDeletePermission}
          data-testid={testIds.businessCase.buying('commentsWidget')}
        />
      </Show>
    </Grid>
  );
}
