import {Action, closeDialog, DataStatus, FlagProps, openDialog} from 'platform/components';

import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {find, isNil, not} from 'ramda';
import {isNotNil, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  GetVehicleCustomerApiResponse,
  useDeleteServiceCaseMutation,
  useGetCustomerV2Query,
  useGetMetadaServiceCaseQuery,
  useGetServiceCaseJobsQuery,
  useGetServiceCaseScheduleRepairQuery,
  useGetVehicleLocalActionNoteQuery,
  useGetVehicleQuery,
  useLazyGetVehicleCustomerQuery,
  usePatchServiceCaseMutation,
  usePostAvailableXentryOrdersMutation,
  usePostIntegrationsForXentryLinkMutation,
  usePostServiceCaseCreateOrdersMutation,
  usePostServiceCaseIntegrationsXentryOrderManualCreationMutation,
  usePostServiceCaseMutation,
  usePostVehicleCustomerMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, workshopRoutes} from '@omnetic-dms/routes';
import {
  CustomerVehicleSelection,
  EntityHeader,
  getCustomerName,
  handleApiError,
  Page,
  queryParams,
  Section,
  useStateFlag,
  workshopSearchParams,
} from '@omnetic-dms/shared';

import {buildArray, composePath, getApiDateString, Nullish, useQueryState} from 'shared';

import {SOURCE_DATA_GRID} from 'features/datagrid';

import {IntegrationsDropdown} from '../../components/IntegrationsDropdown';
import {LinkXentry} from '../../components/LinkXentry';
import {useWorkshopUrl} from '../../hooks/useWorkshopUrl';
import {ScheduleSelection} from './(sections)/Schedule/ScheduleSelection';
import {ServiceJobsSelection} from './(sections)/ServiceRequest/ServiceJobsSelection';
import {useServiceCaseVehicleDraftParameters} from './hooks/useServiceCaseVehicleDraftParameters';

const OPERATOR_TYPE = 'CORVEHICLECUSTOMERTYPE_OPERATOR';

export function CreateServiceCase() {
  const navigate = useNavigate();
  const {serviceCaseId} = useWorkshopUrl();
  const [sectionTab, setSectionTab] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);
  const [searchParams] = useSearchParams();

  const defaultVehicleId = searchParams.get('vehicleId');
  const defaultCustomerId = searchParams.get('customerId');

  const [customerId, setCustomerId] = useState<string | Nullish>(defaultCustomerId);
  const [vehicleId, setVehicleId] = useState<string | Nullish>(defaultVehicleId);
  const [suggestedVehicles, setSuggestedVehicles] = useState<GetVehicleCustomerApiResponse | null>(
    null
  );
  const [isCustomerVehicleChanged, setCustomerVehicleChanged] = useState<boolean>(false);

  const [postServiceCaseIntegrationsXentryOrderManualCreation] =
    usePostServiceCaseIntegrationsXentryOrderManualCreationMutation();
  const [postIntegrationsForXentryLink] = usePostIntegrationsForXentryLinkMutation();
  const [postAvailableXentryOrders] = usePostAvailableXentryOrdersMutation({
    fixedCacheKey: 'open-link-xentry',
  });
  const [postServiceCase, {isLoading: isPostServiceCaseLoading}] = usePostServiceCaseMutation();
  const [patchServiceCase, {isLoading: isPatchServiceCaseLoading}] = usePatchServiceCaseMutation();
  const [postVehicleCustomer, {isLoading: isPostVehicleCustomerLoading}] =
    usePostVehicleCustomerMutation();
  const [getVehicleCustomer] = useLazyGetVehicleCustomerQuery();
  const [deleteServiceCase, deleteServiceCaseQuery] = useDeleteServiceCaseMutation();
  const [postServiceCaseCreateOrders, {isLoading: isPostServiceCaseCreate}] =
    usePostServiceCaseCreateOrdersMutation();
  const {
    data: serviceCase,
    isFetching: isServiceCaseFetching,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId}, {skip: not(serviceCaseId)});
  const {
    data: jobs,
    isLoading: isServiceCaseJobsLoading,
    isError: isServiceCaseJobsError,
  } = useGetServiceCaseJobsQuery({serviceCaseId}, {skip: not(serviceCaseId)});
  const {data: vehicle, isError: isVehicleError} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: not(vehicleId)}
  );
  const {data: scheduleRepairData} = useGetServiceCaseScheduleRepairQuery(
    {serviceCaseId},
    {skip: not(serviceCaseId) || sectionTab !== workshopSearchParams.create.schedule}
  );

  useEffect(() => {
    if (serviceCase && serviceCase?.state !== 'DRAFT') {
      const searchParams = new URLSearchParams(window.location.search);
      navigate(
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {id: serviceCaseId},
          queryParams: {
            [queryParams.REDIRECT_SOURCE]: 'service-cases',
            [SOURCE_DATA_GRID]: searchParams.get(SOURCE_DATA_GRID),
          },
        })
      );
    }
  }, [serviceCase?.state]);

  useEffect(() => {
    if (isNotNil(serviceCase)) {
      setCustomerId(serviceCase?.customerId);
      setVehicleId(serviceCase?.vehicleId);
    }
  }, [serviceCase]);

  const {data: serviceCaseCustomer, isError: isServiceCaseCustomerError} = useGetCustomerV2Query(
    {customerId: serviceCase?.customerId ?? ''},
    {skip: not(serviceCase?.customerId)}
  );

  const stateFlag = useStateFlag(serviceCase?.state);

  const handleDiscard = () => {
    if (not(serviceCaseId)) {
      return navigate(workshopRoutes.serviceCases);
    }

    deleteServiceCase({serviceCaseId})
      .unwrap()
      .then(() => {
        navigate(workshopRoutes.serviceCases);
      })
      .catch(handleApiError);
  };

  const onCreateServiceCase = () => {
    if (not(serviceCaseId)) {
      return;
    }

    postServiceCaseCreateOrders({serviceCaseId})
      .unwrap()
      .then(() => {
        navigate(composePath(workshopRoutes.serviceCaseDetail, {params: {id: serviceCaseId}}));
      })
      .catch(handleApiError);
  };

  const handleCreateServiceDraftCase = async () => {
    if (!vehicle?.type || !customerId) {
      return;
    }

    if (serviceCaseId) {
      return patchServiceCase({
        serviceCaseId,
        body: {
          customerId,
          vehicleId: vehicle?.id,
          make: vehicle.make,
          type: vehicle.type,
          modelFamily: vehicle.modelFamily,
          vin: vehicle.vin,
          registrationPlate: vehicle.vehicle.registrationPlate,
        },
      })
        .unwrap()
        .then((response) => {
          if (response?.serviceCaseId) {
            setCustomerVehicleChanged(false);
            navigate(
              composePath(workshopRoutes.draftServiceCase, {
                params: {id: response?.serviceCaseId ?? ''},
                queryParams: {
                  [queryParams.COMPONENT_SECTIONS_TAB]: workshopSearchParams.create.serviceJobs,
                },
              })
            );
          }
        })
        .catch(handleApiError);
    }

    const customers = await getVehicleCustomer({vehicleId: vehicle?.id})
      .unwrap()
      .catch(handleApiError);

    const hasCustomerConnection = find(
      (vehicleCustomer) => vehicleCustomer?.customerId === customerId,
      customers ?? []
    );
    const hasVehicleOperator = find(
      (vehicleCustomer) => vehicleCustomer?.customerType === OPERATOR_TYPE,
      customers ?? []
    );

    if (not(hasCustomerConnection) && not(hasVehicleOperator)) {
      postVehicleCustomer({
        body: {
          customerType: OPERATOR_TYPE,
          customerId,
          vehicleId: vehicle.id,
          eventId: vehicle.id,
          eventType: 'vehicle',
          dateFrom: getApiDateString(new Date()),
        },
      })
        .unwrap()
        .catch(handleApiError);
    }

    postServiceCase({
      body: {
        vehicleId: vehicle?.id,
        customerId:
          !hasCustomerConnection || !hasVehicleOperator
            ? customerId
            : String(hasVehicleOperator.customerId),
        make: vehicle.make,
        type: vehicle.type,
        modelFamily: vehicle.modelFamily,
        vin: vehicle.vin,
        registrationPlate: vehicle.vehicle.registrationPlate,
      },
    })
      .unwrap()
      .then((response) => {
        const draftServiceCasePath = composePath(workshopRoutes.draftServiceCase, {
          params: {id: response?.id ?? ''},
          queryParams: {
            [queryParams.COMPONENT_SECTIONS_TAB]: workshopSearchParams.create.serviceJobs,
          },
        });
        if (response?.additional_action?.includes('MERCEDES_XENTRY_LINK')) {
          setCustomerVehicleChanged(false);

          const serviceCaseId = response.id ?? '';

          postAvailableXentryOrders({serviceCaseId}).unwrap().catch(handleApiError);

          openDialog(
            <LinkXentry
              serviceCaseId={serviceCaseId}
              onClose={() => closeDialog('selectXentryDialog')}
              onLink={(xentryOrderId) =>
                postIntegrationsForXentryLink({serviceCaseId, body: {xentryOrderId}})
                  .unwrap()
                  .then(() => {
                    closeDialog('selectXentryDialog');
                    navigate(draftServiceCasePath);
                  })
                  .catch(handleApiError)
              }
              onLinkNew={() =>
                postServiceCaseIntegrationsXentryOrderManualCreation({
                  serviceCaseId,
                })
                  .unwrap()
                  .then(() => {
                    closeDialog('selectXentryDialog');
                    navigate(draftServiceCasePath);
                  })
                  .catch(handleApiError)
              }
              data-testid={testIds.workshop.createServiceCase('customerVehicleSelection')}
            />,
            {title: i18n.t('entity.integration.labels.selectXentryOrder'), id: 'selectXentryDialog'}
          );
        } else if (response?.id) {
          setCustomerVehicleChanged(false);
          navigate(draftServiceCasePath);
        }
      })
      .catch(handleApiError);
  };

  const handleTabChange = (newTab: string) => {
    if (
      newTab === workshopSearchParams.create.serviceJobs &&
      (sectionTab === workshopSearchParams.create.customerVehicle || isNil(sectionTab))
    ) {
      handleCreateServiceDraftCase();
    }
  };

  const onCustomerChange = (customerId: string | Nullish) => {
    setCustomerId(customerId);
    setCustomerVehicleChanged(true);
  };

  const onVehicleChange = (vehicleId: string | Nullish) => {
    setVehicleId(vehicleId);
    setCustomerVehicleChanged(true);
  };

  const actions = buildArray<Action>()
    .add({
      type: 'button',
      onClick: handleDiscard,
      title: i18n.t('general.actions.discard'),
      isLoading: deleteServiceCaseQuery.isLoading,
      variant: 'secondary',
    })
    .add({
      type: 'custom',
      component: <IntegrationsDropdown serviceCaseId={serviceCaseId} />,
    })
    .when(serviceCaseId, {
      type: 'button',
      variant: 'primary',
      onClick: onCreateServiceCase,
      isLoading: isPostServiceCaseCreate,
      isDisabled: not(jobs?.length) || sectionTab === workshopSearchParams.create.customerVehicle,
      title: i18n.t('general.actions.confirm'),
    });

  const flags = buildArray<FlagProps>()
    .when(serviceCaseId && stateFlag, stateFlag as FlagProps)
    .whenNot(serviceCaseId, {label: i18n.t('entity.serviceOrder.states.draft')});

  const parameters = useServiceCaseVehicleDraftParameters(serviceCaseId, serviceCase, vehicle);

  const isError =
    isServiceCaseError || isServiceCaseJobsError || isVehicleError || isServiceCaseCustomerError;

  const isLoading =
    isServiceCaseLoading ||
    isServiceCaseFetching ||
    isServiceCaseJobsLoading ||
    isPostServiceCaseLoading ||
    isPatchServiceCaseLoading ||
    isPostVehicleCustomerLoading;

  const isCustomerVehicleIconChecked = customerId && vehicleId;
  const isJobsIconChecked =
    not(isCustomerVehicleChanged) && serviceCaseId && isPositive(jobs?.length);
  const isSchedulerChecked = not(isCustomerVehicleChanged) && isNotNilOrEmpty(scheduleRepairData);

  const isNotNewOrEdited =
    serviceCase?.customerId === customerId && serviceCase?.vehicleId === vehicleId;

  const vehicleLocalActionNoteQuery = useGetVehicleLocalActionNoteQuery(
    {vehicleId},
    {skip: !vehicleId}
  );

  const recallMessage = vehicleLocalActionNoteQuery.currentData?.local_action_note_text;

  return (
    <>
      <Helmet title={i18n.t('page.workshop.labels.newServiceCase')} />
      <DataStatus isLoading={isLoading} isError={isError} minHeight="100%">
        <Page
          isFullHeight
          header={
            <EntityHeader
              data-testid={testIds.workshop.createServiceCase('header')}
              title={
                isNotNewOrEdited
                  ? `${vehicle?.title} - ${getCustomerName(serviceCaseCustomer)}`
                  : i18n.t('page.workshop.labels.newServiceCase')
              }
              icon="action/build"
              flags={flags}
              actions={actions}
              parameters={isNotNewOrEdited ? parameters : undefined}
              isTagDeletable
              isTagUpdatable
              isTagReadable
              isTagCreatable
              isTagAssignable
              isTagUnassignable
              alert={recallMessage ? {type: 'banner', message: recallMessage} : undefined}
            />
          }
          data-testid={testIds.workshop.createServiceCase()}
        >
          <Section
            isFullHeight
            defaultActiveTab="customerVehicle"
            data-testid={testIds.workshop.createServiceCase('sections')}
            onTabChange={handleTabChange}
            tabs={[
              {
                queryId: workshopSearchParams.create.customerVehicle,
                content: (
                  <CustomerVehicleSelection
                    customerId={customerId}
                    vehicleId={vehicleId}
                    onCustomerChange={onCustomerChange}
                    onVehicleChange={onVehicleChange}
                    onContinueClick={handleCreateServiceDraftCase}
                    suggestedVehicles={suggestedVehicles}
                    onSuggestedVehiclesChange={setSuggestedVehicles}
                    data-testid={testIds.workshop.createServiceCase('customerVehicleSelection')}
                  />
                ),
                icon: isCustomerVehicleIconChecked ? 'success' : 'default',
                title: i18n.t('page.workshop.labels.customerVehicle'),
              },
              {
                queryId: workshopSearchParams.create.serviceJobs,
                content: (
                  <ServiceJobsSelection
                    onContinueClick={() => setSectionTab(workshopSearchParams.create.schedule)}
                    onBackClick={() => setSectionTab(workshopSearchParams.create.customerVehicle)}
                  />
                ),
                title: i18n.t('page.workshop.labels.serviceRequests'),
                icon: isJobsIconChecked ? 'success' : 'default',
                isDisabled: not(customerId) || not(vehicleId),
              },
              {
                queryId: workshopSearchParams.create.schedule,
                content: (
                  <ScheduleSelection
                    onBackClick={() => setSectionTab(workshopSearchParams.create.serviceJobs)}
                  />
                ),
                title: i18n.t('page.workshop.labels.repairSchedule'),
                icon: isSchedulerChecked ? 'success' : 'default',
                isDisabled:
                  not(customerId) ||
                  not(vehicleId) ||
                  not(serviceCaseId) ||
                  not(jobs?.length) ||
                  sectionTab === workshopSearchParams.create.customerVehicle,
              },
            ]}
          />
        </Page>
      </DataStatus>
    </>
  );
}
