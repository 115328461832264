import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';
import {yupPasswordValidation} from '@omnetic-dms/shared';

import {ResetPasswordTuple} from './types';

const SPECIFIC_FIELD_REQUIRED_MESSAGE = (fieldName: string) =>
  `${fieldName} ${i18n.t('general.notifications.errorSpecFieldRequired')}`;

const stringRequiredValidation = (field: string): Yup.StringSchema =>
  Yup.string().required(SPECIFIC_FIELD_REQUIRED_MESSAGE(field) as string);

const confirmPassword = stringRequiredValidation('Confirm password').oneOf(
  [Yup.ref('password'), null],
  i18n.t('general.notifications.errorPasswordsNotMatch')
);

export const ResetPasswordValidationSchema = Yup.object().shape({
  password: yupPasswordValidation,
  passwordNewConfirm: confirmPassword,
}) as Yup.SchemaOf<ResetPasswordTuple>;
