import {Module} from '@omnetic-dms/api';
import {moduleKeys} from '@omnetic-dms/config';
import featureFlags from '@omnetic-dms/feature-flags';
import {taskManagerRoutes} from '@omnetic-dms/routes';
import {permissions} from '@omnetic-dms/shared';

import {TaskList} from './pages/TaskList/TaskList';

export const TaskManagerModule: Module = {
  id: moduleKeys.taskManager,
  requiredFeatureFlag: featureFlags.CORE_TASK_MANAGEMENT,
  requiredPermission: permissions.readTask,
  layoutProps: {
    title: 'page.taskManager.title',
    icon: 'action/assignment_turned_in',
    iconV2: 'action/assignment_turned_in',
  },
  routerProps: {
    path: taskManagerRoutes.taskList,
    element: <TaskList />,
  },
};
