import {Action, DataStatus} from 'platform/components';

import {useRef} from 'react';
import {Helmet} from 'react-helmet-async';

import i18n from '@omnetic-dms/i18n';
import {taskManagerRoutes, testIds} from '@omnetic-dms/routes';
import {Main, PageTab, usePermissions} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {DataGridRef} from 'features/datagrid';

import {TasksDataGrid} from '../../components/TasksDataGrid/TasksDataGrid';
import {openCreateTaskFormDialog} from '../../utils/openCreateTaskFormDialog';

export function TaskList() {
  const [canCreateTask] = usePermissions({
    permissionKeys: ['createTask'],
  });
  const dataGridRef = useRef<DataGridRef>(null);

  const Actions = buildArray<Action>().when(canCreateTask, {
    type: 'button',
    title: i18n.t('entity.task.actions.createTask'),
    variant: 'primary',
    'data-testid': testIds.taskManager.taskList('createTask'),
    onClick: () =>
      openCreateTaskFormDialog({
        onCreate: () => dataGridRef.current?.refreshData?.(),
        'data-testid': testIds.taskManager.taskList('createTaskDialog'),
      }),
  });

  const Tabs = buildArray<PageTab>().add({
    id: 'task-list',
    title: i18n.t('entity.task.labels.tasks'),
    href: taskManagerRoutes.taskList,
    content: (
      <TasksDataGrid dataGridRef={dataGridRef} data-testid={testIds.taskManager.taskList('grid')} />
    ),
  });

  return (
    <>
      <Helmet title={i18n.t('entity.task.labels.taskManagement')} />
      <DataStatus isLoading={false}>
        <Main
          isFullHeight
          data-testid={testIds.taskManager.taskList()}
          actions={Actions}
          tabs={Tabs}
        />
      </DataStatus>
    </>
  );
}
