import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetHandlingUnitQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {MeasurementUnitsDetailForm} from './components/MeasurementUnitsDetailForm';

export function WarehousesMeasurementUnitsDetail() {
  const {id} = useParams();

  const {
    data: measurementUnit,
    isLoading,
    isError,
  } = useGetHandlingUnitQuery({id: id as string}, {skip: isNilOrEmpty(id)});

  const header: SettingsTemplateHeader = {
    title: isNil(id)
      ? i18n.t('entity.warehouse.actions.newMeasurementUnit')
      : (measurementUnit?.description ?? ''),
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.warehousesMeasurementUnitsDetail('template')}
    >
      <MeasurementUnitsDetailForm
        measurementUnit={measurementUnit}
        data-testid={testIds.settings.warehousesMeasurementUnitsDetail(
          'measurementUnitsDetailForm'
        )}
      />
    </SettingsTemplate>
  );
}
