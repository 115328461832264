import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {
  RoundingDefinitionResponseBody,
  selectBranchList,
  useCreateRoundingDefinitionMutation,
  useGetPaymentTypeEnumQuery,
  useGetRoundStepQuery,
  useGetRoundTypeQuery,
  useUpdateRoundingDefinitionMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {DocumentRoundingDefinitionFormValues} from '../types/DocumentRoundingDefinitionFormValues';
import {getEmptyValidityItem} from '../utils/getEmptyValidityItem';
import {documentRoundingDefinitionFormValidations} from './documentRoundingDefinitionFormValidations';
import {ValidityList} from './ValidityList';

interface DocumentRoundingDefinitionsAddProps {
  roundingDefinition?: RoundingDefinitionResponseBody;
}

export const DocumentRoundingDefinitionForm = (props: DocumentRoundingDefinitionsAddProps) => {
  const isCreating = isNil(props.roundingDefinition);
  const navigate = useNavigate();

  const {data: {branchListItems: branchList} = {}} = useSelector(selectBranchList);
  const {
    data: paymentTypes,
    isError: isPaymentTypesError,
    isLoading: isPaymentTypesLoading,
  } = useGetPaymentTypeEnumQuery();
  const {
    data: roundStepList,
    isError: isRoundingStepError,
    isLoading: isRoundingStepLoading,
  } = useGetRoundStepQuery();
  const {
    data: roundTypeList,
    isError: isRoundTypeError,
    isLoading: isRoundTypeLoading,
  } = useGetRoundTypeQuery();

  const [createRoundingDefinition] = useCreateRoundingDefinitionMutation();
  const [updateRoundingDefinition] = useUpdateRoundingDefinitionMutation();

  const onCancel = () => {
    navigate(composePath(settingsRoutes.documentRoundingDefinitions));
  };

  const handleSubmit: FormSubmitHandler<DocumentRoundingDefinitionFormValues> = async (data) => {
    if (isCreating) {
      createRoundingDefinition({
        createRoundingDefinitionRequestBody: data,
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.accounting.notifications.roundingDefinitionCreated')
          );
        })
        .catch(handleApiError);
    } else if (props.roundingDefinition) {
      await updateRoundingDefinition({
        id: props.roundingDefinition.id,
        updateRoundingDefinitionRequestBody: data,
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.accounting.notifications.roundingDefinitionUpdated')
          );
        })
        .catch(handleApiError);
    }
    navigate(composePath(settingsRoutes.documentRoundingDefinitions));
  };

  return (
    <DataStatus
      isLoading={isPaymentTypesLoading || isRoundingStepLoading || isRoundTypeLoading}
      isError={isPaymentTypesError || isRoundingStepError || isRoundTypeError}
    >
      <Form<DocumentRoundingDefinitionFormValues>
        schema={documentRoundingDefinitionFormValidations(!isCreating, props.roundingDefinition)}
        defaultValues={{
          validities: [getEmptyValidityItem()],
          ...(props.roundingDefinition as Partial<DocumentRoundingDefinitionFormValues>),
        }}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <SettingsSection>
            <VStack spacing={4}>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  options={branchList?.map((item) => ({
                    value: item.id,
                    label: item.marketingName ?? '',
                  }))}
                  label={i18n.t('entity.branch.labels.branch')}
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit('branchId')}
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="paymentType"
                  options={paymentTypes?.map((item) => ({value: item.key, label: item.value}))}
                  label={i18n.t('entity.bank.labels.paymentType')}
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit('paymentType')}
                  isRequired
                />
              </Grid>
              <ValidityList
                roundStepList={roundStepList}
                roundTypeList={roundTypeList}
                control={control}
                formApi={formApi}
              />
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={onCancel}
                  title={i18n.t('general.actions.cancel')}
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit('cancel')}
                />
                <FormButton
                  type="submit"
                  control={control}
                  title={
                    isCreating ? i18n.t('general.actions.create') : i18n.t('general.actions.edit')
                  }
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit('submit')}
                />
              </ButtonGroup>
            </VStack>
          </SettingsSection>
        )}
      </Form>
    </DataStatus>
  );
};
