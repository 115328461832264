import i18n from '@omnetic-dms/i18n';

import {MyProfileTemplate} from '../../components/MyProfileTemplate/MyProfileTemplate';
import {DashboardForm} from './components/DashboardForm';

export function Dashboard() {
  return (
    <MyProfileTemplate
      header={{
        title: i18n.t('page.myProfile.dashboard.title'),
        breadcrumbs: [
          {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
          {label: i18n.t('page.myProfile.dashboard.title'), isCurrentPage: true},
        ],
      }}
    >
      <DashboardForm />
    </MyProfileTemplate>
  );
}
