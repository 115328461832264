import {Button, FormControl} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {TireWarehouseForm} from '../types/TireWarehouseForm';
import {externalWarehouseFactory} from '../utils/externalWarehouseFactory';
import {ExternalWarehouseSelector} from './ExternalWarehouseSelector';

interface ExternalWarehousesProps {
  control: FormControl<TireWarehouseForm>;
  formApi: UseFormReturn<TireWarehouseForm>;
  isLoading?: boolean;
}

export function ExternalWarehouses(props: ExternalWarehousesProps) {
  const {fields, append, remove} = useFieldArray({
    control: props.control,
    name: 'externalWarehouses',
  });

  return (
    <VStack spacing={4} justify="center">
      {fields.map((field, index) => (
        <ExternalWarehouseSelector
          key={field.id}
          control={props.control}
          formApi={props.formApi}
          index={index}
          onRemove={() => remove(index)}
          isLastOfKind={fields.length <= 1}
        />
      ))}
      <HStack>
        <Button
          title={i18n.t('general.actions.addAnother')}
          variant="link"
          leftIcon="content/add_circle"
          onClick={() => append(externalWarehouseFactory())}
        />
      </HStack>
    </VStack>
  );
}
