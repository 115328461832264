import {Box, HStack, Show, VStack} from 'platform/foundation';

import {EntityResourceIds, useGetParticipationQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {testIds} from '@omnetic-dms/routes';
import {
  CustomerInformationCard,
  CustomerContactPersonCard,
  Section,
  useCustomerUrl,
  EntityCustomFields,
  usePermissions,
} from '@omnetic-dms/shared';

import {AddressesCard} from './components/AddressesCard';
import {CommentsCard} from './components/CommentsCard';
import {ContractInformationCard} from './components/ContractInformationCard';
import {NoteCard} from './components/NoteCard';
import {PaymentSettingsCard} from './components/PaymentSettingsCard';

export function CustomerInformation() {
  const {customerId} = useCustomerUrl();

  const {data: customerParticipation} = useGetParticipationQuery({
    recordId: customerId,
    resourceId: EntityResourceIds.customer,
  });

  const [canEditCustomFields] = usePermissions({
    permissionKeys: ['customerCustomFields'],
    scopes: {customerCustomFields: {participation: customerParticipation}},
  });

  return (
    <Section>
      <HStack spacing={4} justify="flex-start" align="flex-start">
        <Box flex={1}>
          <VStack spacing={4}>
            <CustomerInformationCard
              data-testid={testIds.customer.detail('detail')}
              customerId={customerId}
            />
            <ContractInformationCard
              data-testid={testIds.customer.detail('detail')}
              customerId={customerId}
            />
            <Show whenFeatureEnabled={featureFlags.AFTS_CUSTOMER_PAYMENT_SETTINGS}>
              <PaymentSettingsCard
                data-testid={testIds.customer.detail('paymentSettings')}
                customerId={customerId}
              />
            </Show>

            <EntityCustomFields
              recordId={customerId}
              resourceId={EntityResourceIds.customer}
              isEditable={canEditCustomFields}
              data-testid={testIds.customer.detail('customer-custom-fields')}
            />
          </VStack>
        </Box>
        <Box flex={1}>
          <VStack spacing={4}>
            <CustomerContactPersonCard
              data-testid={testIds.customer.detail('detail')}
              customerId={customerId}
            />
            <AddressesCard
              data-testid={testIds.customer.detail('detail')}
              customerId={customerId}
            />
            <NoteCard data-testid={testIds.customer.detail('detail')} customerId={customerId} />
            <CommentsCard data-testid={testIds.customer.detail('detail')} customerId={customerId} />
          </VStack>
        </Box>
      </HStack>
    </Section>
  );
}
