import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  Alert,
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  DataStatus,
  Dialog,
  Dropdown,
  DropdownItem,
  EmptyStatus,
  getFilePreviewUrl,
  openDialog,
  TabProps,
  Tabs,
} from 'platform/components';
import {Box, Hide, HStack, Show, Space} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {head, isNil, last, pipe} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BusinessCaseResponseBody,
  EntityResourceIds,
  FileResponseBody,
  OfferResponseBody,
  tenantApi,
  useCreateSaleOfferDocumentMutation,
  useDeleteOfferSaleVehicleMutation,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  useGetSaleOfferTemplatesQuery,
  useGetVehicleQuery,
  useMarkOfferDocumentsAsActualMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {
  DocumentsDialog,
  getBusinessCaseTradeTypeFlag,
  handleApiError,
  Main,
  queryParams,
  useBusinessCaseActions,
  usePermissions,
  usePricePermissions,
} from '@omnetic-dms/shared';
import {selectActiveBranch, useRouter, VehicleWidget} from '@omnetic-dms/teas';

import {
  buildArray,
  composePath,
  useBoolean,
  useQueryState,
  useRequiredParams,
  useToggle,
} from 'shared';

import {useSelectVehicleContext} from '../../../hooks/useSelectVehicleContext';
import {BusinessCaseSaleVehicleComments} from './BusinessCaseSaleVehicleComments';
import {BusinessCaseSaleVehicleDiscount} from './BusinessCaseSaleVehicleDiscount/BusinessCaseSaleVehicleDiscount';
import {BusinessCaseSaleVehiclePriceSummary} from './BusinessCaseSaleVehiclePriceSummary';

const TAB_IDS = {
  VEHICLE_SELLING: 'vehicleSelling',
  VEHICLE_COMMENTS: 'vehicleComments',
};

export const BusinessCaseSaleVehicles: FC = () => {
  const router = useRouter();
  const navigate = useNavigate();
  const {id: businessCaseId} = useRequiredParams();
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.BUSINESS_CASE_SALE_VEHICLE);

  const isFirstTabActive = activeTabId === TAB_IDS.VEHICLE_SELLING;
  const isLastTabActive = activeTabId === TAB_IDS.VEHICLE_COMMENTS;

  const activeBranch = useSelector(selectActiveBranch);
  tenantApi.endpoints.getBranch.useQuery({branchId: activeBranch});

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [
    canViewBusinessCaseSelling,
    canChangeBusinessCaseSellingVehicle,
    canRemoveBusinessCaseSellingVehicle,
  ] = usePermissions({
    permissionKeys: [
      'viewBusinessCaseSelling',
      'changeBusinessCaseSellingVehicle',
      'removeBusinessCaseSellingVehicle',
    ],
    scopes: {
      viewBusinessCaseSelling: {participation: businessCaseParticipation},
      changeBusinessCaseSellingVehicle: {participation: businessCaseParticipation},
      removeBusinessCaseSellingVehicle: {participation: businessCaseParticipation},
    },
  });

  const {data: businessCaseData, isLoading, isError} = useGetBusinessCaseQuery({businessCaseId});

  const offer = businessCaseData?.offers?.[0];

  const saleVehicleData = businessCaseData?.offers?.[0]?.saleVehicles?.[0];

  const {
    data: vehicleDetail,
    isLoading: isLoadingVehicle,
    isError: isErrorVehicle,
  } = useGetVehicleQuery(
    {vehicleId: saleVehicleData?.vehicleId ?? ''},
    {skip: isNilOrEmpty(saleVehicleData?.vehicleId)}
  );

  const allowedActions = businessCaseData?.actions;

  const [id] = router.params.dynamic;
  const isDraft = id === 'concept';

  const [isLoadingRemoveVehicle, setIsLoadingRemoveVehicle, stopIsLoadingRemoveVehicle] =
    useBoolean();
  const [isConfirmRemoveVehicleOpen, toggleIsConfirmRemoveVehicleOpen] = useToggle();
  const [isConfirmChangeVehicleOpen, toggleIsConfirmChangeVehicleOpen] = useToggle();

  const isVehicleCommentsEnabled = isFeatureEnabled(featureFlags.SALES_SALE_VEHICLE_COMMENTS);
  const {setSelectSaleVehicleOpen} = useSelectVehicleContext();
  const {data: saleOfferTemplates = []} = useGetSaleOfferTemplatesQuery();

  const [deleteOfferSaleVehicle] = useDeleteOfferSaleVehicleMutation();
  const [markDocumentsAsActual] = useMarkOfferDocumentsAsActualMutation();

  const [createSaleOfferDocument, {isLoading: isCreateSaleOfferDocumentLoading}] =
    useCreateSaleOfferDocumentMutation();

  const {isActionEnabled} = useBusinessCaseActions(id);

  const {
    canReadBusinessCaseSaleVehicleSalePrice,
    canViewAnyOfSalePriceFields,
    canViewAnyOfPurchasePriceFields,
  } = usePricePermissions({businessCaseRecordId: businessCaseId, vehicleRecordId: null});

  const canReadPrices = canViewAnyOfSalePriceFields || canViewAnyOfPurchasePriceFields;

  const handleRemoveVehicle = async () => {
    if (!(offer && businessCaseData && saleVehicleData)) {
      return;
    }

    setIsLoadingRemoveVehicle();

    await deleteOfferSaleVehicle({
      businessCaseId: businessCaseData.id,
      offerId: offer.id,
      offerSaleVehicleId: saleVehicleData.vehicleId,
    })
      .unwrap()
      .catch(handleApiError);

    stopIsLoadingRemoveVehicle();
    toggleIsConfirmRemoveVehicleOpen();
  };

  const handleMarkOfferDocumentsAsActual = () => {
    if (!(offer && businessCaseData && saleVehicleData)) {
      return;
    }

    markDocumentsAsActual({
      businessCaseId: businessCaseData.id,
      offerId: offer.id,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleCreateDocuments = (templateId: string) =>
    createSaleOfferDocument({
      saleOfferRequestBody: {businessCaseId, templateId},
    })
      .unwrap()
      .then((businessCase: BusinessCaseResponseBody) => {
        const offerFile = pipe(
          (businessCase: BusinessCaseResponseBody) => head(businessCase?.offers),
          (offer: OfferResponseBody | undefined) => offer?.files,
          (files) => last(files ?? [])
        )(businessCase);

        if (isNil(offerFile)) {
          return;
        }

        const document: FileResponseBody = {
          id: offerFile.fileFileId ?? '',
          filename: offerFile.name,
          fileUri: offerFile.fileUri,
          pdfFilename: offerFile.name + '.pdf',
          pdfUri: getFilePreviewUrl(offerFile.fileUri),
        };

        openDialog(
          <DocumentsDialog
            customerId={businessCase.customerId}
            documents={[document]}
            text={i18n.t('page.businessCase.labels.offerForCustomerNote')}
          />,
          {
            title: i18n.t('page.businessCase.labels.offerForCustomer'),
            buttons: [
              {
                variant: 'secondary',
                title: i18n.t('general.actions.close'),
                onClick: closeCurrentDialog,
              },
            ],
          }
        );
      })
      .catch(handleApiError);

  const setIsSelectVehicleOpen = () => {
    setSelectSaleVehicleOpen(true);
  };

  const onChangeVehicleConfirm = () => {
    toggleIsConfirmChangeVehicleOpen();
    setIsSelectVehicleOpen();
  };

  const flag = getBusinessCaseTradeTypeFlag(
    businessCaseData?.brokerageBusinessCaseId ? 'BROKERAGE' : 'IN_STOCK'
  );

  const tabs: TabProps[] = buildArray<TabProps>()
    .when(canReadBusinessCaseSaleVehicleSalePrice, {
      id: TAB_IDS.VEHICLE_SELLING,
      title: i18n.t('page.businessCase.labels.typeSelling'),
      'data-testid': testIds.businessCase.selling('vehicle-selling'),
      content: <BusinessCaseSaleVehicleDiscount />,
    })
    .when(isVehicleCommentsEnabled, {
      id: TAB_IDS.VEHICLE_COMMENTS,
      title: i18n.t('entity.vehicle.labels.vehicleComments'),
      'data-testid': testIds.businessCase.selling('vehicle-comments'),
      content: <BusinessCaseSaleVehicleComments vehicleId={saleVehicleData?.vehicleId} />,
    });

  const moveToNextTab = () => {
    const activeTabIndex = tabs.findIndex((item) => item.id === activeTabId);
    const nextTabId = tabs[activeTabIndex + 1]?.id;
    if (isNilOrEmpty(nextTabId)) {
      return;
    }

    setActiveTabId(nextTabId);
  };

  const moveToPreviousTab = () => {
    const activeTabIndex = tabs.findIndex((item) => item.id === activeTabId);
    const previousTabId = tabs[activeTabIndex - 1]?.id;
    if (isNilOrEmpty(previousTabId)) {
      return;
    }

    setActiveTabId(previousTabId);
  };

  const onForward = () => {
    if (isLastTabActive || !isVehicleCommentsEnabled) {
      navigate(composePath(businessCaseRoutes.testDrive, {params: {id: businessCaseId}}));

      return;
    }

    moveToNextTab();
  };

  const onBackward = () => moveToPreviousTab();

  return (
    <Main
      actions={buildArray<Action>().when(
        isNotNilOrEmpty(saleOfferTemplates) && isActionEnabled('GENERATE_SALE_OFFER_DOCUMENT'),
        {
          title: i18n.t('page.businessCase.actions.generateOffer'),
          type: 'dropdown-button',
          rightIcon: 'navigation/expand_more',
          variant: 'outlined',
          isLoading: isCreateSaleOfferDocumentLoading,
          menuItems: saleOfferTemplates.map((template) => ({
            label: template.title,
            onClick: () => handleCreateDocuments(template.id),
          })),
        }
      )}
    >
      <Show when={offer?.documentsIsActual === false}>
        <Alert
          title={i18n.t('page.businessCase.notifications.checkSaleVehicleDocuments')}
          variant="warning"
          onClose={handleMarkOfferDocumentsAsActual}
          data-testid={testIds.businessCase.selling('checkSaleVehicleDocumentsAlert')}
        />
        <Space vertical={4} />
      </Show>
      <DataStatus isLoading={isLoading || isLoadingVehicle} isError={isError || isErrorVehicle}>
        {saleVehicleData && vehicleDetail ? (
          <Card>
            <Box paddingBottom={4}>
              <VehicleWidget
                data-testid={testIds.businessCase.selling('vehicle')}
                vehicle={vehicleDetail}
                selling={saleVehicleData}
                shouldShowPrice={canViewAnyOfSalePriceFields}
                showMileageSale
                saleVehicleId={businessCaseData?.saleVehicleId ?? ''}
                extraControls={
                  <Show
                    when={
                      (isActionEnabled('SWITCH_OFFER_SALE_VEHICLE') &&
                        canChangeBusinessCaseSellingVehicle) ||
                      (isActionEnabled('DELETE_OFFER_SALE_VEHICLE') &&
                        canRemoveBusinessCaseSellingVehicle)
                    }
                  >
                    <Dropdown
                      dropdownControl={
                        <Button
                          variant="outlined"
                          leftIcon="navigation/more_vert"
                          title={i18n.t('page.businessCase.labels.actions')}
                          data-testid={testIds.businessCase.selling('dropdownControlButton')}
                        />
                      }
                      data-testid={testIds.businessCase.selling('dropdown')}
                    >
                      <Show
                        when={
                          isActionEnabled('SWITCH_OFFER_SALE_VEHICLE') &&
                          canChangeBusinessCaseSellingVehicle
                        }
                      >
                        <DropdownItem
                          label={i18n.t('page.businessCase.actions.changeVehicle')}
                          leftIcon="navigation/refresh"
                          onClick={toggleIsConfirmChangeVehicleOpen}
                        />
                      </Show>
                      <Show
                        when={
                          isActionEnabled('DELETE_OFFER_SALE_VEHICLE') &&
                          canRemoveBusinessCaseSellingVehicle
                        }
                      >
                        <DropdownItem
                          severity="danger"
                          label={i18n.t('page.businessCase.actions.removeVehicle')}
                          leftIcon="action/delete"
                          onClick={toggleIsConfirmRemoveVehicleOpen}
                        />
                      </Show>
                    </Dropdown>
                  </Show>
                }
                flags={
                  flag && flag.label && flag.colorScheme
                    ? [
                        {
                          name: flag.label,
                          color: flag.colorScheme,
                        },
                      ]
                    : undefined
                }
              />
              <Dialog
                data-testid={testIds.businessCase.selling('change-vehicle-confirm')}
                isOpen={isConfirmChangeVehicleOpen}
                onClose={toggleIsConfirmChangeVehicleOpen}
                size="small"
                title={i18n.t`page.businessCase.labels.confirmChangeVehicle`}
              >
                <ButtonGroup align="right">
                  <Button
                    variant="secondary"
                    onClick={toggleIsConfirmChangeVehicleOpen}
                    title={i18n.t('general.labels.no')}
                  />
                  <Button
                    variant="primary"
                    onClick={onChangeVehicleConfirm}
                    title={i18n.t('general.labels.yes')}
                  />
                </ButtonGroup>
              </Dialog>

              <Dialog
                data-testid={testIds.businessCase.selling('remove-confirm')}
                isOpen={isConfirmRemoveVehicleOpen}
                onClose={toggleIsConfirmRemoveVehicleOpen}
                size="small"
                title={i18n.t`page.businessCase.labels.confirmRemoveVehicle`}
              >
                <ButtonGroup align="right">
                  <Button
                    variant="secondary"
                    onClick={toggleIsConfirmRemoveVehicleOpen}
                    isDisabled={isLoadingRemoveVehicle}
                    title={i18n.t('general.labels.no')}
                  />
                  <Button
                    variant="primary"
                    onClick={handleRemoveVehicle}
                    isLoading={isLoadingRemoveVehicle}
                    title={i18n.t('general.labels.yes')}
                  />
                </ButtonGroup>
              </Dialog>
            </Box>
            <Show when={canReadPrices}>
              <Box paddingBottom={4}>
                <BusinessCaseSaleVehiclePriceSummary
                  data-testid={testIds.businessCase.selling('summary')}
                  saleVehicle={saleVehicleData}
                  offerTotalPrice={businessCaseData?.offers?.[0]?.offerTotalPrice}
                  isBrokeredSale={businessCaseData?.brokerageBusinessCaseId}
                  businessCaseId={businessCaseData?.id}
                  offer={businessCaseData?.offers?.[0]}
                />
              </Box>
            </Show>
            <Box>
              <Show when={isVehicleCommentsEnabled}>
                <Tabs
                  data-testid={testIds.businessCase.selling('vehicle')}
                  variant="condensed"
                  tabs={tabs}
                  activeTabId={activeTabId}
                  onChange={setActiveTabId}
                />
              </Show>
              <Hide when={isVehicleCommentsEnabled}>
                <BusinessCaseSaleVehicleDiscount />
              </Hide>
            </Box>
          </Card>
        ) : (
          <Box paddingTop={20} paddingBottom={4}>
            <EmptyStatus
              data-testid={testIds.businessCase.selling('noVehicle')}
              headline={i18n.t('page.businessCase.notifications.noVehicleAddedToSale')}
              action={
                canViewBusinessCaseSelling
                  ? {
                      onClick: setIsSelectVehicleOpen,
                      title: i18n.t('entity.vehicle.actions.addVehicleToSell'),
                      isDisabled: !isActionEnabled('ADD_VEHICLE_TO_SELL'),
                      leftIcon: 'content/add_circle',
                    }
                  : undefined
              }
            />
          </Box>
        )}
      </DataStatus>
      {saleVehicleData && (
        <Box paddingTop={4}>
          <HStack justify={isFirstTabActive || !activeTabId ? 'flex-end' : 'space-between'}>
            <Hide when={isFirstTabActive || !activeTabId}>
              <Button
                data-testid={testIds.businessCase.selling('back')}
                onClick={onBackward}
                variant="outlined"
                leftIcon="navigation/chevron_left"
                title={i18n.t('general.actions.back')}
              />
            </Hide>
            <Button
              data-testid={testIds.businessCase.selling('continue')}
              isDisabled={
                isLastTabActive &&
                isVehicleCommentsEnabled &&
                (isDraft || !allowedActions?.checkoutViewDetail)
              }
              onClick={onForward}
              variant="outlined"
              rightIcon="navigation/chevron_right"
              title={i18n.t('general.actions.continue')}
            />
          </HStack>
        </Box>
      )}
    </Main>
  );
};
