import {addDays} from 'date-fns';
import {Button, ButtonGroup, Card, FormControl, FormField} from 'platform/components';
import {Grid, Hide, HStack, Show, VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn, useWatch} from 'react-hook-form';

import {last} from 'ramda';

import {GetRoundStepApiResponse, GetRoundTypeApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {getApiDateString, parseDate} from 'shared';

import {DocumentRoundingDefinitionFormValues} from '../types/DocumentRoundingDefinitionFormValues';
import {getEmptyValidityItem} from '../utils/getEmptyValidityItem';

interface RoundingFormProps {
  roundStepList: GetRoundStepApiResponse | undefined;
  roundTypeList: GetRoundTypeApiResponse | undefined;
  control: FormControl<DocumentRoundingDefinitionFormValues>;
  formApi: UseFormReturn<DocumentRoundingDefinitionFormValues>;
}

export const ValidityList = (props: RoundingFormProps) => {
  const {fields, append, remove} = useFieldArray<DocumentRoundingDefinitionFormValues>({
    control: props.control,
    name: 'validities',
  });

  const isSystem = useWatch({
    control: props.control,
    name: 'isSystem',
  });

  const handleCreateNew = () => {
    const lastItem = last(props.formApi.watch('validities'));
    const newDate = lastItem && addDays(parseDate(lastItem.validFrom), 1);

    append(getEmptyValidityItem(newDate));
  };

  return (
    <VStack spacing={4}>
      {fields.map((field, index) => (
        <Card key={field.id} variant="inlineGrey">
          <VStack spacing={4}>
            <Grid columns={2}>
              <Grid columns={2}>
                <FormField
                  control={props.control}
                  type="choice"
                  name={`validities.${index}.roundType`}
                  options={props.roundTypeList?.map((item) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                  label={i18n.t('entity.accounting.labels.roundingType')}
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit(
                    `validity-${index}-roundType`
                  )}
                  isRequired
                />
                <FormField
                  control={props.control}
                  type="choice"
                  name={`validities.${index}.roundStep`}
                  options={props.roundStepList?.map((item) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                  label={i18n.t('entity.accounting.labels.rounding')}
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit(
                    `validity-${index}-roundStep`
                  )}
                  isRequired
                />
              </Grid>
              <FormField
                control={props.control}
                type="date"
                onChange={(date) => {
                  if (date) {
                    props.formApi.setValue(`validities.${index}.validFrom`, getApiDateString(date));
                  }
                }}
                name={`validities.${index}.validFrom`}
                label={i18n.t('entity.accounting.labels.validFrom')}
                data-testid={testIds.settings.documentRoundingDefinitionsEdit(
                  `validity-${index}-validFrom`
                )}
                isDisabled={isSystem}
                isRequired
              />
            </Grid>

            <HStack justify="space-between">
              <FormField
                control={props.control}
                type="checkbox"
                name={`validities.${index}.taxRounding`}
                label={i18n.t('entity.accounting.labels.taxRounding')}
                data-testid={testIds.settings.documentRoundingDefinitionsEdit(
                  `validity-${index}-taxRounding`
                )}
              />
              <Show when={fields.length > 1 && !isSystem}>
                <Button
                  title={i18n.t('general.labels.delete')}
                  onClick={() => remove(index)}
                  leftIcon="content/remove_circle"
                  variant="errorLink"
                  data-testid={testIds.settings.documentRoundingDefinitionsEdit(
                    `validity-${index}-remove`
                  )}
                />
              </Show>
            </HStack>
          </VStack>
        </Card>
      ))}
      <Hide when={isSystem}>
        <ButtonGroup align="left">
          <Button
            title={i18n.t('entity.accounting.actions.addValidity')}
            onClick={handleCreateNew}
            leftIcon="content/add_circle"
            variant="link"
            data-testid={testIds.settings.documentRoundingDefinitionsEdit('addValidity')}
          />
        </ButtonGroup>
      </Hide>
    </VStack>
  );
};
