import {Action, Card, DataStatus} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useListLatestBusinessCasesQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes} from '@omnetic-dms/routes';
import {useDataQuery, usePermissions} from '@omnetic-dms/shared';

import {TestIdProps, composePath, useNavigate} from 'shared';

import {LatestBusinessCaseItem} from './LatestBusinessCaseItem';

interface LatestBusinessCasesProps extends TestIdProps {
  branchId: string | undefined;
}

export function LatestBusinessCases(props: LatestBusinessCasesProps) {
  const navigate = useNavigate();

  const {
    data: latestBusinessCases,
    isLoading: isLatestBusinessCases,
    isError: isLatestBusinessCasesError,
  } = useListLatestBusinessCasesQuery(
    {
      branchId: props.branchId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const [hasViewBusinessCasePurchasePermission, hasViewBusinessCaseSellingPermission] =
    usePermissions({
      permissionKeys: ['viewBusinessCasePurchase', 'viewBusinessCaseSelling'],
      scopes: {
        // No need for participation scope. Listing all BCs, child is handling visibility
        viewBusinessCasePurchase: {
          participation: undefined,
        },
        // No need for participation scope. Listing all BCs, child is handling visibility
        viewBusinessCaseSelling: {
          participation: undefined,
        },
      },
    });

  const canViewLatestBusinessCases =
    hasViewBusinessCasePurchasePermission || hasViewBusinessCaseSellingPermission;

  const {queryParams} = useDataQuery('business-case', {
    // Latest business cases sorted by createdAt in descending order
    order: [{columnKey: 'businessCaseCreatedAt', order: 'DESC'}],
  });

  const onClickOpenLatestBusinessCases = () => {
    navigate(composePath(businessCaseRoutes.home, {queryParams}));
  };

  const displayLatestBusinessCasesAction: Action = {
    title: i18n.t('page.homepage.labels.view'),
    onClick: onClickOpenLatestBusinessCases,
    variant: 'link',
    type: 'button',
    rightIcon: 'navigation/chevron_right',
  };

  if (not(canViewLatestBusinessCases)) {
    return <></>;
  }

  return (
    <Card
      title={i18n.t('page.homepage.labels.latestBusinessCases')}
      hasSeparator={false}
      actions={[displayLatestBusinessCasesAction]}
    >
      <Box minHeight={45}>
        <DataStatus
          minHeight="100%"
          isLoading={isLatestBusinessCases}
          isError={isLatestBusinessCasesError}
          isEmpty={isNilOrEmpty(latestBusinessCases)}
        >
          <VStack spacing={4}>
            {latestBusinessCases?.map((businessCase) => (
              <LatestBusinessCaseItem key={businessCase.id} businessCase={businessCase} />
            ))}
          </VStack>
        </DataStatus>
      </Box>
    </Card>
  );
}
