import {closeCurrentDialog, openDialog, showNotification} from 'platform/components';
import {Text} from 'platform/foundation';

import {defaultTo, find, innerJoin, isNil, isNotNil, propEq} from 'ramda';
import {isEmptyArray, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useChangeBranchSaleVehicleMutation,
  useGetCurrentUserInfoQuery,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useGetVehicleQuery,
  useLazyListAvailableVehicleWarehousesForVehicleMovementQuery,
  useLazyListVehicleWarehousesQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {TestIdProps} from 'shared';

import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {useVehicleWarehouse} from '../../../hooks/useVehicleWarehouse/useVehicleWarehouse';
import {handleApiError} from '../../../utils/handleApiError';
import {BranchChanger} from './BranchChanger';

export interface LocationProps extends TestIdProps {
  recordId: string;
}

export function VehicleLocation(props: LocationProps) {
  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.recordId});
  const {data: saleVehicle} = useGetSaleVehicleQuery({vehicleId: props.recordId});
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();
  const [changeBranchSaleVehicle, {isLoading: isBranchChangeLoading}] =
    useChangeBranchSaleVehicleMutation();

  const [getAvailableWarehouses, {isFetching: isAvailableFetching}] =
    useLazyListAvailableVehicleWarehousesForVehicleMovementQuery();
  const [getVehicleWarehouseList, {isFetching: isListFetching}] =
    useLazyListVehicleWarehousesQuery();

  const {data: vehicleParticipationId} = useGetParticipationQuery({
    recordId: props.recordId,
    resourceId: EntityResourceIds.vehicle,
  });

  const [canChangeVehicleBranch] = usePermissions({
    permissionKeys: ['vehicleChangeBranch'],
    scopes: {
      vehicleChangeBranch: {participation: vehicleParticipationId},
    },
  });

  const {availableActions, isVehicleWarehouseEnabled, openTransferDialog} = useVehicleWarehouse({
    vehicleId: props.recordId,
    saleVehicleId: saleVehicle?.id,
    vehicleWarehouseId: saleVehicle?.vehicleWarehouse?.id,
    onMovementComplete: (branchId) => {
      if (isNotNilOrEmpty(branchId) && isString(branchId)) {
        updateVehicleBranch(branchId);
      }
    },
    'data-testid': props['data-testid'],
  });

  const isLoading = isBranchChangeLoading || isAvailableFetching || isListFetching;

  const updateVehicleBranch = (branchId: string) =>
    changeBranchSaleVehicle({
      vehicleId: props.recordId,
      changeBranchSaleVehicleRequestBody: {branchId},
    })
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const handleUpdateVehicleBranch = (branchId: string) => {
    if (!isVehicleWarehouseEnabled || isNil(saleVehicle?.vehicleWarehouse?.id)) {
      return updateVehicleBranch(branchId);
    }

    if (isNotNil(saleVehicle?.vehicleWarehouse?.id) && !availableActions?.transfer) {
      showNotification.error(i18n.t('entity.vehicleWarehouse.notifications.transferNotAllowed'));

      return;
    }

    getVehicleWarehouseList({branchId})
      .unwrap()
      .then((list) => {
        if (list.some((warehouse) => warehouse.id === saleVehicle?.vehicleWarehouse?.id)) {
          return updateVehicleBranch(branchId);
        }

        return getAvailableWarehouses({vehicleId: props.recordId})
          .unwrap()
          .then((warehouses) => {
            const availableWarehouses = innerJoin(
              (warehouseByUser, warehouseByBranch) => warehouseByUser.id === warehouseByBranch.id,
              warehouses,
              list
            );

            if (isEmptyArray(availableWarehouses)) {
              openDialog(
                <Text>{i18n.t('entity.vehicleWarehouse.labels.noWarehouseForBranch')}</Text>,
                {
                  size: 'small',
                  buttons: [
                    {
                      title: i18n.t('general.actions.close'),
                      variant: 'secondary',
                      onClick: closeCurrentDialog,
                    },
                  ],
                }
              );

              return;
            }

            openTransferDialog(branchId);
          });
      })
      .catch(handleApiError);
  };

  const activeBranch = find(
    propEq(vehicle?.branchId, 'id'),
    defaultTo([], currentUserInfo?.branches)
  );

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={activeBranch}
      onChange={handleUpdateVehicleBranch}
      isLoading={isLoading}
      data-testid={props['data-testid']}
      isEditDisabled={!canChangeVehicleBranch}
    />
  );
}
