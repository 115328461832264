import {ThemeColorPath, CSSDimension} from 'platform/foundation';

import {is} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {buildArray} from 'shared';

type ColumnTransformation = {
  title: string;
  value: number;
  color: ThemeColorPath;
  height: CSSDimension;
  id: string;
};

type OpportunitiesFunnelData =
  | {
      activeInterests: number;
      closed: number;
      contracts: number;
      newInterests: number;
      offers: number;
      sold?: number;
      bought?: number;
    }
  | undefined;

export function createOpportunitiesFunnelColumns(
  data: OpportunitiesFunnelData,
  isFunnelInterestsEnabled: boolean,
  isFunnelBusinessCasesEnabled: boolean
): ColumnTransformation[] {
  if (!data) {
    return [];
  }

  const maximum = Math.max(...Object.values(data));

  const calculateHeight = (value: number) => Math.round((value / maximum) * 100);

  const {activeInterests, closed, contracts, newInterests, offers, sold, bought} = data;

  return buildArray<ColumnTransformation>()
    .when(isFunnelInterestsEnabled, {
      title: i18n.t('page.homepage.salesFunnel.labels.newInterests'),
      value: newInterests,
      color: 'palettes.orange.40.100',
      id: 'newInterests',
      height: `${calculateHeight(newInterests)}%`,
    })
    .when(isFunnelInterestsEnabled, {
      title: i18n.t('page.homepage.salesFunnel.labels.activeInterests'),
      value: activeInterests,
      color: 'palettes.magenta.40.100',
      id: 'activeInterests',
      height: `${calculateHeight(activeInterests)}%`,
    })
    .when(isFunnelInterestsEnabled, {
      title: i18n.t('page.homepage.salesFunnel.labels.offers'),
      value: offers,
      color: 'palettes.purple.40.100',
      id: 'offers',
      height: `${calculateHeight(offers)}%`,
    })
    .when(isFunnelBusinessCasesEnabled, {
      title: i18n.t('page.homepage.salesFunnel.labels.contracts'),
      value: contracts,
      color: 'palettes.blue.60.100',
      id: 'contracts',
      height: `${calculateHeight(contracts)}%`,
    })
    .when(isFunnelBusinessCasesEnabled && is(Number, sold), {
      title: i18n.t('page.homepage.salesFunnel.labels.sold'),
      value: sold as number,
      color: 'palettes.teal.40.100',
      id: 'salesFunnel',
      height: `${calculateHeight(sold as number)}%`,
    })
    .when(isFunnelBusinessCasesEnabled && is(Number, bought), {
      title: i18n.t('page.homepage.salesFunnel.labels.bought'),
      value: bought as number,
      color: 'palettes.teal.40.100',
      id: 'salesFunnel',
      height: `${calculateHeight(bought as number)}%`,
    })
    .when(isFunnelBusinessCasesEnabled, {
      title: i18n.t('page.homepage.salesFunnel.labels.closed'),
      value: closed,
      color: 'palettes.green.50.100',
      id: 'closed',
      height: `${calculateHeight(closed)}%`,
    });
}
